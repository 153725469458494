import React from "react";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { IconoirEditPencil, IconoirTrash } from '@ey-xd/motif-icon';

export default (props) => {
  const propertyId = props.node.data ? props.node.data.propertyID : -1;

  if (props.node.data !== undefined) {
    return (
      <div className="justify-content-end align-items-center mt-2">
        <MotifIconButton
          aria-label="View/Edit this Property"
          type="button"
          data-testid="btnEditIcon"
          disabled={false}
          onClick={() => props.handleOpenComponent(propertyId)}
        >
          <MotifIcon iconFunction={IconoirEditPencil} size='24'  title="View/Edit this Property" />
        </MotifIconButton>
        <MotifIconButton
          aria-label="Delete this Property"
          type="button"
          data-testid="btnDeleteIcon"
          disabled={false}
          onClick={() => props.handleOpenDeleteProperty(propertyId)}
        >
          <MotifIcon iconFunction={IconoirTrash} size='24'  title="Delete this Property" />
        </MotifIconButton>
      </div>
    );
  }
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifIcon,
} from '@ey-xd/motif-react';
import { IconoirWarningCircle } from '@ey-xd/motif-icon';
import { addAttribute } from '../../common/Attribute';

const TemplateConfirmEditQuestionModal = ({
  showEditTemplateQuestionModal,
  handleCancelEditTemplateQuestion,
  handleEditTemplateQuestion,
}) => {
  useEffect(() => {
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close Edit Template Question modal',
    ariaLabel: 'Close Template Edit Question modal',
    dataTestid: 'close',
  };

  return (
    <React.Fragment>
      <MotifModal
        variant="alt"
        show={showEditTemplateQuestionModal}
        onClose={handleCancelEditTemplateQuestion}
        data-testid="TemplateConfirmEditQuestionModal"
      >
        <MotifModalHeader
          data-testid="h3EditTemplateQuestion"
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
            <MotifIcon style={{ color: '#F95D54' }} iconFunction={IconoirWarningCircle} size='24'   />
            <span className="ml-2">Confirm Edit Question</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <span
            className="checklistActionMsg mb-2"
            data-testid="spanEditConfirmation"
          >
            Are you sure you want to edit this question? If you are not sure, please add a new question.
          </span>
          <br />
          <span data-testid="spanNote">
            <b>Note: </b>
            A change made to this question will be reflected on other templates
              using this question. And questionnaire to questionnaire comparison may be 
              impacted by this change.
          </span>

          <MotifModalFooter className="pl-0">
            <MotifButton
              variant="secondary"
              className="right"
              data-testid="btnCancel"
              type="button"
              onClick={handleCancelEditTemplateQuestion}
            >
              Cancel
            </MotifButton>
            <MotifButton
              variant="primary"
              className="right"
              data-testid="btnEdit"
              type="button"
              onClick={handleEditTemplateQuestion}
            >
              Edit
            </MotifButton>
          </MotifModalFooter>
        </MotifModalBody>
      </MotifModal>
    </React.Fragment>
  );
};

TemplateConfirmEditQuestionModal.propTypes = {
  showEditTemplateQuestionModal: PropTypes.bool,
  handleCancelEditTemplateQuestion: PropTypes.bool,
  handleEditTemplateQuestion: PropTypes.func,
};

export default TemplateConfirmEditQuestionModal;

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { useSelector } from 'react-redux';
import {
    MotifButton,
    MotifSelect,
    MotifLabel,
    MotifFormField,
    MotifOption,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter
} from "@ey-xd/motif-react";
import { addAttribute } from "../../common/Attribute";

const CreatePeriod = ({
    toggleShowNewPeriodModal,
    showModal,
    handleModalAction,
    handleQuarterSelection,
    handleYearSelection,
    selectedQuarter,
    selectedYear,
    clientIsAudit,
}) => {
    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const quarterOptionValues = [
        "Quarter 1",
        "Quarter 2",
        "Quarter 3",
        "Quarter 4",
    ];
   const featureFlags = useSelector(
    state => state.featureManagementData
);
    const quarterOptions = quarterOptionValues.map((quarterOption) => {
        return {
            key: quarterOption,
            text: `Q${quarterOption.slice(-1)}`,
            value: quarterOption,
        };
    });

    const currentYear = new Date().getFullYear();
    let yearOptionValues = [];
    const dropdownStartYear = clientIsAudit == true ? currentYear - 1 : currentYear - 5;
    let initialYear = featureFlags && featureFlags['RestrictedReportPeriodLimit'] ? dropdownStartYear : currentYear - 5;

    for (let i = initialYear; i <= currentYear + 10; i++) {
        yearOptionValues.push(i.toString());
    }

    const yearOptions = yearOptionValues.map(yearOption => ({
        key: yearOption,
        text: yearOption,
        value: yearOption
    }));
    return (
        <div className="mb-2">
            <MotifButton
                variant="primary"
                type="button"
                value="NEW"
                onClick={toggleShowNewPeriodModal}
            >
                New Period
            </MotifButton>

            <MotifModal show={showModal} onClose={handleModalAction} variant="alt" className="create-period-motif-modal">
                <MotifModalHeader>Create a new period</MotifModalHeader>
                <Form onSubmit={handleModalAction}>
                    <MotifModalBody>
                        <div className="row">
                            <div className="col pr-0">
                                <MotifFormField>
                                    <MotifLabel position="in">Quarter</MotifLabel>
                                    <MotifSelect
                                        value={selectedQuarter}
                                        onChange={(v) => handleQuarterSelection(v)}
                                        labelPosition="in"
                                    >
                                        {quarterOptions.map(
                                            ({ key, value, text }) => (
                                                <MotifOption {...{ key, value }}>
                                                    {text}
                                                </MotifOption>
                                            )
                                        )}
                                    </MotifSelect>
                                </MotifFormField>
                            </div>

                            <div className="col pl-0">
                                <MotifFormField>
                                    <MotifLabel position="in">Year</MotifLabel>
                                    <MotifSelect
                                        value={selectedYear}
                                        onChange={(v) => handleYearSelection(v)}
                                        labelPosition="in"
                                    >
                                        {yearOptions.map(({ key, value, text }) => (
                                            <MotifOption {...{ key, value }}>
                                                {text}
                                            </MotifOption>
                                        ))}
                                    </MotifSelect>
                                </MotifFormField>
                            </div>
                        </div>
                    </MotifModalBody>
                    <MotifModalFooter className="margin-top-5">
                        <MotifButton
                            variant="secondary"
                            className="right"
                            type="button"
                            onClick={handleModalAction}
                            value="CANCEL"
                        >
                            Cancel
                        </MotifButton>
                        <MotifButton
                            variant="primary"
                            className="right"
                            type="submit"
                            value="CREATE"
                        >
                            Ok
                        </MotifButton>
                    </MotifModalFooter>
                </Form>
            </MotifModal>
        </div>
    );
};

CreatePeriod.propTypes = {
    toggleShowNewPeriodModal: PropTypes.func,
    showModal: PropTypes.bool,
    handleModalAction: PropTypes.func,
    handleCreateTypeSelection: PropTypes.func,
    handleQuarterSelection: PropTypes.func,
    handleYearSelection: PropTypes.func,
    selectedQuarter: PropTypes.string,
    selectedYear: PropTypes.string,
    clientIsAudit: PropTypes.bool,
};

export default CreatePeriod;

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifFormField,
  MotifLabel,
  MotifInput,
  MotifModalFooter,
  MotifButton,
} from '@ey-xd/motif-react';

import { addAttribute } from '../../common/Attribute';

const AddSectionModal = ({
  showAddSectionModal,
  handleAddSection,
  toggleAddSectionModal,
  sectionDetail,
}) => {
  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  useEffect(() => {
    setNewSection(sectionDetail ? sectionDetail.sectionName : '');
  }, [sectionDetail]);

  useEffect(() => {
    setAddEditButtonText(sectionDetail ? 'Update' : 'Add');
  }, [sectionDetail]);

  const [newSection, setNewSection] = useState('');
  const [addEditButtonText, setAddEditButtonText] = useState('');

  const cancelSection = (_event) => {
    setNewSection('');
    toggleAddSectionModal();
  };

  const addSection = (_event, newSection) => {
    setNewSection('');
    handleAddSection(
      newSection,
      sectionDetail ? sectionDetail.sectionSortOrder : null
    );
  };

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close Add checklist users modal',
    ariaLabel: 'Close Add checklist users modal',
    dataTestid: 'close',
  };

  return (
    <React.Fragment>
      <MotifModal
        variant="alt"
        show={showAddSectionModal}
        onClose={(event) => cancelSection(event)}
        data-testid="AddSectionModal"
      >
        <MotifModalHeader
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
            {(sectionDetail && <span className="ml-2">Edit Section</span>) || (
              <span className="ml-2">Add a New Section</span>
            )}
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <MotifFormField>
            <MotifLabel position="in">Section Name (Required)</MotifLabel>
            <MotifInput
              value={newSection}
              placeholder="Enter a new Section Name"
              onChange={(event) => setNewSection(event.target.value)}
              data-testid="lblSectionNameFooter"
              maxlength="255"
              labelPosition="in"
            />
          </MotifFormField> 
        </MotifModalBody>
        <MotifModalFooter>
          <MotifButton
              variant="secondary"
              type="button"
              onClick={(event) => cancelSection(event)}
              className="right"
              data-testid="btnAddSectionCancel"
            >
              Cancel
            </MotifButton>
            <MotifButton
              type="button"
              onClick={(event) => addSection(event, newSection)}
              className="right"
              data-testid="btnAddSectionSave"
              disabled={newSection === ''}
            >
              {addEditButtonText}
            </MotifButton>
          </MotifModalFooter>
      </MotifModal>
    </React.Fragment>
  );
};

AddSectionModal.propTypes = {
  showAddSectionModal: PropTypes.bool,
  toggleAddSectionModal: PropTypes.bool,
  handleAddSection: PropTypes.func,
  sectionDetail: PropTypes.object,
};

export default AddSectionModal;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Message } from 'semantic-ui-react';
import ErrorSummary from '../shared/ErrorSummary';
import { FileField } from "../shared/formFields";
import * as reitActions from '../../actions/reitActions';
import $ from 'jquery';
import * as formParsers from "../../scripts/formParsers";
import { MotifButton, MotifToast, MotifFormField, MotifLabel, MotifSelect, MotifOption } from '@ey-xd/motif-react';
import { useEffectToggle } from "../../scripts/customHooks";
import { addAttribute } from "../../common/Attribute";
import { MotifInput } from '@ey-xd/motif-react';

const TrialBalanceUpload = ({ onSubmit, onFileChange, file, reit, errorResponse, tbUploadSuccess, canEdit, createTrialBalancePurposeSelectItems,
    handleTrialBalancePurposeDropdownSelected, property, selectedTBOption, handleDismissErrorMessage, handleDismissSuccessMessage, percentageOfOwnership, handlePctChanged,
    tbMode, clientId, period, handleDownloadTbTemplates, chartExistsForClient }) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const selectAllText = () => {
        $(document).on('click', 'input[type=text]', function () { this.select(); });
    };

    const clearFileInput = (type) => {
        $('input[name=tbFileToUpload]').val("");
    };

    const renderDropdown = useEffectToggle([selectedTBOption === "0"]);

    return (
        <React.Fragment>
            <div>
                <form onSubmit={onSubmit} style={{ width: '100%' }}>
                    <div className="row">
                        <div className="col-md-3" style={{ display: 'inline-block', width: '100%' }}>
                            {tbMode === 1 && <div>
                                <div className="form-group">
                                    {
                                        renderDropdown && (
                                            <MotifFormField>
                                                <MotifLabel aria-label="Select an entity dropdown" position="in">
                                                    For...
                                                </MotifLabel>
                                                <MotifSelect
                                                    labelPosition="in"
                                                    onChange={handleTrialBalancePurposeDropdownSelected}
                                                    value={selectedTBOption}
                                                    role="dropdown"
                                                    aria-label="Select an entity dropdown"
                                                    placeholder="Select an Entity">
                                                    {createTrialBalancePurposeSelectItems()}
                                                </MotifSelect>
                                            </MotifFormField>
                                        )
                                    }
                                </div>
                                {property && property > 0 &&
                                    <div className="form-group margin-top-5">
                                        Ownership %:
                                        <span>
                                            <MotifInput
                                                type="text"
                                                className="ml-1 motif-input-has-not-label"
                                                id="pctOfO"
                                                aria-label="Edit ownership percentage"
                                                onFocus={selectAllText}
                                                onChange={handlePctChanged}
                                                disabled={!canEdit}
                                                value={formParsers.percentageOfOwnership(percentageOfOwnership).toString()}
                                                labelPosition="in"
                                            />
                                        </span>
                                    </div>
                                    || null}
                            </div> || <div>&nbsp;</div>}
                        </div>
                        <div className="col-md-9" style={{ display: 'inline-block', width: '100%' }}>
                            <div className="form-group m-0">
                                <FileField
                                    name="tbFileToUpload"
                                    onChange={onFileChange}
                                    className="form-control tbinput"
                                    disabled={!canEdit}
                                    accept=".xlsx"
                                />
                                <MotifButton variant="primary" className="left ml-2 mb-2" type="submit"
                                    disabled={!file || property === null}
                                    style={{ display: 'inline-block' }}
                                    onMouseUp={clearFileInput}>Upload</MotifButton>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {(errorResponse &&
                <div className="mt-3 mb-2">
                    <ErrorSummary  errorResponse={errorResponse} handleDismiss={handleDismissErrorMessage} />
                </div>
            ) || (tbUploadSuccess && (
                <MotifToast className="mt-3 mb-2" variant="success" onClose={handleDismissSuccessMessage}>Your trial balance has been uploaded to REITSuite for processing.</MotifToast>
            ))}
        </React.Fragment>
    );
};

TrialBalanceUpload.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onFileChange: PropTypes.func.isRequired,
    reit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }),
    file: PropTypes.object,
    errorResponse: PropTypes.object,
    tbUploadSuccess: PropTypes.bool,
    selectedTBOption: PropTypes.string,
    canEdit: PropTypes.bool,
    createTrialBalancePurposeSelectItems: PropTypes.func.isRequired,
    handleTrialBalancePurposeDropdownSelected: PropTypes.func.isRequired,
    property: PropTypes.number,
    handleDismissErrorMessage: PropTypes.func.isRequired,
    handleDismissSuccessMessage: PropTypes.func.isRequired,
    percentageOfOwnership: PropTypes.string,
    handlePctChanged: PropTypes.func.isRequired,
    tbMode: PropTypes.number,
    clientId: PropTypes.number.isRequired,
    period: PropTypes.object,
    handleDownloadTbTemplates: PropTypes.func.isRequired
};

export default TrialBalanceUpload;

import PropTypes from "prop-types";
import React from "react";
import { MotifButton,MotifModal,MotifModalHeader,MotifModalBody,MotifModalFooter,MotifIcon } from "@ey-xd/motif-react";
import { IconoirWarningCircle } from '@ey-xd/motif-icon';

const DeactivateClientDisplayErrorMessageModal = ({ showDeactivateErrorMessageModal, handleCancel, confirmationMessage,headerTitle
    }) => {
        const HeaderWrapper = ({ children }) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center'
                }}
            >
                {children}
            </div>
        );

        const closeButtonProps = {
            title: headerTitle,
            ariaLabel: headerTitle,
            dataTestid: 'close'
        };

    return (   
        <MotifModal variant="alt" show={showDeactivateErrorMessageModal} onClose={handleCancel} data-testid="Delete">
        <MotifModalHeader closeButtonProps={closeButtonProps} >
            <HeaderWrapper>
                <MotifIcon className="mt-2" style={{ color: "#F95D54" }} iconFunction={IconoirWarningCircle} size='24'   />
                <span className="ml-2 mt-1">{headerTitle}</span>
            </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
            <span className="deleteActionMsg">{confirmationMessage}</span>
        </MotifModalBody>
        <MotifModalFooter>
            
            <MotifButton variant="warn" className="right"
                data-testid="Delete"
                type="button"
                onClick={handleCancel}>Close</MotifButton>
        </MotifModalFooter>
        </MotifModal>
    );
};

DeactivateClientDisplayErrorMessageModal.propTypes = {
    showDeactivateErrorMessageModal: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    confirmationMessage: PropTypes.string.isRequired,
};

export default DeactivateClientDisplayErrorMessageModal;
import React from "react";
import PropTypes from "prop-types";
import { MotifButton, MotifIcon, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';
import { IconoirWarningCircle } from '@ey-xd/motif-icon';

const DeleteModal = ({
    showDeleteModal,
    handleDelete,
    toggleshowDeleteModal,
    closeTitle,
    headerTitle,
    confirmationMessage,
    size
}) => {
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );

    const closeButtonProps = {
        title: closeTitle,
        ariaLabel: closeTitle,
        dataTestid: 'close'
    };

    return (
        <MotifModal variant="alt" size={size} show={showDeleteModal} onClose={toggleshowDeleteModal} data-testid="Delete">
            <MotifModalHeader closeButtonProps={closeButtonProps} >
                <HeaderWrapper>
                    <MotifIcon style={{ color: "#F95D54" }} iconFunction={IconoirWarningCircle} size='24'   />
                    <span className="ml-2">{headerTitle}</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <span className="deleteActionMsg">{confirmationMessage}</span>

            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" className="right"
                    data-testid="Cancel"
                    type="button"
                    onClick={toggleshowDeleteModal}>Cancel</MotifButton>
                <MotifButton variant="warn" className="right"
                    data-testid="Delete"
                    type="button"
                    onClick={handleDelete}>Delete</MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

DeleteModal.propTypes = {
    showDeleteModal: PropTypes.bool,
    handleDelete: PropTypes.func,
    toggleshowDeleteModal: PropTypes.func,
    closeTitle: PropTypes.string,
    headerTitle: PropTypes.string,
    confirmationMessage: PropTypes.string,
    size: PropTypes.string
};

export default DeleteModal;
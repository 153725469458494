import React, { Component } from 'react';
import { connect } from 'react-redux';


class AuthorizedUsersContainer extends Component {
    componentDidMount() {        
    }

    render() {       

        return (
            <div>
                <h1>Authorized Users</h1>               
            </div>
        );
    }   
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedUsersContainer);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class MasterDataManagementContainer extends Component {
    componentDidMount() {
       
    }

    render() {
        const { masterData } = this.props;

        return (
            <div>
                <h1>Master Data Management</h1>
               
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        masterData: state.masterData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({}, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterDataManagementContainer);
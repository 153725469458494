import React from 'react';
import PropTypes from 'prop-types';

import { MotifButton, MotifIcon } from '@ey-xd/motif-react';
import { IconoirPlus } from '@ey-xd/motif-icon';

const PsqCustomChecklistTemplateFooter = ({
  toggleAddSectionModal,
  handleOnClickCreateNewQuestion,
  isNewQuestionForSectionEnabled,
  showNewQuestionAvailablePanel = false,
  isTemplateEditedByOtherUser
}) => {
  return (
    <React.Fragment>
      <div
        style={{
          border: '0.5px solid #C4C4C4',
          flex: '1',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          className="col-md-8"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '18px',
          }}
        >
        {!isTemplateEditedByOtherUser && <>
          <MotifButton
            variant="text"
            style={{ width: '10rem' }}
            data-testid="btnNewSection"
            onClick={toggleAddSectionModal}
          >
            <MotifIcon iconFunction={IconoirPlus} size='24'  />
            New Section
          </MotifButton>
          <MotifButton
            variant="text"
            style={{ width: '11rem' }}
            data-testid="btnNewQuestion"
            onClick={() => handleOnClickCreateNewQuestion('templateSections')}
            disabled={!isNewQuestionForSectionEnabled}
          >
            <MotifIcon iconFunction={IconoirPlus} size='24'  />
            New Question
          </MotifButton>
        </>}
        </div>
      </div>
      <div
        style={{ border: '0.5px solid #C4C4C4', flex: '1', display: 'flex' }}
      >
        <div
          className="col-md-6"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '18px',
          }}
        >
          {showNewQuestionAvailablePanel && !isTemplateEditedByOtherUser &&
          (
          <MotifButton
            variant="text"
            style={{ width: '11rem' }}
            data-testid="btnNewQuestion"
            onClick={() => handleOnClickCreateNewQuestion('availableQuestions')}
          >
            <MotifIcon iconFunction={IconoirPlus} size='24'  />
            New Question
          </MotifButton>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

PsqCustomChecklistTemplateFooter.propTypes = {
  toggleAddSectionModal: PropTypes.func,
  handleOnClickCreateNewQuestion: PropTypes.func,
  isNewQuestionForSectionEnabled: PropTypes.bool,
  showNewQuestionAvailablePanel:PropTypes.bool,
};

export default PsqCustomChecklistTemplateFooter;

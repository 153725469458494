import React from 'react';
import PropTypes from 'prop-types';

import {
    MotifIconButton,
    MotifIcon,
    MotifDropdown,
    MotifDropdownItem,
    MotifAvatar,
    MotifToggleSwitch
} from "@ey-xd/motif-react";
import { IconoirLogOut } from '@ey-xd/motif-icon';

const UserAvatarComponent = ({
    isUserDropdownOpen,
    handleToggleUserDropdownWhenClickedOutside,
    handleToggleUserDropdown,
    userName,
    userEmail,
    userRole,
    userProfilePictureUrl,
    navToLogoff,
    handleThemesToggle,
    isDarkTheme
}) => {
    return (
        <> 
        <MotifDropdown
                open={isUserDropdownOpen}
                placement="bottom-end"
                aria-labelledby="User Avatar Dropdown"
                handleClickOutside={handleToggleUserDropdownWhenClickedOutside}
                trigger={
                    <MotifIconButton
                        type="button"
                        onClick={handleToggleUserDropdown}
                        id="userDropDownTrigger"
                        aria-label="User Options"
                    >
                        <MotifAvatar
                            userName={userName}
                            description={`${userName} ${userEmail}`}
                            status={null}
                            size="small"
                            src={userProfilePictureUrl}
                        />
                    </MotifIconButton>
                }
            >
                <MotifDropdownItem                    
                    style={{
                        height: '82px',
                        pointerEvents: 'none',
                        width: '100%'
                    }}
                    onClick={() => { }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <div style={{ flex: '1', margin: 'auto' }}>
                            <MotifAvatar
                                userName={userName}
                                description={`${userName} ${userEmail}`}
                                status={null}
                                size="large"
                                src={userProfilePictureUrl}
                            />
                        </div>
                        <div className='ml-3 mt-3' style={{ flex: '1.5' }}>
                            <h6>{userName}</h6>
                            <div style={{ fontSize:'14px' }}>{userEmail}</div>
                            <div style={{ fontSize:'14px' }}>{userRole}</div>
                        </div>
                    </div>
                </MotifDropdownItem>
                <span className="motif-dropdown-horizontal-divider mt-2" />
                {/* <MotifDropdownItem
                    style={{
                        height: '20px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{ flex: '1', textAlign: 'end', marginLeft:'-5%' }}
                        >
                           <h6>
                                Theme
                            </h6>
                        </div>
                        <div style={{ flex: '1',transform:'scale(0.75)', marginLeft:'-2%', marginTop:'1%' }}>
                        <MotifToggleSwitch
                                            aria-label="Themes"
                                            name="switchTheme"
                                            data-testid="btnThemeToggle"
                                            id="switchTheme"
                                            onChange={handleThemesToggle}
                                            checked={!!isDarkTheme}
                                            offLabel='Light'
                                            onLabel='Dark'
                                        />
                        </div>
                    </div>
                </MotifDropdownItem> */}
                <MotifDropdownItem
                    style={{
                        height: '40px'
                    }}
                    onClick={navToLogoff}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft:'-4px',
                            width: '100%',
                        }}

                    > 
                            <MotifIcon
                                iconFunction={IconoirLogOut} size='24' 
                                title="Log out"
                                className='logoff-icon mt-2 mr-2'
                            />
                            <span className='mt-2'>Log out</span>
                            
                    </div>
                </MotifDropdownItem>
            </MotifDropdown>
        </>
    );
};

UserAvatarComponent.propTypes = {
    isUserDropdownOpen: PropTypes.bool,
    handleToggleUserDropdownWhenClickedOutside: PropTypes.func,
    handleToggleUserDropdown: PropTypes.func,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userRole: PropTypes.string,
    userProfilePictureUrl: PropTypes.string,
    navToLogoff: PropTypes.func,
    handleThemesToggle: PropTypes.func,
    isDarkTheme: PropTypes.bool
};

export default UserAvatarComponent;
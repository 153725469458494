import React, { useState } from 'react';
import { Field } from 'redux-form';
import { PropTypes } from "prop-types";
import * as formHelpers from '../../scripts/formHelpers';
import * as Constants from '../../constants/other';
import {
    MotifButton,
    MotifDropdown,
    MotifDropdownItem,
    MotifIcon,
    MotifIconButton,
    MotifTooltip,
} from '@ey-xd/motif-react';
import { IconoirNavArrowDown, IconoirInfoCircle } from '@ey-xd/motif-icon';
const BulkProcessManagementHeader = ({
    calendarYears,
    handleCalendarYearSelection,
    handleReportPeriodSelection,
    rowsAffected,
    gridCurrentState,
    toggleShowSignOffModal,
    toggleServiceScopeModal,
    isAuditOnlyClient
}) => {

    const calendarYearOptions = calendarYears.map(cy => {
        return { key: cy, value: cy, text: cy }
    });

    let updateServicesScopeStatus = false;
    let updateSignOffStatus = false;
    let exportBulkProcessHistoryStatus = true;
    // Check if there is no reit selected 
    if (!gridCurrentState || !gridCurrentState.length > 0) {
        updateServicesScopeStatus = false;
        updateSignOffStatus = false;

    }
    // Check if there is any reit selected
    else if (gridCurrentState && gridCurrentState.length > 0) {
        if (gridCurrentState.some(x => x.reitSelection == true)) {
            updateServicesScopeStatus = true;
            updateSignOffStatus = true;

        }
    }

    const [actionOptionsOpen, setActionOptionsOpen] = useState(false);
    return (
        <>
            <h2>Bulk Process Management</h2>
            {/* <div style={{ minHeight: '24px' }}></div> */}
            <div className="row align-items-center">
                <div className="col-md-2">
                    <Field
                        name="calendarYear"
                        label="Calendar Year"
                        type="select"
                        data-testid="calendarYear"
                        onChange={handleCalendarYearSelection}
                        options={calendarYearOptions}
                        component={formHelpers.SingleSelectComponent}
                        defaultValue={calendarYears[0]}
                    />
                </div>
                <div className="col-md-2">
                    <Field
                        name="reportPeriod"
                        label="Report Period"
                        type="select"
                        data-testid="reportPeriod"
                        onChange={handleReportPeriodSelection}
                        options={Constants.reportPeriodQuarter}
                        component={formHelpers.SingleSelectComponent}
                        defaultValue={Constants.reportPeriodQuarter[0].value}

                    />
                </div>
                <div className="col-md-8 mt-5 d-flex justify-content-end">
                    <div>
                        <MotifDropdown
                            open={actionOptionsOpen}
                            placement="bottom-end"
                            id={'action-dropdown'}
                            aria-labelledby={'bulk-process-trigger'}
                            handleClickOutside={() => setActionOptionsOpen(false)}
                            trigger={

                                <MotifButton
                                    type="button"
                                    variant="primary"
                                    onClick={() => setActionOptionsOpen(!actionOptionsOpen)}
                                    aria-controls={'action-dropdown'}
                                    id={'action-dropdown-trigger'}
                                    aria-expanded={actionOptionsOpen}
                                    aria-label="Action dropdown"
                                    data-testid="actions"
                                >
                                    Actions <MotifIcon iconFunction={IconoirNavArrowDown} size='24' />
                                </MotifButton>

                            }
                        >
                        <ul role="menu">
                            {(updateServicesScopeStatus || isAuditOnlyClient) &&
                                <li role="menuitem">  
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleServiceScopeModal(); }} type="button"
                                        disabled={!updateServicesScopeStatus || isAuditOnlyClient}
                                        data-testid="updateServicesScope">
                                        Update Services Scope
                                    </MotifDropdownItem>
                                </li>
                                ||
                                <li role="menuitem"> 
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleServiceScopeModal(); }} type="button"
                                                disabled={!updateServicesScopeStatus} data-testid="updateServicesScope"
                                            >
                                                Update Services Scope
                                                <MotifIconButton className="ml-2 mr-5" aria-label="Update Services Scope" type="button">
                                                    <MotifIcon iconFunction={IconoirInfoCircle} size='24'/>
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        {Constants.bulkProcessManagementMessages.toolTipMessage}
                                    </MotifTooltip>
                                </li>
                            }

                            {updateSignOffStatus &&
                                <li role="menuitem">
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowSignOffModal(); }} type="button"
                                        disabled={!updateSignOffStatus}
                                        data-testid="deleteUsers"   >
                                        Update Sign Off Status
                                    </MotifDropdownItem>
                                </li>
                                ||
                                <li role="menuitem">
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowSignOffModal(); }} type="button"
                                                disabled={!updateSignOffStatus} data-testid="updateSignOffStatus">
                                                Update Sign Off Status
                                                <MotifIconButton className="ml-2" aria-label="Update Sign Off Status" type="button">
                                                    <MotifIcon iconFunction={IconoirInfoCircle} size='24'/>
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        {Constants.bulkProcessManagementMessages.toolTipMessage}
                                    </MotifTooltip>
                                </li>
                            }

                           </ul>
                        </MotifDropdown>
                    </div>
                </div>
            </div>
        </>
    );
}

BulkProcessManagementHeader.propTypes = {
    calendarYears: PropTypes.array,
    handleCalendarYearSelection: PropTypes.func,
    handleReportPeriodSelection: PropTypes.func,
    gridCurrentState: PropTypes.array,
    toggleShowSignOffModal: PropTypes.func,
    toggleServiceScopeModal: PropTypes.func,
    isAuditOnlyClient: PropTypes.bool
}

export default BulkProcessManagementHeader;
import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import AddSysAdminModal from "./AddSysAdminModal";
import { MotifFormField, MotifLabel, MotifSearch, MotifButton, MotifTable } from "@ey-xd/motif-react";
import { AgGridReact } from 'ag-grid-react';
import { handleFilterChanged,handleFirstDataRendered,handleGridReady,tableHeaderClass,handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
 import CustomPagination from '../../common/MotifPagination';
 import SystemAdminCellRenderer from '../shared/customRenderers/SystemAdminCellRenderer';
 import CustomHeaderTemplate from "../shared/customHeaders/CustomHeaderTemplate.js";

 const AdminAuthList = ({ auths, handleSearchChange, handleClearSearchText, adminUserSearchText, handleEditButtonClick, handleDeleteButtonClick,
    handleNewButtonClick, showAddSysAdminModal, handleAddSysAdminModalAction, auth, toggleShowAddSysAdminModal, currentUserId, addAuthMessageText, validateDomain }) => {
    
        const gridRef = useRef();
        const [rowData, setRowData] = useState([]);
        const [currentPage, setCurrentPage] = useState(1);
        const [totalPages, setTotalPages] = useState(1);
        const [itemsPerPage, setItemsPerPage] = useState(100);
    
        //Default column settings
        const defaultColDef = useMemo(() => {
            return {
                flex: 1,
                minWidth: 65,
                filter: true,
                resizable: true,
                sortable: true,
                wrapText: true,
                autoHeight: true,
            };
        }, []);
    
        const getColumnDefs = () => {
            return [
                {
                    field: 'userId', headerName: 'User', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 550,
                    headerComponentParams: {template: CustomHeaderTemplate("userId"),},
                    comparator: (a, b) => {
                        var valA = a.toString().toLowerCase();
                        var valB = b.toString().toLowerCase();
                        if (valA === valB) return 0;
                        return valA > valB ? 1 : -1;
                    },
                },
                {
                    field: 'userId',
                    headerName: 'Role',
                    sortable: true,
                    showRowGroup: false,
                    hide: false,
                    rowGroup: false,
                    minWidth: 350,
                    valueGetter: (params) => {
                        return getRoleName(params.data.role);
                    },
                    headerComponentParams: {template: CustomHeaderTemplate("userId"),},
                  },
                  {
                    field: 'editIcon', headerName: '', suppressMenu: true,suppressHeaderFilterButton: true, 
                    minWidth: 125,
                    maxWidth: 125,
                    cellRenderer: SystemAdminCellRenderer, cellRendererParams: { fieldName: 'editIcon' ,handleDeleteButtonClick, currentUserId},
                }
                
            ];
        };
    
        const getRoleName = (role) => {
            if (role === 1) {
                return 'System Administrator';
            } else if (role === 4) {
                return 'External Checklist User';
            } else {
                return 'Engagement Administrator';
            }
        };

        //Handle deselect all event
        const handleDeselectAll = (val) => {
            // set current page and total pages to 0
            setCurrentPage(0);    
            setTotalPages(0);      
        }; 

        //Handle Grid Page change event
        const handlePageChange = (val) => {
            setCurrentPage(val);
            gridRef.current.api.paginationGoToPage(val-1);
        };
    
        const [columnDefs, setColumnDefs] = useState(getColumnDefs());
        useEffect(() => {
            if(rowData.length > 0){
                let calcTotalPages = gridRef && gridRef.current && gridRef.current.api && gridRef.current.api.paginationGetTotalPages();
                setTotalPages(calcTotalPages);
                //If total page is zero then set the current page as zero
                if (calcTotalPages == 0) {
                    setCurrentPage(calcTotalPages);
                }
               }
        }, [rowData]);
    
        useEffect(() => {
            setRowData(auths);
            if (gridRef && gridRef.current && gridRef.current.api) {
                
                // Get total pages from Grid's default pagination control and apply it to custom pagination control
                let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
                setTotalPages(calcTotalPages);
            }
        }, [auths]);
    
        const rowHeight = 73; 
        const headerHeight = 45; 
        const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

        return (
        <React.Fragment>
            <div className="row d-flex">
                
                <div className="col-md-12 pt-3 pb-2">
                    <MotifButton variant="primary" data-testid="addUser" className="right" onClick={handleNewButtonClick}>Add User</MotifButton>
                </div>
            </div>
            <div className="row d-flex justify-content-end mt-1">
                <div className="col-md-8" />
                <div className="col-md-4">
                    <MotifFormField>
                        <MotifSearch
                            data-testid="txtSearch" 
                            type="text"
                            onChange={handleSearchChange}
                            value={adminUserSearchText}
                            onSelect={handleClearSearchText}
                            items={[]}
                            title="Type in a user name"
                            aria-label="Search for User"                            
                            placeholder="Search for User"
                            searchButtonProps={{tabIndex:"-1","data-testid":"btnSearch"}} />
                    </MotifFormField>
                </div>
            </div>
            <div className="row mt-3">
            {(rowData.length > 0) &&
                <div className="col">
                        <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                            <MotifTable zebra={true} compact={true}
                            rowData={rowData}
                            columnDefs={getColumnDefs()}
                            defaultColDef={defaultColDef}
                            accentedSort={true}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            onGridReady={event => handleGridReady(event, gridRef,setCurrentPage,setItemsPerPage,setTotalPages,'REITSuiteSystemAdminListColumnState', handleDeselectAll)}
                            onFirstDataRendered={handleFirstDataRendered(gridRef)}
                            ref={gridRef}
                            pagination={true}
                            suppressPaginationPanel={true}
                            paginationPageSize={100}
                            groupDisplayType={'custom'}
                            groupSelectsChildren={true}
                            groupDefaultExpanded={0}
                            onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                            data-testid="divSystemAdminListGrid"
                            deltaRowDataMode={true} 
                            immutableData={true}
                            getRowNodeId={(data) => data.userId}
                        />
                        <div className="mt-3">
                            <span className="right mb-3">
                                <CustomPagination
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                    totalPages={totalPages}
                                    handleItemsPerPageChange={handleItemsPerPageChange}
                                    gridRef={gridRef}
                                    setCurrentPage={setCurrentPage}
                                    setItemsPerPage={setItemsPerPage}
                                    setTotalPages={setTotalPages}/> 
                            </span>
                        </div>
                    </div>
                </div>
                }
                {(auths && auths.length === 0 && adminUserSearchText === "" && <div><h5>No authorized user has been added for this client.</h5></div>) || ((auths && auths.length === 0 && adminUserSearchText !== "") && <div><h5>No authorized user match your search text &apos;{adminUserSearchText}&apos;</h5></div>) || null}
            </div>
            
            <AddSysAdminModal showAddSysAdminModal={showAddSysAdminModal} onSubmit={handleAddSysAdminModalAction} toggleShowAddSysAdminModal={toggleShowAddSysAdminModal} initialValues={auth} addAuthMessageText={addAuthMessageText} validateDomain={validateDomain} />
        </React.Fragment>
    );
};

AdminAuthList.propTypes = {
    auths: PropTypes.array.isRequired,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    adminUserSearchText: PropTypes.string,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    handleNewButtonClick: PropTypes.func,
    showAddSysAdminModal: PropTypes.bool,
    handleAddSysAdminModalAction: PropTypes.func,
    auth: PropTypes.object,
    toggleShowAddSysAdminModal: PropTypes.func,
    currentUserId: PropTypes.string,
    addAuthMessageText: PropTypes.string,
    validateDomain: PropTypes.func
};

export default AdminAuthList;
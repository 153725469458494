import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, formValueSelector, submit } from "redux-form";
import { connect } from 'react-redux';
import { Dropdown, Icon, Message } from "semantic-ui-react";
import * as amenities from "../../constants/amenities";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MotifIconButton, MotifIcon, MotifButton,MotifErrorMessage } from "@ey-xd/motif-react";
import { IconoirTrash } from '@ey-xd/motif-icon';
const PropertyAmenitiesTableComponent = ({ taxableReitSubsidiaryOptions, formName, readOnly, dispatch, shouldSubmitReduxFormOnChange }) => {
    let amenityRowProvider = new AmenityRowProvider(formName);
    return <FieldArray dispatch={dispatch} shouldSubmitReduxFormOnChange={shouldSubmitReduxFormOnChange} validate={validateAmenityRow} name="propertyAmenities" component={renderServicesTable} taxableReitSubsidiaryOptions={taxableReitSubsidiaryOptions} readOnly={readOnly} amenityRowProvider={amenityRowProvider} />;
};

PropertyAmenitiesTableComponent.propTypes = {
    taxableReitSubsidiaryOptions: PropTypes.array,
    formName: PropTypes.string,
    readOnly: PropTypes.bool,
    dispatch: PropTypes.func,
    shouldSubmitReduxFormOnChange: PropTypes.bool
};
const AmenityRow = ({ service, index, fields, isOtherSelected, isTrsChecked, taxableReitSubsidiaryOptions, readOnly, dispatch, shouldSubmitReduxFormOnChange }) => {
   
    let timerIdle = null;
    
    const removeRow = () => {
        fields.remove(index);
        if (shouldSubmitReduxFormOnChange) {
            clearTimeout(timerIdle);
            timerIdle = setTimeout(() => {
                dispatch(submit("checklist"));
            }, 800);
        }
    };
   
    const onChangeSubmit = (e) => {
        if (shouldSubmitReduxFormOnChange) {
            clearTimeout(timerIdle);
            timerIdle = setTimeout(() => {
                dispatch(submit("checklist"));
            }, 800);
        }
    };

    return (
        <tr key={index} style={{ backgroundColor: "#FFF", borderBottom: "solid 1px #dee2e6" }}>
            <td style={{marginTop: '20px'}}>                   
        <Field
            name={`${service}.propertyAmenityName`}
            component={formHelpers.SingleSelectComponent}
            type="select"
            filter
            placeholder="Select a service..."
            disabled={readOnly}
            options={amenities.options}
            value={fields.get(index).propertyAmenityName?.value}
            defaultValue={fields.get(index).propertyAmenityName?.value}
            onChange={onChangeSubmit}
            className="tenent-service-select"
            optionValue={(opt) => opt.value}
            optionText={(opt) => opt.label}
            style={{flexDirection: 'unset', overflowX: 'hidden'}}  
            lblPosition="out"
        />
       
    
       
<div  style={{ marginTop:'20px' }} >
 {(isOtherSelected || fields.get(index).propertyAmenityName?.value === "Other") && 
 (<div>
    please specify:<br />
<Field onChange={onChangeSubmit} name={`${service}.otherName`} maxLength={255} component={formHelpers.renderField} validate={formValidators.maxLength255} type="input" disabled={readOnly} />
</div>) || null}
</div>
            </td>
            <td style={{ verticalAlign: 'top' , paddingTop: '24px' ,marginTop: '20px'}}>
           
                <Field onChange={onChangeSubmit} name={`${service}.isProvidedByCustomary`} component={formHelpers.renderField} type="checkbox" disabled={readOnly} />
               
            </td>
            <td style={{ verticalAlign: 'top' , paddingTop: '24px' ,marginTop: '20px' }}>
            
                <Field onChange={onChangeSubmit} name={`${service}.isProvidedByEmployee`} component={formHelpers.renderField} type="checkbox" disabled={readOnly} />
            
                </td>
            <td style={{ verticalAlign: 'top' , paddingTop: '24px' ,marginTop: '20px' }}>
               
                <Field onChange={onChangeSubmit} name={`${service}.isProvidedByIndependentContractor`} component={formHelpers.renderField} type="checkbox" disabled={readOnly} />
               </td>
               <td  className={`d-flex align-items-center justify-content-start h-100 prop-amenity-trs-td `}>
               <div style={{paddingTop:  '8px',verticalAlign: 'top'}}> <Field  onChange={onChangeSubmit} name={`${service}.isProvidedByTaxableReitSubsidiary`} component={formHelpers.renderField} type="checkbox" disabled={readOnly} /></div>
                {(isTrsChecked && Array.isArray(taxableReitSubsidiaryOptions) && taxableReitSubsidiaryOptions.length > 0 && [
                    (<div  className="flex-grow-1 ml-2" style={{ wordWrap: "break-word", minWidth: '150px', verticalAlign: 'top', paddingBottom:'35px' }} >
                       
                        <Field
                            name={`${service}.taxableReitSubsidiary`}
                            component={formHelpers.SingleSelectComponent}
                            key={"field-" + index}
                            type="select"
                            filter
                            placeholder="Select a TRS..."
                            disabled={readOnly}                            
                            options={taxableReitSubsidiaryOptions}
                            value={fields.get(index).taxableReitSubsidiary?.value}
                            defaultValue={fields.get(index).taxableReitSubsidiary?.value?fields.get(index).taxableReitSubsidiary.value:fields.get(index).taxableReitSubsidiary}
                            onChange={onChangeSubmit}
                            className="trs-select"
                            optionValue={(opt) => opt.value}
                            optionText={(opt) => opt.label}
                            style={{flexDirection: 'unset'}}   
                            lblPosition="out"                     
                        />
                         </div>)
                ]) || <div style={{ marginTop:"12px"}} className="h-100">&nbsp;</div>}                 
            </td>
            <td style={{ verticalAlign: 'top' , paddingTop: '20px'  ,marginTop: '20px' }}>{(!readOnly && <span>
                <MotifIconButton aria-label="Delete this Service/Amenity" type="button"
                    onClick={removeRow}>
                    <MotifIcon iconFunction={IconoirTrash} size='24'  title="Delete this Service/Amenity" />
                </MotifIconButton>
            </span>) || null}</td>
        </tr>
    );
};

AmenityRow.propTypes = {
    service: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    fields: PropTypes.object.isRequired,
    isOtherSelected: PropTypes.bool,
    isTrsChecked: PropTypes.bool,
    taxableReitSubsidiaryOptions: PropTypes.array,
    readOnly: PropTypes.bool,
    dispatch: PropTypes.func,
    shouldSubmitReduxFormOnChange: PropTypes.bool
};

/**
 * Provider for amenity rows
 * @extends {React.Component}
 */
export class AmenityRowProvider {
    constructor(formName) {
        this.selector = formValueSelector(formName);
        this.AmenityRow = connect((state, props) => ({
            isOtherSelected: (this.selector(state, `${props.service}.propertyAmenityName`) && this.selector(state, `${props.service}.propertyAmenityName`) === "Other") || false,
            isTrsChecked: !!this.selector(state, `${props.service}.isProvidedByTaxableReitSubsidiary`),            
        }))(AmenityRow);
    }
}

const validateAmenityRow = values => {
    const errorArray = [];

    if (values) {
        values.forEach(value => {
            let error = undefined;
            if (!value.propertyAmenityName) {
                error = "Please select a service";
            } else if (value.propertyAmenityName && value.propertyAmenityName === "Other" && !value.otherName) {
                error = "Please specify the name of the service";
            }

            errorArray.push(error);
        });
    }

    if (errorArray.some(item => !!item)) {
        return errorArray;
    } else {
        return undefined;
    }
};

const renderServicesTable = ({ fields, meta: { error }, taxableReitSubsidiaryOptions, readOnly, amenityRowProvider, dispatch, shouldSubmitReduxFormOnChange }) => {
    fields = fields || [];
    readOnly = !!readOnly;

    const createNewRow = () => {
        fields.push({});
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Service Name</th>
                        <th>Customary</th>
                        <th>Employee</th>
                        <th>IK</th>
                        <th>TRS</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {fields.length === 0 ? <tr><td colSpan={6}>Please add a service</td></tr> : fields.map(
                        (service, index) => {
                            let errorRow = null;
                            if (error && error[index]) {
                                errorRow = <tr style={{ backgroundColor: "#FFF" }}><td colSpan="6"><MotifErrorMessage >{error[index]}</MotifErrorMessage></td></tr>;
                            }
                            return [errorRow, (
                                <amenityRowProvider.AmenityRow shouldSubmitReduxFormOnChange={shouldSubmitReduxFormOnChange} dispatch={dispatch} service={service} index={index} fields={fields} key={index} taxableReitSubsidiaryOptions={taxableReitSubsidiaryOptions} readOnly={readOnly} />
                            )];
                        }
                    )}
                </tbody>
            </table>
            {(!readOnly &&
                <MotifButton variant="secondary" className="mt-2" type="button"
                                onClick={createNewRow}>
                New Row
                </MotifButton>
            ) || null}
        </div>
    );
};

renderServicesTable.propTypes = {
    fields: PropTypes.object.isRequired,
    meta: PropTypes.object,
    taxableReitSubsidiaryOptions: PropTypes.array,
    readOnly: PropTypes.bool,
    amenityRowProvider: PropTypes.object,
    dispatch: PropTypes.func,
    shouldSubmitReduxFormOnChange: PropTypes.bool
};

export default PropertyAmenitiesTableComponent;
import React, {useEffect } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm,reset } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { PhoneField } from "../shared/formFields";
import {useDispatch } from 'react-redux';
import { MotifButton, MotifToast, MotifFormField, MotifToggleSwitch, MotifLabel } from '@ey-xd/motif-react';

const EditClientForm = ({
    handleSubmit,
    initialValues,
    clientFormPurpose,
    showSuccess,
    error,
    submitting,   
    currentUserIsSysAdmin,
    handleDismissErrorMessage, handleDismissSuccessMessage, handleToggleActiveFlag, isActive, isAudit, handleToggleAuditFlag,
    handleEditClientCancel, validateDomain
}) => {

    const dispatch = useDispatch();
    let operationMessage = "Client created successfully!";
    if (clientFormPurpose === "EDIT") {
        operationMessage = "Client updated successfully!";
    }
    
    const client = Object.assign({}, initialValues);
    const isDisabled = !currentUserIsSysAdmin;
    const toggleActiveFlag = (e) => {
        handleToggleActiveFlag(e, client.id, client.name, client.legalName, isDisabled);
    };   
    const toggleAuditFlag = (e) => {
        handleToggleAuditFlag(e,isDisabled);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            
            if (currentUserIsSysAdmin == false) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (showSuccess) {
            dispatch(reset('client')); // Reset the form to clear the dirty state
        }
    }, [showSuccess, dispatch]);

    return (        
        <form onSubmit={(e) => {e.preventDefault(); handleSubmit();}}>
        <div>
            {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
            {showSuccess &&
                <MotifToast className="mb-3" variant="success" onClose={handleDismissSuccessMessage}>{operationMessage}</MotifToast>
            }
            <div className="row">

                <div className="col-md-6 field">
                    <Field name="legalName"
                        component={formHelpers.renderField}
                        type="text"
                        maxLength={50}
                        label="Full Legal Name (Required)"
                        validate={[formValidators.required, formValidators.minLength2, formValidators.maxLength50]}
                    />
                </div>

                <div className="col-md-3 field">
                    <Field
                        name="name"
                        component={formHelpers.renderField}
                        type="text"
                        maxLength={50}
                        label="Short Name (Required)"
                        validate={[formValidators.required, formValidators.minLength2, formValidators.maxLength50]}
                    />
                </div>

                <div className="col-md-3 d-flex">
                    <div>
                        <label aria-label={`Active toggle button ${isActive ? 'on' : 'off'}`}>Active: </label>
                        <div>
                            <MotifFormField aria-hidden="true">
                                <MotifToggleSwitch
                                    checked={isActive}
                                    disabled={!currentUserIsSysAdmin}
                                    onChange={toggleActiveFlag}
                                    id="isActiveToggle"
                                    name="isActive"
                                />
                            </MotifFormField>
                        </div>
                    </div>
                    <div className="ml-5">
                        <MotifLabel aria-label={`Audit - ARQC Checklist Only toggle button ${isAudit ? 'on' : 'off'}`}>Audit - ARQC Checklist Only: </MotifLabel>
                        <div>
                            <MotifFormField aria-hidden="true">
                                <MotifToggleSwitch
                                    checked={isAudit}
                                    disabled={!currentUserIsSysAdmin}
                                    onChange={toggleAuditFlag}
                                    id="isAuditToggle"
                                    name="isAudit"
                                />
                            </MotifFormField>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-6 field">
                    <Field
                        name="gfisCode"
                        component={formHelpers.renderField}
                        type="text"
                        maxLength={50}
                        label="Client ID"
                        disabled={!currentUserIsSysAdmin}
                        validate={[formValidators.maxLength50]}
                    />
                </div>

                <div className="col-md-6 field">
                    <Field
                        name="engagementNumber"
                        component={formHelpers.renderField}
                        type="text"
                        maxLength={50}
                        label="Client Engagement Number"
                        validate={[formValidators.maxLength50]} />
                </div>
            </div>

            <section className="client-contact-info">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Client Contact Information</h2>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4 field">
                        <Field name="contactName"
                            component={formHelpers.renderField}
                            type="text"
                            maxLength={50}
                            label="Contact Name"
                            validate={[formValidators.maxLength50]}
                        />
                    </div>
                    <div className="col-md-4 field">
                        <PhoneField name="contactPhone" label="Phone"
                        validate={[                            
                            formValidators.phoneNumber,
                        ]} />                        
                    </div>
                    <div className="col-md-4 field">
                        <Field
                            name="contactEmail"
                            component={formHelpers.renderField}
                            type="email"
                            label="Contact Email"
                            maxLength={255}
                            validate={[formValidators.email, formValidators.maxLength255]}
                        />
                    </div>
                </div>
                {(!(initialValues && initialValues.id) &&
                    <div className="row mt-3">
                        <div className="col-md-4 field">
                            <Field
                                name="engagementAdministratorEmail"
                                component={formHelpers.renderField}
                                type="email"
                                label="Engagement Administrator Email (Required)"
                                maxLength={255}
                                validate={[formValidators.email, validateDomain, formValidators.required, formValidators.maxLength255]}
                            />
                        </div>
                    </div>) || <div />}
            </section>  

            <br />
            <section className="clientCreation-button">
            <div className="row action mb-3">
                <br />
                <div className="col-md-12 container">  
                <div className="cancelSubmit">
                    <MotifButton variant="secondary" type="button" className="mr-2"
                        onClick={handleEditClientCancel} title="Cancel">
                        Cancel
                    </MotifButton> 
                    <MotifButton variant="primary"  type="button"
                        disabled={submitting} title="Submit client data"
                        onClick={handleSubmit}>
                        Submit
                    </MotifButton>
                    </div>
                </div>
            </div>
            </section>
         </div>
         </form>
    );
};

EditClientForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleEditClientCancel: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    clientFormPurpose: PropTypes.string,
    showSuccess: PropTypes.bool,
    auths: PropTypes.array,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    clientUserSearchText: PropTypes.string,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    handleNewButtonClick: PropTypes.func,
    auth: PropTypes.object,
    currentUserId: PropTypes.string,
    currentUserIsSysAdmin: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func,
    handleDismissSuccessMessage: PropTypes.func,
    handleToggleActiveFlag: PropTypes.func,
    handleToggleAuditFlag: PropTypes.func,
    isActive: PropTypes.bool,
    isAudit: PropTypes.bool,
    dirty: PropTypes.bool,
    validateDomain: PropTypes.func,
};

export default reduxForm({ form: "client", enableReinitialize: true })(EditClientForm);

import React, { useState, useEffect, useContext,useRef  } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useParams, useMatches, Outlet } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Breadcrumbs from '../components/shared/Breadcrumbs';
import { MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem } from '@ey-xd/motif-react';
import { IconoirNavArrowDown, IconoirXmark } from '@ey-xd/motif-icon';
import ReactModal from 'react-modal';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import TabbedNavigationContainer from './shared/TabbedNavigationContainer';
import ErrorSummary from '../components/shared/ErrorSummary';
import * as commonActionsImported from "../actions/commonActions";
import * as authActions from "../actions/authActions";
import * as notificationActionsImported from "../actions/notificationActions";
import * as servicesActionsImported from "../actions/servicesActions";
import * as periodActionsImported from "../actions/periodActions";
import * as trialBalanceActionsImported from '../actions/trialBalanceActions';
import Notifications from "./notifications/Notifications";
import UploadQueueWatcher from "./tb/UploadQueueWatcher2";
import { BulkUploadModal } from '../components/tb/BulkUploadModal';
import ReitSuiteUsersMessage from '../components/shared/ReitSuiteUsersMessage';
import { withMsal } from "@azure/msal-react";
import Footer from '../components/Footer';
import shouldHideMainErrorSummary from '../scripts/errorSummaryHelper';
import useWindowDimensions from '../common/useWindowDimensions';
import * as securitiesSnapshotActionsImported from '../actions/securitiesSnapshotActions';
// Helper function to determine if the tab navigation should be shown
const shouldShowTabNav = (location) => {
    // Define regex patterns for paths where the tab navigation should be shown
    // Currently we will show the tab nav on any of the client pages, as long as you are 1 level deep.
    // So we will NOT match on /client/1
    // But we WILL match on /client/1/edit
    const tabNavPatterns = [
        /^\/client\/\d+\/reit\/\d+\/period\/.+/i,
    ];

    // if this hits a match, we will not show the tab navigation
    // New or Edit Client page should not show tab nav
    const excludeTabNavPatterns = [
        /^\/client\/edit\/?$/i,
        /^\/client\/\d+\/edit\/?$/i,
        /^.*\/psqrecord\/\d+\/?$/i,
        /^.*\/arqcrecord\/\d+\/?$/i,
        /^.*\/preview\/\d+\/?$/i,
        /^.*\/helpGuide\/\d+\/?$/i,
    ];

    // Check if the current location's pathname matches any of the tabNavPatterns
    return tabNavPatterns.some(pattern => pattern.test(location.pathname))
        && !excludeTabNavPatterns.some(pattern => pattern.test(location.pathname));
};

const shouldShowBreadcrumbs = (location) => {
    return location.pathname.toLowerCase().indexOf("/psqrecord") < 0 &&
        location.pathname.toLowerCase().indexOf("/arqcrecord") < 0 &&
        location.pathname.toLowerCase().indexOf("/preview") < 0 &&
        location.pathname.toLowerCase().indexOf("/helpGuide") < 0 ;
};

const loadingModalStyle = {
    content: {
        backgroundColor: 'transparent',
        border: 'none',
        color: 'white',
        textAlign: 'center',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        overflow: 'none' // <-- This tells the modal to scroll
    }
};

// Functional component Main
const Main = (props) => { 										
    const [clientDropdownOpen, setClientDropdownOpen] = useState(false);
    const [showEngagementAdminForDataStewardAssignmentModal, setShowEngagementAdminForDataStewardAssignmentModal] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const matches = useMatches();
    const dispatch = useDispatch();

    const handleCloseNotifications = (e) => {
        e.preventDefault();		
        dispatch(notificationActionsImported.toggleNotificationsDisplay());
    };

    const handleDismissErrorMessage = () => {
        dispatch(commonActionsImported.clearErrorResponse());
    };

    const handleDismissUsersMessage = () => {
        dispatch(commonActionsImported.dismissUsersMessage());
    };

    const handleClientManagementDropdownToggle = () => {
        setClientDropdownOpen(!clientDropdownOpen);
    };

    const closeClientManagementDropdown = () => {
        setClientDropdownOpen(false);
    };     
     
    // Use useSelector to access the Redux state
    const {
        client,
        reit,
        period,
        pendingTasks,
        errorResponse,
        dismissUsersMessage,
        currentUserAuthorizations,
        showNotifications,
        uploadProgress,
        currentUserId,
        processingQueueCount,
        showBulkUpload,
        incompleteSecuritiesSnapshots
    } = useSelector((state) => {
        return {
            client: state.client,
            reit: state.reit,
            period: state.period,
            pendingTasks: state.pendingTasks,
            errorResponse: state.errorResponse,
            dismissUsersMessage: state.dismissUsersMessage,
            currentUserAuthorizations: state.currentUserAuthorizations,
            showNotifications: state.showNotifications,
            uploadProgress: state.uploadProgress,
            currentUserId: state.authentication ? state.authentication?.currentUser : '',
            processingQueueCount: state.processingQueueCount,
            showBulkUpload: state.showBulkUploadModal,
            incompleteSecuritiesSnapshots: state.incompleteSecuritiesSnapshots,
        };
    });

    const commonActions = bindActionCreators(commonActionsImported, dispatch);
    const notificationActions = bindActionCreators(notificationActionsImported, dispatch);
    const servicesActions = bindActionCreators(servicesActionsImported, dispatch);
    const periodActions = bindActionCreators(periodActionsImported, dispatch);
    const securitiesSnapshotActions= bindActionCreators(securitiesSnapshotActionsImported, dispatch);
    // Define individual action dispatchers for trial balance actions
    const clearUploadTrialBalanceProgress = () => dispatch(trialBalanceActionsImported.clearUploadTrialBalanceProgress());
    const addFilesToQueue = (files, userId, clientId, notifyStatus) => dispatch(trialBalanceActionsImported.addFilesToQueue(files, userId, clientId, notifyStatus));
    const notifyRejectedFiles = (files, userId) => dispatch(trialBalanceActionsImported.notifyRejectedFiles(files, userId));
    const fetchQueueCount = () => dispatch(trialBalanceActionsImported.fetchQueueCount());
    const showBulkUploadModal = () => dispatch(trialBalanceActionsImported.showBulkUploadModal());
    const hideBulkUploadModal = () => dispatch(trialBalanceActionsImported.hideBulkUploadModal());

    const endsWithOptionalSlash = (str, endsWithStr) => {
        return str.endsWith(endsWithStr) || str.endsWith(`${endsWithStr}/`);
    };
    const closeButtonRef = useRef(null);
   // Use useEffect to focus the close button when the sidebar is shown
   useEffect(() => {
    if (showNotifications && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
   }, [showNotifications]);

    const getDisplayName = (match) => {
        const { params, pathname } = match;

        // Check if the pathname includes the expected pattern with dynamic segments
        if (endsWithOptionalSlash(pathname, `/client/${params.clientId}`)) {
            return client && client.name ? client.name : '...';
        } else if (endsWithOptionalSlash(pathname, `/reit/${params.reitId}`)) {
            return reit && reit.reitName ? reit.reitName : '...';
        } else if (endsWithOptionalSlash(pathname, `/period/${params.periodId}`)) {
            return period && period.reportPeriodDescription ? period.reportPeriodDescription : '...';
        } else {
            // If the route has a name, use it; otherwise, use the last segment of the pathname (which really should never happen)
            return match.handle?.name || pathname.split('/').pop();
        }
    };

    // Function to set the page title based on the route's displayName
    const handleTabPageLocation = () => {
        // Filter out matches that don't have a name (e.g., the root match)
        const filteredMatches = matches.filter((match) => match.handle?.name);

        let tabTitle = '';
        let pages = filteredMatches.filter(x => x.handle?.name).map((match) => {
            return getDisplayName(match);
        }).reverse();

        pages.push('EY REITSuite');
        tabTitle = pages.join(' | ');
        document.title = tabTitle;
    };

    // ... (other handler functions)

    // Replace getBreadcrumbsAction with a function that uses hooks
    const getBreadcrumbsAction = () => {
        if (Array.isArray(currentUserAuthorizations)) {
            let links = [];

            let newClientLink = <React.Fragment />;

            if (authActions.isSystemAdministrator(currentUserAuthorizations) && location.pathname && (location.pathname === "/" || location.pathname === "/clients")) {
                newClientLink = <MotifButton variant="primary" className="right" onClick={() => { navigate(`/client/edit`) }}>New Client</MotifButton>;
            } else if (client && client.id && (authActions.isSystemAdministrator(currentUserAuthorizations) || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)) && location.pathname && location.pathname.indexOf("/client/") >= 0 && location.pathname.indexOf("/edit") == -1) {
                links.push({
                    key: "client",
                    as: Link,
                    to: "/client/" + client.id + "/edit",
                    text: "Client Settings",
                    dataTestId: 'btnClientSettings'
                });
            }
      
            if (client && client.id && (authActions.isSystemAdministrator(currentUserAuthorizations) || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)) && location.pathname && location.pathname.indexOf("/client/") >= 0 && location.pathname.indexOf("/authorizedUsers") === -1) {
                links.push({
                    key: "client",
                    as: Link,
                    to: "/client/" + client.id + "/authorizedUsers",
                    text: "Authorized Users",
                    dataTestId: 'btnAuthorizedUser'
                });
            }

            if (client && client.id && (authActions.isSystemAdministrator(currentUserAuthorizations) 
                || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)
                || authActions.isUser(currentUserAuthorizations, client.id)) 
                && location.pathname && location.pathname.indexOf("/client/") >= 0 && location.pathname.indexOf("/masterDataManagement") === -1) {
                links.push({
                    key: "client",
                    as: Link,
                    to: "/client/" + client.id + "/masterDataManagement",
                    text: "Master Data Management",
                    dataTestId: 'btnMasterDataManagement'
                });
            }
              
            if (client
                && client.id
                && (authActions.isSystemAdministrator(currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)
                    || authActions.isUser(currentUserAuthorizations, client.id))
                && location.pathname &&
                location.pathname.indexOf("/client/") >= 0
                && location.pathname.indexOf("/checklistManagement") == -1
                && location.pathname.indexOf("/manageChecklist") == -1) {
                links.push({
                    key: "checklistManagement",
                    as: Link,
                    to: "/client/" + client.id + "/checklistManagement",
                    text: "Checklist Management",
                    dataTestId: 'btnChecklistManagement'
                });
            }

            if (client
                && client.id
                && !client.isAudit
                && (authActions.isSystemAdministrator(currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)
                    || authActions.isUser(currentUserAuthorizations, client.id))
                && location.pathname &&
                location.pathname.indexOf("/client/") >= 0
                && location.pathname.indexOf("/customPsqTemplate") == -1) {
                links.push({
                    key: "customPSQTemplate",
                    as: Link,
                    to: "/client/" + client.id + "/customPsqTemplate",
                    text: "Custom PSQ Template",
                    dataTestId: 'btnCustomPSQTemplate'
                });
            }

            if (client
                && client.id
                && !client.isAudit
                && (authActions.isSystemAdministrator(currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)
                    || authActions.isUser(currentUserAuthorizations, client.id))
                && location.pathname &&
                location.pathname.indexOf("/client/") >= 0
                && location.pathname.indexOf("/customFdrTemplate") == -1) {
                links.push({
                    key: "customFDRTemplate",
                    as: Link,
                    to: "/client/" + client.id + "/customFdrTemplate",
                    text: "Custom FDR Template",
                    dataTestId: 'btnCustomFDRTemplate'
                });
            }

            if (client
                && client.id
                && !client.isAudit
                && (authActions.isSystemAdministrator(currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id))
                && location.pathname &&
                location.pathname.indexOf("/client/") >= 0
                && location.pathname.indexOf("/chartOfAccountsManager") == -1) {
                links.push({
                    key: "coaManager",
                    as: Link,
                    to: "/client/" + client.id + "/chartOfAccountsManager",
                    text: "Chart of Accounts Manager",
                    className: "btn btn-primary",
                    dataTestId: 'btnChartofAccountsManager'
                });
            }

            if (client
                && client.id
                && (authActions.isSystemAdministrator(currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)
                    || authActions.isUser(currentUserAuthorizations, client.id))
                && location.pathname &&
                location.pathname.indexOf("/client/") >= 0
                && location.pathname.indexOf("/bulkReportManagement") == -1) {
                links.push({
                    key: "bulkReportManagement",
                    as: Link,
                    to: "/client/" + client.id + "/bulkReportManagement",
                    text: "Bulk Report Management",
                    className: "btn btn-primary",
                    dataTestId: 'btnBulkReportManagement'
                });
            }

            if (client
                && client.id
                && (authActions.isSystemAdministrator(currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)
                    || authActions.isUser(currentUserAuthorizations, client.id))
                && location.pathname &&
                location.pathname.indexOf("/client/") >= 0
                && location.pathname.indexOf("/bulkProcessManagement") == -1) {
                links.push({
                    key: "bulkProcessManagement",
                    as: Link,
                    to: "/client/" + client.id + "/bulkProcessManagement",
                    text: "Bulk Process Management",
                    className: "btn btn-primary",
                    dataTestId: 'btnBulkProcessManagement'
                });
            }

            if (client
                && client.id
                && (authActions.isSystemAdministrator(currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)
                    || authActions.isUser(currentUserAuthorizations, client.id))
                && location.pathname &&
                location.pathname.indexOf("/client/") >= 0
                && location.pathname.indexOf("/bulkRollForward") == -1) {
                links.push({
                    key: "bulkRollForward",
                    as: Link,
                    to: "/client/" + client.id + "/bulkRollForward",
                    text: "Bulk Roll Forward",
                    className: "btn btn-primary",
                    dataTestId: 'btnBulkRollForward'
                });
            }
            if (client
                && client.id
                && (authActions.isSystemAdministrator(currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(currentUserAuthorizations, client.id)
                    || authActions.isUser(currentUserAuthorizations, client.id))
                && location.pathname &&
                location.pathname.indexOf("/client/") >= 0
                && location.pathname.indexOf("/dataRetentionManagement") == -1) {
                links.push({
                    key: "dataRetentionManagement",
                    as: Link,
                    to: "/client/" + client.id + "/dataRetentionManagement",
                    text: "Data Retention Management",
                    className: "btn btn-primary",
                    dataTestId: 'btnDataRetentionManagement'
                });
            }
            if (               
                location.pathname 
                && location.pathname.indexOf("/dataStewardRoleManagement") != -1) {
                links = [];
            }
            if (               
                location.pathname 
                && location.pathname.indexOf("/missingDeletionApproval") != -1) {
                links = [];
            }
            // Hide Client Management dropdown if user is on bulk report management page and isLockedForMissingDeletionApprovalOrException flag is true
            if (client && client.isLockedForMissingDeletionApprovalOrException === true              
                && location.pathname 
                && location.pathname.indexOf("/bulkReportManagement") != -1
                ) {
                links = [];
            }
            // Hide Client Management dropdown if user is on data retention management page and isLockedForMissingDeletionApprovalOrException flag is true
            if (client && client.isLockedForMissingDeletionApprovalOrException === true   
                && (!authActions.isSystemAdministrator(currentUserAuthorizations))          
                && location.pathname 
                && location.pathname.indexOf("/dataRetentionManagement") != -1
                ) {
                links = [];
            }
            const dropDownLinks = (links.length > 0 ?
                <div className="row mt-2">
                <div className="col">
                <MotifDropdown
                    open={clientDropdownOpen}
                    placement="bottom-end"
                    id={'client-management'}
                    className="right ml-2"
                    aria-labelledby={'client-management-trigger'}
                    handleClickOutside={closeClientManagementDropdown}
                    trigger={
                        <MotifButton
                            type="button"
                            variant="primary-alt"
                            onClick={handleClientManagementDropdownToggle}
                            aria-controls={'client-management'}
                            id={'client-management-trigger'}
                            aria-expanded={clientDropdownOpen}
                            aria-label="Client Management dropdown"
                            data-testid="btnClientManagement"
                        >
                            Client Management <MotifIcon iconFunction={IconoirNavArrowDown} size='24'  />
                        </MotifButton>
                    }
                >
                    {links.map((link, index) => <Link tabIndex='-1' style={{ color: '#2e2e38' }} to={link.to}><MotifDropdownItem data-testid={link.dataTestId} onClick={closeClientManagementDropdown} key={link.key}>{link.text}</MotifDropdownItem></Link>)}
                </MotifDropdown></div></div> : <React.Fragment />);

            return <React.Fragment>
                {newClientLink}
                {dropDownLinks}
            </React.Fragment>;
        }

        return (<span />);
    };

    // Use the helper functions with the current location
    const showTabNav = shouldShowTabNav(location);
    const showBreadcrumbs = shouldShowBreadcrumbs(location);
    const hideMainErrorSummary = shouldHideMainErrorSummary(location);

    useEffect(() => {
        if (params.periodId) {
            servicesActions.fetchNavigationServicesByPeriod(params.periodId);
        }
    }, []);

    useEffect(() => {
        if (location.pathname !== props.currentLocation) {
            commonActions.clearErrorResponse();
        }
        handleTabPageLocation();
    }, [location]);

    useEffect(() => {
        if (params.periodId) {
            servicesActions.fetchNavigationServicesByPeriod(params.periodId);
            periodActions.fetchPeriodById(params.periodId);
        }
    }, [params.periodId]);


    // Handle client, reit, or period updates to call handleTabPageLocation()
    useEffect(() => {
        handleTabPageLocation();
    }, [client, reit, period]);
    useEffect(() => {       
        if (params.periodId) {          
            securitiesSnapshotActions.getIncompleteSecuritySnapshotDetails(params.clientId, params.reitId, params.periodId);
        }
    }, []);
    const { height } = useWindowDimensions(72);
    return (
        <div style={{ height: '100%', minHeight: height  - 84, paddingBottom: '2px', marginTop: '-1px', paddingTop: '72px' }}>
            <Sidebar.Pushable as={Segment}>
            <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible={showNotifications}
        width="wide"
        direction="right"
      >
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <div><h4>Notifications</h4></div>
          <div style={{ display: 'flex' }}>										  
            <MotifButton
              variant="text"
              className="notification-motif-text-button"
              onClick={handleCloseNotifications}
              title="Close sidebar"
              ref={closeButtonRef} 
            >
              <div style={{ marginLeft: '14px' }}>
                <MotifIcon style={{ color: '#c4c4cd' }} iconFunction={IconoirXmark} size='24'  />
              </div>
            </MotifButton>										
          </div>
        </div>
        <Notifications userId={currentUserId} />
      </Sidebar>

                <Sidebar.Pusher>
                    <Segment basic>
                        <div className="container-fluid" style={{ minHeight: height - 84 }}>
                            <div style={{ minHeight: height - 60}}>
                                {showBreadcrumbs &&
                                    <div className="row header breadcrumbs d-flex justify-content-between" >
                                        <div className="col-md-10" style={{marginTop:'15px'}}>
                                            <Breadcrumbs
                                                client={client}
                                                reit={reit}
                                                period={period}
                                                theme="motif-light"
                                                currentUserAuthorizations={currentUserAuthorizations}
                                            />
                                        </div>
                                        <div className="col-md-2 breadcrumbs-action">{getBreadcrumbsAction()}</div>
                                    </div>
                                }

                                {showTabNav &&
                                    <TabbedNavigationContainer
                                        clientId={client && client.id}
                                        reitId={reit && reit.reitid}
                                        periodId={period && period.reportPeriodID}
                                        currentLocation={location.pathname}
                                        hasIncompleteSecuritySnapshotsList={incompleteSecuritiesSnapshots}
                                    />
                                }
                                <div style={{ minHeight: '24px' }}>
                                    {errorResponse && !hideMainErrorSummary && (
                                        <ErrorSummary errorResponse={errorResponse} handleDismiss={handleDismissErrorMessage} />
                                    )}
                                    {!dismissUsersMessage && (
                                        <ReitSuiteUsersMessage handleDismiss={handleDismissUsersMessage} expireDate={"2022/02/28"} />
                                    )}
                                </div>
                                <Outlet />
                            </div>
                            {location.pathname &&
                                !location.pathname.toLowerCase().includes("/template") &&
                                <Footer />
                            }
                        </div>
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
            <BulkUploadModal
                clearUploadTrialBalanceProgress={clearUploadTrialBalanceProgress}
                addFilesToQueue={addFilesToQueue}
                notifyRejectedFiles={notifyRejectedFiles}
                fetchQueueCount={fetchQueueCount}
                hideBulkUploadModal={hideBulkUploadModal}
                uploadProgress={uploadProgress}
                currentUserId={currentUserId}
                processingQueueCount={processingQueueCount}
                showBulkUpload={showBulkUpload}
                client={client}
            />         
            {(pendingTasks > 0 && (                                
                    <div class="loader-overlay"  >
                    <div class="loader-container" >
                    <img src="/images/loading-spinner-black.gif" height="20px" alt="Loading..." /> 
                        <span>...loading...please wait...</span>
                    </div>
                </div>)) }
            <UploadQueueWatcher />
        </div>
    );
};

Main.propTypes = {
    routes: PropTypes.array,
    params: PropTypes.object,
    client: PropTypes.object,
    reit: PropTypes.object,
    period: PropTypes.object,
    currentLocation: PropTypes.string,
    showTabNav: PropTypes.bool,
    hideMainErrorSummary: PropTypes.bool,
    pendingTasks: PropTypes.number,
    errorResponse: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    containerHeight: PropTypes.number.isRequired,
    commonActions: PropTypes.object.isRequired,
    notificationActions: PropTypes.object.isRequired,
    servicesActions: PropTypes.object.isRequired,
    showNotifications: PropTypes.bool.isRequired
};

export default (withMsal(Main));

























// import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import Breadcrumbs from 'react-breadcrumbs';
// import { MotifBreadcrumb, MotifBreadcrumbItem, MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem } from '@ey-xd/motif-react';
// import { navigationIcClose24px, navigationIcArrowDropDown24px } from '@ey-xd/motif-react/assets/icons';
// 
// import { Menu, Segment, Sidebar } from 'semantic-ui-react';
// import Dimensions from 'react-dimensions';
// import TabbedNavigationContainer from './shared/TabbedNavigationContainer';
// import ErrorSummary from '../components/shared/ErrorSummary';
// import * as commonActions from "../actions/commonActions";
// import * as authActions from "../actions/authActions";
// import * as notificationActions from "../actions/notificationActions";
// import * as servicesActions from "../actions/servicesActions";
// import * as periodActions from "../actions/periodActions";
// import * as trialBalanceActions from '../actions/trialBalanceActions';
// import Notifications from "./notifications/Notifications";
// import UploadQueueWatcher from "./tb/UploadQueueWatcher2";
// import { BulkUploadModal } from '../components/tb/BulkUploadModal';
// import ReitSuiteUsersMessage from '../components/shared/ReitSuiteUsersMessage';
// import { withMsal } from "@azure/msal-react";
// import Footer from '../components/Footer';

// /**
//  * LoginPage container component
//  * @extends {React.Component}
//  */
// export class Main extends React.Component {
//     /**
//      * Creates a new LoginPage
//      * @constructor
//      * @param {Object} props The component properties
//      * @param {Object} context The component context
//      */
//     constructor(props, context) {
//         super(props, context);

//         this.state = {
//             clientDropdownOpen: false
//         }

//         this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
//         this.handleDismissUsersMessage = this.handleDismissUsersMessage.bind(this);
//         this.handleCloseNotifications = this.handleCloseNotifications.bind(this);
//         this.handleClientManagementDropdownToggle = this.handleClientManagementDropdownToggle.bind(this);
//         this.closeClientManagementDropdown = this.closeClientManagementDropdown.bind(this);
//     }

//     componentDidMount() {
//         this.props.servicesActions.fetchNavigationServicesByPeriod(this.props.params.periodId);
//         if (this.props.params.periodId && !this.props.periodId) {
//             this.props.periodActions.fetchPeriodById(this.props.params.periodId);
//         }

//     }

//     componentDidUpdate(prevProps) {
//         if (prevProps.currentLocation !== this.props.currentLocation) {
//             this.props.commonActions.clearErrorResponse();
//         }

//         if (prevProps.params.periodId !== this.props.params.periodId) {

//             this.props.servicesActions.fetchNavigationServicesByPeriod(this.props.params.periodId);
//         }

//         if (
//             prevProps.routes !== this.props.routes ||
//             prevProps.client !== this.props.client ||
//             prevProps.period !== this.props.period ||
//             prevProps.reit !== this.props.reit
//         ) {
//             this.handleTabPageLocation(this.props.routes);
//         }
//     }

//     handleTabPageLocation(routes) {
//         let tabTitle = '';
//         let tabTitlesArray = [];
//         let pathTitlesToReName = ['client'];
//         routes.forEach((route, index) => {
//             if (index == 0 || (index == routes.length - 1 && !route.path)) return;
//             let initialPath = route.path.split('/')[0];
//             let currentTitle = '';
//             if (typeof route.getDisplayName === 'function') {
//                 currentTitle = route.getDisplayName();
//             }
//             if (route.name && !pathTitlesToReName.includes(initialPath)) {
//                 currentTitle = route.name;
//             }
//             tabTitlesArray.unshift(currentTitle);
//         });
//         tabTitlesArray.push('EY REITSuite');
//         tabTitle = tabTitlesArray.join(' | ');
//         window.document.title = tabTitle;
//     }

//     handleCloseNotifications(e) {
//         e.preventDefault();
//         this.props.notificationActions.toggleNotificationsDisplay();
//     }

//     handleDismissErrorMessage() {
//         this.props.commonActions.clearErrorResponse();
//     }

//     handleDismissUsersMessage() {
//         this.props.commonActions.dismissUsersMessage();
//     }

//     handleClientManagementDropdownToggle() {
//         this.setState({ clientDropdownOpen: !this.state.clientDropdownOpen })
//     }

//     closeClientManagementDropdown() {
//         this.setState({ clientDropdownOpen: false })
//     }

//     getBreadcrumbsAction() {
//         if (Array.isArray(this.props.currentUserAuthorizations)) {
//             const links = [];

//             let newClientLink = <React.Fragment />;

//             if (authActions.isSystemAdministrator(this.props.currentUserAuthorizations) && this.props.currentLocation && (this.props.currentLocation === "/" || this.props.currentLocation === "/clients")) {
//                 newClientLink = <MotifButton variant="primary" className="right" onClick={() => { this.context.router.push(`/client/edit`) }}>New Client</MotifButton>;
//             } else if (this.props.client && this.props.client.id && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations) || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)) && this.props.currentLocation && this.props.currentLocation.indexOf("/client/") >= 0 && this.props.currentLocation.indexOf("/edit") == -1) {
//                 links.push({
//                     key: "client",
//                     as: Link,
//                     to: "/client/" + this.props.client.id + "/edit",
//                     text: "Client Settings",
//                     dataTestId: 'btnClientSettings'
//                 });
//             }

//             if (this.props.client
//                 && this.props.client.id
//                 && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
//                     || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
//                     || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
//                 && this.props.currentLocation &&
//                 this.props.currentLocation.indexOf("/client/") >= 0
//                 && this.props.currentLocation.indexOf("/checklistManagement") == -1
//                 && this.props.currentLocation.indexOf("/manageChecklist") == -1) {
//                 links.push({
//                     key: "checklistManagement",
//                     as: Link,
//                     to: "/client/" + this.props.client.id + "/checklistManagement",
//                     text: "Checklist Management",
//                     dataTestId: 'btnChecklistManagement'
//                 });
//             }

//             if (this.props.client
//                 && this.props.client.id
//                 && !this.props.client.isAudit
//                 && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
//                     || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
//                     || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
//                 && this.props.currentLocation &&
//                 this.props.currentLocation.indexOf("/client/") >= 0
//                 && this.props.currentLocation.indexOf("/customPsqTemplate") == -1) {
//                 links.push({
//                     key: "customPSQTemplate",
//                     as: Link,
//                     to: "/client/" + this.props.client.id + "/customPsqTemplate",
//                     text: "Custom PSQ Template",
//                     dataTestId: 'btnCustomPSQTemplate'
//                 });
//             }

//             if (this.props.client
//                 && this.props.client.id
//                 && !this.props.client.isAudit
//                 && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
//                     || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
//                     || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
//                 && this.props.currentLocation &&
//                 this.props.currentLocation.indexOf("/client/") >= 0
//                 && this.props.currentLocation.indexOf("/customFdrTemplate") == -1) {
//                 links.push({
//                     key: "customFDRTemplate",
//                     as: Link,
//                     to: "/client/" + this.props.client.id + "/customFdrTemplate",
//                     text: "Custom FDR Template",
//                     dataTestId: 'btnCustomFDRTemplate'
//                 });
//             }

//             if (this.props.client
//                 && this.props.client.id
//                 && !this.props.client.isAudit
//                 && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
//                     || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id))
//                 && this.props.currentLocation &&
//                 this.props.currentLocation.indexOf("/client/") >= 0
//                 && this.props.currentLocation.indexOf("/chartOfAccountsManager") == -1) {
//                 links.push({
//                     key: "coaManager",
//                     as: Link,
//                     to: "/client/" + this.props.client.id + "/chartOfAccountsManager",
//                     text: "Chart of Accounts Manager",
//                     className: "btn btn-primary",
//                     dataTestId: 'btnCoaManager'
//                 });
//             }

//             if (this.props.client
//                 && this.props.client.id
//                 && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
//                     || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
//                     || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
//                 && this.props.currentLocation &&
//                 this.props.currentLocation.indexOf("/client/") >= 0
//                 && this.props.currentLocation.indexOf("/bulkReportManagement") == -1) {
//                 links.push({
//                     key: "bulkReportManagement",
//                     as: Link,
//                     to: "/client/" + this.props.client.id + "/bulkReportManagement",
//                     text: "Bulk Report Management",
//                     className: "btn btn-primary",
//                     dataTestId: 'btnBulkReportManagement'
//                 });
//             }

//             if (this.props.client
//                 && this.props.client.id
//                 && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
//                     || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
//                     || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
//                 && this.props.currentLocation &&
//                 this.props.currentLocation.indexOf("/client/") >= 0
//                 && this.props.currentLocation.indexOf("/bulkProcessManagement") == -1) {
//                 links.push({
//                     key: "bulkProcessManagement",
//                     as: Link,
//                     to: "/client/" + this.props.client.id + "/bulkProcessManagement",
//                     text: "Bulk Process Management",
//                     className: "btn btn-primary",
//                     dataTestId: 'btnBulkProcessManagement'
//                 });
//             }

//             if (this.props.client
//                 && this.props.client.id
//                 && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
//                     || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
//                     || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
//                 && this.props.currentLocation &&
//                 this.props.currentLocation.indexOf("/client/") >= 0
//                 && this.props.currentLocation.indexOf("/bulkRollForward") == -1) {
//                 links.push({
//                     key: "bulkRollForward",
//                     as: Link,
//                     to: "/client/" + this.props.client.id + "/bulkRollForward",
//                     text: "Bulk Roll Forward",
//                     className: "btn btn-primary",
//                     dataTestId: 'btnBulkRollForward'
//                 });
//             }

//             const dropDownLinks = (links.length > 0 ?
//                 <MotifDropdown
//                     open={this.state.clientDropdownOpen}
//                     placement="bottom-right"
//                     id={'client-management'}
//                     aria-labelledby={'client-management-trigger'}
//                     handleClickOutside={this.closeClientManagementDropdown}
//                     trigger={
//                         <MotifButton
//                             type="button"
//                             variant="primary-alt"
//                             onClick={this.handleClientManagementDropdownToggle}
//                             aria-controls={'client-management'}
//                             id={'client-management-trigger'}
//                             aria-expanded={this.state.clientDropdownOpen}
//                             aria-label="Client Management dropdown"
//                         >
//                             Client Management <MotifIcon src={navigationIcArrowDropDown24px} />
//                         </MotifButton>
//                     }
//                 >
//                     {links.map((link, index) => <Link tabIndex='-1' style={{ color: '#2e2e38' }} to={link.to}><MotifDropdownItem data-testid={link.dataTestId} onClick={this.closeClientManagementDropdown} key={link.key}>{link.text}</MotifDropdownItem></Link>)}
//                 </MotifDropdown> : <React.Fragment />);

//             return <React.Fragment>
//                 {newClientLink}
//                 {dropDownLinks}
//             </React.Fragment>;
//         }

//         return (<span />);
//     }

//     /**
//      * Render a React element
//      * @returns {Object} A reference to the component
//      */
//     render() {
// const loadingModalStyle = {
//     content: {
//         backgroundColor: 'transparent',
//         border: 'none',
//         color: 'white',
//         textAlign: 'center',
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)',
//         width: '60%',
//         overlfow: 'none' // <-- This tells the modal to scrol
//     }
// };

//         MotifBreadcrumb.defaultProps = {
//             triggerButtonProps: {
//                 title: 'Custom Trigger Button title',
//                 'aria-label': 'Custom Trigger Button aria-label'
//             },
//         };


//         return (
//             <div style={{ height: '100%', minHeight: this.height  - 84, paddingBottom: '2px', marginTop: '-1px' }}>
//                 <Sidebar.Pushable as={Segment}>
//                     <Sidebar
//                         as={Menu}
//                         animation="overlay"
//                         icon="labeled"
//                         inverted
//                         vertical
//                         visible={this.props.showNotifications}
//                         width="wide"
//                         direction="right">
//                         <div style={{ display: 'flex', justifyContent: "space-between" }}>
//                             <div><h4>Notifications</h4></div>
//                             <div style={{ display: 'flex' }}>
//                                 <MotifButton variant="text" className="notification-motif-text-button" onClick={this.handleCloseNotifications} title="Close sidebar">
//                                     <div style={{ marginLeft: '14px' }} >
//                                         <MotifIcon style={{ color: '#c4c4cd' }} iconFunction={IconoirXmark} size='24'   />
//                                     </div>
//                                 </MotifButton>
//                             </div>
//                         </div>
//                         <Notifications userId={this.props.currentUserId} />
//                     </Sidebar>

//                     <Sidebar.Pusher>
//                         <Segment basic>
//                             <div className="container-fluid" style={{ minHeight: this.height  - 84 }}>
//                                 <div style={{ minHeight: this.height  - 60 }}>
//                                     {this.props.currentLocation &&
//                                         this.props.currentLocation.toLowerCase().indexOf("/psqrecord") < 0 &&
//                                         this.props.currentLocation.toLowerCase().indexOf("/arqcrecord") < 0 &&
//                                         this.props.currentLocation.toLowerCase().indexOf("/preview") < 0 &&
//                                         <div className="row header breadcrumbs d-flex justify-content-between">
//                                             <div className="col-md-10">
//                                                 <Breadcrumbs
//                                                     routes={this.props.routes}
//                                                     params={this.props.params}
//                                                     client={this.props.client}
//                                                     reit={this.props.reit}
//                                                     period={this.props.period}
//                                                     displayMissing={false}
//                                                     separator=""
//                                                     wrapperElement={MotifBreadcrumb}
//                                                     itemElement={MotifBreadcrumbItem}
//                                                 />
//                                             </div>
//                                             <div className="col-md-2 breadcrumbs-action">{this.getBreadcrumbsAction()}</div>
//                                         </div>
//                                     }
//                                     {(this.props.showTabNav && this.props.currentLocation &&
//                                         this.props.currentLocation.toLowerCase().indexOf("/psqrecord") < 0 &&
//                                         this.props.currentLocation.toLowerCase().indexOf("/arqcrecord") < 0 &&
//                                         <TabbedNavigationContainer clientId={this.props.client && this.props.client.id} reitId={this.props.reit && this.props.reit.reitid} periodId={this.props.period && this.props.period.reportPeriodID} currentLocation={this.props.currentLocation} />) || null}
//                                     <div style={{ minHeight: '24px' }}>
//                                         {(this.props.errorResponse && !this.props.hideMainErrorSummary && <ErrorSummary errorResponse={this.props.errorResponse} handleDismiss={this.handleDismissErrorMessage} />) || null}
//                                         {!this.props.dismissUsersMessage && <ReitSuiteUsersMessage handleDismiss={this.handleDismissUsersMessage} expireDate={"2022/02/28"} />}
//                                     </div>
//                                     {this.props.children}
//                                 </div>
//                                 {this.props.currentLocation &&
//                                     this.props.currentLocation.toLowerCase().indexOf("/template") < 0 &&
//                                     <Footer>{this.props.children}</Footer>
//                                 }
//                             </div>
//                         </Segment>
//                     </Sidebar.Pusher>
//                 </Sidebar.Pushable>
//                 <BulkUploadModal
//                     clearUploadTrialBalanceProgress={this.props.clearUploadTrialBalanceProgress}
//                     addFilesToQueue={this.props.addFilesToQueue}
//                     notifyRejectedFiles={this.props.notifyRejectedFiles}
//                     fetchQueueCount={this.props.fetchQueueCount}
//                     hideBulkUploadModal={this.props.hideBulkUploadModal}
//                     uploadProgress={this.props.uploadProgress}
//                     currentUserId={this.props.currentUserId}
//                     processingQueueCount={this.props.processingQueueCount}
//                     showBulkUpload={this.props.showBulkUpload}
//                     client={this.props.client}
//                 />
//                 <ReactModal isOpen={this.props.pendingTasks > 0} contentLabel="" style={loadingModalStyle} overlayClassName="spinner-overlay">
//                     <img src="/images/loading-spinner-black.gif" height="20px" /> ...loading...please wait...
//                 </ReactModal>
//                 <UploadQueueWatcher />
//             </div>
//         );
//     }
// }

// Main.propTypes = {
//     children: PropTypes.element,
//     routes: PropTypes.array,
//     params: PropTypes.object,
//     client: PropTypes.object,
//     reit: PropTypes.object,
//     period: PropTypes.object,
//     currentLocation: PropTypes.string,
//     showTabNav: PropTypes.bool,
//     hideMainErrorSummary: PropTypes.bool,
//     pendingTasks: PropTypes.number,
//     errorResponse: PropTypes.object,
//     currentUserAuthorizations: PropTypes.array,
//     containerHeight: PropTypes.number.isRequired,
//     commonActions: PropTypes.object.isRequired,
//     notificationActions: PropTypes.object.isRequired,
//     servicesActions: PropTypes.object.isRequired,
//     showNotifications: PropTypes.bool.isRequired
// };

// Main.contextTypes = {
//     router: PropTypes.object
// };

// /**
//  * Maps items from state to properties of the component
//  * @param {Object} state The state
//  * @param {Object} ownProps The properties belonging to this component
//  * @returns {Object} An object containing properties that the component can access
//  */
// function mapStateToProps(state, ownProps) {
//     let result = {
//         client: state.client,
//         reit: state.reit,
//         period: state.period,
//         routes: ownProps.children.props.routes,
//         params: ownProps.children.props.params,
//         currentLocation: ownProps.children.props.location.pathname,
//         showTabNav: shouldShowTabNav(ownProps.children.props),
//         hideMainErrorSummary: shouldHideMainErrorSummary(ownProps.children.props),
//         pendingTasks: state.pendingTasks,
//         errorResponse: state.errorResponse,
//         dismissUsersMessage: state.dismissUsersMessage,
//         currentUserAuthorizations: state.currentUserAuthorizations,
//         showNotifications: state.showNotifications,
//         uploadProgress: state.uploadProgress,
//         currentUserId: ownProps.userId,
//         processingQueueCount: state.processingQueueCount,
//         showBulkUpload: state.showBulkUploadModal,
//         periodActions: PropTypes.object
//     };
//     return result;
// }

// /**
//  * Determines whether or not the route specifies that we should show the tabbed navigation.
//  * @param {any} props The component properties
//  * @returns {bool} True if we should show tabbed navigation, otherwise false
//  */
// function shouldShowTabNav(props) {
//     if (!props) {
//         return false;
//     }

//     if (!props.route || !props.route.showTabNav) {
//         if (props.children && props.children.props) {
//             return shouldShowTabNav(props.children.props);
//         } else {
//             return false;
//         }
//     } else {
//         return props.route.showTabNav;
//     }
// }

// /** Determines whether to hide the main error summary
//  * @param { any } props The component properties
//  * @returns { bool } True if we should hide the main error summary, otherwise false
//  */
// function shouldHideMainErrorSummary(props) {
//     if (!props) {
//         return false;
//     }

//     if (!props.route || !props.route.hideMainErrorSummary) {
//         if (props.children && props.children.props) {
//             return shouldHideMainErrorSummary(props.children.props);
//         } else {
//             return false;
//         }
//     } else {
//         return props.route.hideMainErrorSummary;
//     }
// }


// /**
//  * Binds actions to the dispatcher
//  * @param {Object} dispatch The action dispatcher
//  * @returns {Object} An object containing properties that the component can access
//  */
// function mapDispatchToProps(dispatch) {
//     return {
//         commonActions: bindActionCreators(commonActions, dispatch),
//         notificationActions: bindActionCreators(notificationActions, dispatch),
//         servicesActions: bindActionCreators(servicesActions, dispatch),
//         clearUploadTrialBalanceProgress: () => trialBalanceActions.clearUploadTrialBalanceProgress()(dispatch),
//         addFilesToQueue: (files, userId, clientId, notifyStatus) => trialBalanceActions.addFilesToQueue(files, userId, clientId, notifyStatus)(dispatch),
//         notifyRejectedFiles: (files, userId) => trialBalanceActions.notifyRejectedFiles(files, userId)(dispatch),
//         fetchQueueCount: () => trialBalanceActions.fetchQueueCount()(dispatch),
//         showBulkUploadModal: () => trialBalanceActions.showBulkUploadModal()(dispatch),
//         hideBulkUploadModal: () => trialBalanceActions.hideBulkUploadModal()(dispatch),
//         periodActions: bindActionCreators(periodActions, dispatch),
//     };
// }

// export default Dimensions({
//     getHeight: function (element) {
//         return window.innerHeight - 72;
//     },
//     getWidth: function (element) {
//         let widthOffset = window.innerWidth < 680 ? 0 : 47;
//         return window.innerWidth - widthOffset;
//     }
// })(connect(mapStateToProps, mapDispatchToProps)(withMsal(Main)));

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import * as formHelpers from '../../scripts/formHelpers';
import * as formValidators from '../../scripts/formValidators';
import {
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifIcon,
  MotifIconButton
} from '@ey-xd/motif-react';
import { IconoirXmark } from '@ey-xd/motif-icon';

const PicklistItemSection = ({
  picklistItems,
  addPicklistItem,
  removePicklistItem,
  shouldDisableAddPicklistItem,
}) => {
  return (
    <React.Fragment>
      <hr />
      <div className="mt-2 mb-4">
        <MotifLabel className="mt-2 font-weight-bold">
          Add Picklist Items
        </MotifLabel>
        <div className="row mt-2 align-items-center">
          <div className="field col-md-6">
            <Field
              name="picklistItemText"
              label="Enter a new picklist item"
              type="text"
              component={formHelpers.renderField}
              maxLength={255}
              validate={[
                formValidators.maxLength255,
                formValidators.noDuplicatePicklistItems,
              ]}
              data-testid="txtNewPicklistItem"
            />
          </div>
          <div className="field col-md-6">
            <MotifFormField>
              <MotifButton
                variant="text"
                type="button"
                disabled={shouldDisableAddPicklistItem}
                onClick={() => addPicklistItem()}
                data-testid="addNewPicklistItem"
              >
                + Add New Picklist Item to Picklist
              </MotifButton>
            </MotifFormField>
          </div>
        </div>
        <div data-testid="picklistItems">
          {picklistItems &&
            picklistItems.length > 0 &&
            picklistItems.map((picklistItem, index, picklistItems) => (
              <div className="row" key={index}>
                <div
                  className={
                    picklistItems.length == index + 1
                      ? 'col-md-6 mb-4'
                      : 'col-md-6'
                  }
                >
                  <div
                    className="d-flex align-items-center pt-1 pb-1 pl-2"
                    style={{
                      backgroundColor: '#E1E1E6',
                      borderBottom: '1px solid',
                    }}
                  >
                    <MotifLabel className="mb-0 pb-0">
                      {picklistItem.picklistItemName}
                    </MotifLabel>
                    <MotifIconButton
                      style={{ marginLeft: "auto" }}
                      aria-label="Remove this picklist item"
                      type="button"
                      onClick={(event) =>
                        removePicklistItem(picklistItem.picklistItemName, event)
                      }
                    >
                      <MotifIcon iconFunction={IconoirXmark} size='24'   />
                    </MotifIconButton>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

PicklistItemSection.propTypes = {
  picklistItems: PropTypes.array,
  addPicklistItem: PropTypes.func,
  removePicklistItem: PropTypes.func,
  shouldDisableAddPicklistItem: PropTypes.bool,
};

export default PicklistItemSection;

import React from "react";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Breadcrumbs from "../components/shared/Breadcrumbs";
import ErrorSummary from '../components/shared/ErrorSummary';
import ReactModal from 'react-modal';
import * as commonActions from "../actions/commonActions";
import {useWindowDimensions} from '../common/useWindowDimensions';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import { MotifBreadcrumb, MotifBreadcrumbItem, MotifIcon, MotifButton } from '@ey-xd/motif-react';
import { IconoirXmark } from '@ey-xd/motif-icon';
import Notifications from "./notifications/Notifications";
import * as notificationActions from "../actions/notificationActions";
import ExternalFooter from '../components/ExternalFooter';
import shouldHideMainErrorSummary from '../scripts/errorSummaryHelper';
import { withRouter } from "../common/withRouter";
import {withWindowDimensions} from '../common/withWindowDimensions';
import reit from "../reducers/reitReducer";

/**
 * ExternalMain container component
 * @extends {React.Component}
 */
export class ExternalMain extends React.Component {
    /**
     * Creates a new ExternalMain
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.handleCloseNotifications = this.handleCloseNotifications.bind(this);
       
    }
        
    componentDidUpdate(prevProps) {        
        if (prevProps.currentLocation !== this.props.currentLocation) {
            this.props.commonActions.clearErrorResponse();
        }
        if (
            prevProps.router?.location?.pathname !== this.props.router?.location?.pathname ||
            prevProps.client !== this.props.client ||
            prevProps.period !== this.props.period ||
            prevProps.reit !== this.props.reit
        ) {
            this.handleTabPageLocation();
        }
    }

    endsWithOptionalSlash(str, endsWithStr) {
        return str.endsWith(endsWithStr) || str.endsWith(`${endsWithStr}/`);
    }

    getDisplayName(match) {
        const { params, pathname } = match;
        const { client, reit, period } = this.props;

        // Check if the pathname includes the expected pattern with dynamic segments
        if (this.endsWithOptionalSlash(pathname, `/client/${params.clientId}`)) {
            return client && client.name ? client.name : '...';
        } else if (this.endsWithOptionalSlash(pathname, `/reit/${params.reitId}`)) {
            return reit && reit.reitName ? reit.reitName : '...';
        } else if (this.endsWithOptionalSlash(pathname, `/period/${params.periodId}`)) {
            return period && period.reportPeriodDescription ? period.reportPeriodDescription : '...';
        } else {
            // If the route has a name, use it; otherwise, use the last segment of the pathname (which really should never happen)
            return match.handle?.name || pathname.split('/').pop();
        }
    }

    // Function to set the page title based on the route's displayName
    handleTabPageLocation() {
        const matches = this.props.router.matches;

        // Filter out matches that don't have a name (e.g., the root match)
        const filteredMatches = matches.filter((match) => match.handle?.name);

        let tabTitle = '';
        let pages = filteredMatches.filter(x => x.handle?.name).map((match) => {
            return this.getDisplayName(match);
        }).reverse();

        pages.push('EY REITSuite');
        tabTitle = pages.join(' | ');
        window.document.title = tabTitle;
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearErrorResponse();
    }

    handleCloseNotifications(e) {
        e.preventDefault();
        this.props.notificationActions.toggleNotificationsDisplay();
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const loadingModalStyle = {
            content: {
                backgroundColor: 'transparent',
                border: 'none',
                color: 'white',
                textAlign: 'center',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                overflow: 'none' // <-- This tells the modal to scroll
            }
        };

        const suppressErrorSummary = this.props.errorResponse && this.props.errorResponse.errorCode && (this.props.errorResponse.errorCode === "RESOURCE_NOT_FOUND" || this.props.errorResponse.errorCode === "UNKNOWN" || this.props.errorResponse.errorCode === "LINK_EXPIRED");
        const { height } = this.props;
        return (
            <div style={{ height: '100%', minHeight: height - 84, paddingBottom: '2px', marginTop: '-1px', paddingTop: '72px' }}>
                <Sidebar.Pushable as={Segment}>
                    <Sidebar
                        as={Menu}
                        animation="overlay"
                        icon="labeled"
                        inverted
                        vertical
                        visible={this.props.showNotifications}
                        width="wide"
                        direction="right">
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <div><h4>Notifications</h4></div>
                            <div style={{ display: 'flex' }}>
                                <MotifButton variant="text" className="notification-motif-text-button" onClick={this.handleCloseNotifications} title="Close sidebar">
                                    <div style={{ marginLeft: '14px' }} >
                                        <MotifIcon style={{ color: '#c4c4cd' }} iconFunction={IconoirXmark} size='24'   />
                                    </div>
                                </MotifButton>
                            </div>
                        </div>
                        <Notifications />
                    </Sidebar>

                    <Sidebar.Pusher>
                        <Segment basic>
                            <div className="container-fluid" style={{ minHeight: height - 84 }}>
                                <div style={{ minHeight: height- 60 }}>
                                {this.props.currentLocation &&
                                    this.props.currentLocation.toLowerCase().indexOf("/ext/arqc/arqcrecord") < 0 &&
                                    this.props.currentLocation.toLowerCase().indexOf("/ext/psq/psqrecord") < 0 &&
                                    <div className={this.props.isPermExternalApp ?
                                        "row header breadcrumbs d-flex justify-content-between" 
                                        : "row breadcrumbs d-flex justify-content-between"}>
                                    <div className="col-md-12">
                                        <Breadcrumbs
                                                client={this.props.client}
                                                reit={this.props.reit}
                                                period={this.props.period}
                                                theme="motif-light" />
                                    </div>
                                    </div>
                                }
                                <div style={{ minHeight: '24px' }}>
                                    {(this.props.errorResponse && !this.props.hideMainErrorSummary && !suppressErrorSummary && <ErrorSummary errorResponse={this.props.errorResponse} handleDismiss={this.handleDismissErrorMessage} />) || null}
                                </div>
                                <Outlet />
                                </div>
                                <ExternalFooter></ExternalFooter>
                            </div>                              
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable> 
             
            {(this.props.pendingTasks > 0 && (                       
                <div class="loader-overlay"  >
                <div class="loader-container" >
                <img src="/images/loading-spinner-black.gif" height="20px" alt="Loading..." /> 
                    <span>...loading...please wait...</span>
                </div>
                </div>
                    )) }
            </div>
        );
    }
}

ExternalMain.propTypes = {
    routes: PropTypes.array,
    params: PropTypes.object,
    client: PropTypes.object,
    reit: PropTypes.object,
    period: PropTypes.object,
    currentLocation: PropTypes.string,
    hideMainErrorSummary: PropTypes.bool,
    errorResponse: PropTypes.object,
    pendingTasks: PropTypes.number,
    containerHeight: PropTypes.number.isRequired,
    commonActions: PropTypes.object.isRequired,
    showNotifications: PropTypes.bool.isRequired,
    notificationActions: PropTypes.object.isRequired,
    isPermExternalApp: PropTypes.bool
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    const result = {
        client: state.client,
        reit: state.reit,
        period: state.period,
        params: ownProps.router.location.params,
        currentLocation: ownProps.router.location.pathname,
        hideMainErrorSummary: shouldHideMainErrorSummary(ownProps.router.location),
        errorResponse: state.errorResponse,
        pendingTasks: state.pendingTasks,
        showNotifications: state.showNotifications,
        isPermExternalApp: ownProps.isPermExternalApp
    };
    return result;
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        notificationActions: bindActionCreators(notificationActions, dispatch)
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withWindowDimensions(ExternalMain,72)));

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFormValues, change } from "redux-form";
import EditClientForm from './EditClientForm';

/**
 * The EditClientComponent class
 * @extends {React.Component}
 */
export class EditClientComponent extends React.Component {
    /**
     * Creates a new EditClientComponent
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            initialValues: null,
            gridCurrentState: []
        };       
    }

    componentDidMount() {
        this.resetGridColumnState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.client !== this.props.client) {
            if (this.props.client) {
                let initialValues = Object.assign({}, this.props.client);
                initialValues.isActive = initialValues.isActive.toString();

                this.setState({ initialValues: initialValues });
            }
            else {
                const initialValues = {
                    id: 0,
                    name: "",
                    gfisCode: "",
                    engagementNumber: "",
                    isActive: true.toString()
                };

                this.setState({ initialValues: initialValues });
            }
        }
    }
   
    resetGridColumnState() {
        localStorage.removeItem('REITSuiteClientListColumnState');
    }

    render() {       
        return (
            <div className="edit-client-component">
                <EditClientForm  onSubmit={this.props.handleClientSubmit}
                    initialValues={this.state.initialValues} showSuccess={this.props.showSuccess}
                    clientFormPurpose={this.props.clientFormPurpose}                 
                    auths={this.props.auths} handleSearchChange={this.props.handleSearchChange}
                    handleClearSearchText={this.props.handleClearSearchText} clientUserSearchText={this.props.clientUserSearchText}
                    handleEditButtonClick={this.props.handleEditButtonClick} handleDeleteButtonClick={this.props.handleDeleteButtonClick}
                    handleNewButtonClick={this.props.handleNewButtonClick} 
                    auth={this.props.auth}
                    currentUserId={this.props.currentUserId}
                    currentUserIsSysAdmin={this.props.currentUserIsSysAdmin}
                    handleDismissErrorMessage={this.props.handleDismissErrorMessage}
                    handleDismissSuccessMessage={this.props.handleDismissSuccessMessage} 
                    handleToggleActiveFlag={this.props.handleToggleActiveFlag} isActive={this.props.isActive}
                    handleToggleAuditFlag={this.props.handleToggleAuditFlag} isAudit={this.props.isAudit}
                    handleEditClientCancel={this.props.handleEditClientCancel}
                    validateDomain={this.props.validateDomain} 
                    validatingUser={this.props.validatingUser} 
                    buttonVarient="secondary"
                    />                
            </div>
        );
    }
}

EditClientComponent.propTypes = {
    client: PropTypes.object,
    lookupTypes: PropTypes.object,  
    handleClientSubmit: PropTypes.func,
    showSuccess: PropTypes.bool,
    clientFormPurpose: PropTypes.string,
    auths: PropTypes.array,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    clientUserSearchText: PropTypes.string,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    handleNewButtonClick: PropTypes.func,
    auth: PropTypes.object,
    currentUserId: PropTypes.string,
    currentUserIsSysAdmin: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func,
    handleDismissSuccessMessage: PropTypes.func,
    handleToggleActiveFlag: PropTypes.func,
    isActive: PropTypes.bool,
    handleToggleAuditFlag: PropTypes.func,
    isAudit: PropTypes.bool,
    handleEditClientCancel: PropTypes.func,
    validateDomain: PropTypes.func,
    validatingUser: PropTypes.bool
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
    return {        
        lookupTypes: state.lookupTypes
    };
}

/**
* Binds actions to the dispatcher
* @param {Object} dispatch The action dispatcher
* @returns {Object} An object containing properties that the component can access
*/
function mapDispatchToProps(dispatch) {
    return {
        change: bindActionCreators(change, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditClientComponent);
import React from 'react';
import { MotifTooltip } from '@ey-xd/motif-react';
import { MotifIcon, MotifIconButton } from '@ey-xd/motif-react';
import { IconoirTrash } from '@ey-xd/motif-icon';

export default (props) => {
  let fieldValue = "";
  const commentId = props.data ? props.data.checklistSectionQuestionCommentID : -1;
  if (props.node && props.node.data) {
    switch (props.fieldName) {
      case 'sectionName': {
        fieldValue = props.node.data['sectionSortOrder'] + ' - ' + props.node.data['snapshotSectionName'];
        break;
      }
      case 'questionTitle': {
        fieldValue = props.node.data['questionText'];
        break;
      }
      case 'comments': {
        fieldValue = props.node.data['commentText'];
        break;
      }
    }
  }

  if (props.fieldName == 'deleteComment' && props.canEdit) {
    return (

      <div>
        <div className="justify-content-center align-items-center mt-2">
          <MotifIconButton aria-label="Delete" type="button"
            data-testid="deleteIcon">
            <MotifIcon iconFunction={IconoirTrash} size='24'  title="Delete" onClick={() => props.handleRowDelete(commentId)} />
          </MotifIconButton>
        </div>
      </div>

    );
  } else {
    if (fieldValue && fieldValue.length > 30) {
      return (
        <div>
          <span>{fieldValue.substring(0, 29)}</span>
          <span className="ml-1" style={{ cursor: 'pointer' }}>
            <MotifTooltip
              id="show-comments-tooltip"
              allowClick
              allowHover
              placement="bottom"
              trigger={<span style={{ whiteSpace: 'nowrap' }}>...</span>}
              variant="alt"
              style={{ maxWidth: '20px' }}
              hideCloseButton={true}
            >
              {fieldValue}
            </MotifTooltip>
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span>{fieldValue}</span>
        </div>
      );
    }
  }
}
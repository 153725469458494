import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { MotifLabel, MotifFormField, MotifToggleSwitch } from '@ey-xd/motif-react';

const PSQCompletionRequirement = ({  
    isPSQChecklistCompletionRequirementEnabled,
    handlePSQCompletionRequirementChange
}) => {
    return <div>
        <div style={{ minHeight: '7px'}}></div>
            <MotifFormField className="psqCompletionStyle right">           
                <MotifToggleSwitch
                    name="switchPSQCompletionRequirement"
                    id="switchPSQCompletionRequirement"
                    data-testid="switchPSQCompletionRequirement"
                    onChange={handlePSQCompletionRequirementChange}
                    checked={isPSQChecklistCompletionRequirementEnabled}
            />
            </MotifFormField>
            <MotifLabel className="psqCompletionStyle right" 
                htmlFor="switchPSQCompletionRequirement" >
                PSQ Completion Requirement:
            </MotifLabel>
    </div>;
};

PSQCompletionRequirement.propTypes = {
    handlePSQCompletionRequirementChange: PropTypes.func
};

export default reduxForm({ form: "psqCompletionRequirement" })(PSQCompletionRequirement);
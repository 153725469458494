import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReportPeriodRowContainer from "../../containers/reits/ReportPeriodRowContainer";
import { AgGridReact } from 'ag-grid-react';
import { handleFilterChanged, handleFirstDataRendered, handleGridReady, tableHeaderClass, handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import CustomPagination from '../../common/MotifPagination';
import CustomHeaderTemplate from "../shared/customHeaders/CustomHeaderTemplate.js";
import ReportPeriodCellRenderer from "./customRenderer/ReportPeriodCellRenderer.js";
import { MotifTable } from "@ey-xd/motif-react";
import ReportPeriodComparator from "../shared/customComparators/ReportPeriodComparator.js";
import AGGridLoader from "../../common/AGGridLoader"

const ReportPeriodList = ({ clientId, reitId, reit, reportPeriods, activePeriodId, handleActivePeriodIdChanged, toggleShowClosePeriodModal, toggleShowOpenPeriodModal, tbMode, toggleShowModeModal, chartExistsForClient, 
    clientIsAudit, currentUserAuthorizations, handleDownloadCurrentTestSnapshot, handleDownloadTbTemplates, handleDownloadMappingTemplatePackage, trialBalances, handleTrialBalancesByPeriod,
    hideTbModeLinkList, trialBalancesList,  hideTestSnapshotLinkList, hasIncompleteChecklistsList,hasIncompleteSecuritySnapshotsList,handleFinalReportClick,periodOperationsCompleted }) => {
    reportPeriods = reportPeriods || new Array();
        
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: false,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    let getColumnDefs = 
        [
            {
                field: 'reportPeriodDescription', headerName: 'Report Period', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 190,
                cellRenderer: ReportPeriodCellRenderer,
                cellRendererParams: { clientIsAudit, clientId, reitId, hasIncompleteSecuritySnapshotsList, },
                headerComponentParams: {
                    template: CustomHeaderTemplate("reportPeriodDescription"),
                },
                comparator: ReportPeriodComparator,
            },
            {
                field: 'uploadDownload', headerName: 'Upload/Download', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 275,
                cellRenderer: ReportPeriodCellRenderer,
                cellRendererParams: {clientIsAudit, reit, clientId, reitId, chartExistsForClient, handleDownloadCurrentTestSnapshot, handleDownloadTbTemplates, handleDownloadMappingTemplatePackage,
                    trialBalancesList, hideTestSnapshotLinkList,hasIncompleteSecuritySnapshotsList,handleFinalReportClick
                },
                suppressMenu: true, // Add this line to hide the menu icons
                suppressHeaderFilterButton: true,
            },
            {
                field: 'manage', headerName: 'Manage', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 221,
                cellRenderer: ReportPeriodCellRenderer,
                cellRendererParams: {clientIsAudit, clientId, reitId, toggleShowModeModal, hideTbModeLinkList},
                suppressMenu: true, // Add this line to hide the menu icons
                suppressHeaderFilterButton: true,
            },
            {
                field: 'services', headerName: 'Services', sortable: true, showRowGroup: false, hide: false, rowGroup: false,minWidth: 190,
                cellRenderer: ReportPeriodCellRenderer,
                cellRendererParams: {clientIsAudit, handleActivePeriodIdChanged },
                suppressMenu: true, // Add this line to hide the menu icons
                suppressHeaderFilterButton: true,
            },
            {
                field: 'reportPeriodStatusDescription', headerName: 'Period Status', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 214,
                cellRenderer: ReportPeriodCellRenderer,
                cellRendererParams: {hasIncompleteChecklistsList,hasIncompleteSecuritySnapshotsList},
                headerComponentParams: {
                    template: CustomHeaderTemplate("reportPeriodStatusDescription"),
                  },
            },
            {
                field: 'signOff', headerName: 'Sign Off', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 174, 
                cellRenderer: ReportPeriodCellRenderer,
                cellRendererParams: {clientId, clientIsAudit, toggleShowClosePeriodModal, currentUserAuthorizations, toggleShowOpenPeriodModal, hasIncompleteChecklistsList,hasIncompleteSecuritySnapshotsList },
                suppressMenu: true, // Add this line to hide the menu icons
                suppressHeaderFilterButton: true,
            },
        ];

    //Handle deselect all event
    const handleDeselectAll = (val) => {
        // set current page and total pages to 0
        setCurrentPage(0);    
        setTotalPages(0);      
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val-1);
    };

    const handleGridChanges = (gridRefApi) => {
        if (gridRefApi) {
            gridRefApi.refreshCells();
        }
    }

    const [columnDefs, setColumnDefs] = useState(getColumnDefs);

    useEffect(() => {
        handleGridChanges(gridRef && gridRef.current ? gridRef.current.api : null);
        if(rowData.length > 0 && gridRef?.current?.api !== undefined){
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    }, [rowData]);    

    useEffect(() => {
        setRowData(reportPeriods);        

        if (gridRef && gridRef.current && gridRef.current.api) {
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            setColumnDefs(getColumnDefs);
            gridRef.current.api.redrawRows();
        }
    }, [reportPeriods]);

    return (
        <div className="mt-3">
                {(reportPeriods.length  > 0) &&
                    <div>
                        <div className="row">
                            <div className="col">                                
                                {!periodOperationsCompleted ? <AGGridLoader />
                                :
                                <div className="ag-theme-quartz motif-table table-bd-full-height-width" >
                                    <MotifTable zebra={true} compact={true}
                                        suppressAnimationFrame={true}
                                        suppressChangeDetection={true}
                                        suppressColumnMoveAnimation={true}
                                        suppressLoadingOverlay={true}
                                        suppressNoRowsOverlay={true}
                                        rowData={rowData}
                                        columnDefs={getColumnDefs}
                                        defaultColDef={defaultColDef}
                                        accentedSort={true}
                                        suppressRowClickSelection={true}
                                        onGridReady={event => handleGridReady(event, gridRef,setCurrentPage,setItemsPerPage,setTotalPages,'REITSuiteReportPeriodsListColumnState', handleDeselectAll)}
                                        onFirstDataRendered={handleFirstDataRendered(gridRef)}
                                        ref={gridRef}
                                        pagination={true}
                                        suppressPaginationPanel={true}
                                        paginationPageSize={100}
                                        groupDisplayType={'custom'}
                                        groupSelectsChildren={true}
                                        groupDefaultExpanded={0}
                                        onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                                        data-testid="divReportPeriodsListGrid"
                                        deltaRowDataMode={true}
                                        immutableData={true}
                                        getRowNodeId={(data) => data.reportPeriodID}
                                    />
                                
                                    <div className="mt-3">
                                        <span className="right mb-3">
                                            <CustomPagination
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                                totalPages={totalPages}
                                                handleItemsPerPageChange={handleItemsPerPageChange}
                                                gridRef={gridRef}
                                                setCurrentPage={setCurrentPage}
                                                setItemsPerPage={setItemsPerPage}
                                                setTotalPages={setTotalPages} />
                                        </span>
                                    </div>                                   
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {(reportPeriods && reportPeriods.length === 0  && <div><h5>No Report Periods found for the REIT.</h5></div>) || null}

            </div>
    );
};

ReportPeriodList.propTypes = {
    clientId: PropTypes.number,
    reitId: PropTypes.number,
    reit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        periods: PropTypes.array
    }),
    reportPeriods: PropTypes.array,
    activePeriodId: PropTypes.number,
    handleActivePeriodIdChanged: PropTypes.func.isRequired,
    toggleShowClosePeriodModal: PropTypes.func.isRequired,
    toggleShowOpenPeriodModal: PropTypes.func.isRequired,
    tbMode: PropTypes.number,
    toggleShowModeModal: PropTypes.func.isRequired
};

export default ReportPeriodList;

import React, { useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { handleFilterChanged, handleFirstDataRendered, handleGridReady, tableHeaderClass, handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import CustomPagination from '../../common/MotifPagination';
import FileManagementCellRenderer from './customRenderer/FileManagementCellRenderer';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomHeaderTemplate from "../shared/customHeaders/CustomHeaderTemplate.js";
import { MotifTable } from '@ey-xd/motif-react';

const FileList = ({ files, onDownload, onDelete, canUpload, emptyMessage, handleGridChanges }) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    const dateOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    };

    const getColumnDefs = () => {
        return [
            {
                field: 'fileName', headerName: 'File Name', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 900,
                headerComponentParams: {template: CustomHeaderTemplate("fileName"),},
            
            },
            {
                field: 'lastModified', headerName: 'Date Uploaded', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 100,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data["lastModified"] ? (new Date(params.node.data["lastModified"] + 'Z')).toLocaleString('en-US', dateOptions) : ''
                        : null;
                },
                headerComponentParams: {template: CustomHeaderTemplate("lastModified"),},

            },
            {
                headerName: '', suppressMenu: true,  suppressHeaderFilterButton: true, showRowGroup: false, rowGroup: false,
                minWidth: 125, 
                maxWidth: 125,
                cellRenderer: FileManagementCellRenderer, cellRendererParams: { canUpload, onDownload, onDelete },
            }
        ];
    };

    //Handle deselect all event
    const handleDeselectAll = (val) => {
        // set current page and total pages to 0
        setCurrentPage(0);    
        setTotalPages(0);      
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    useEffect(() => {
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);
        if (rowData?.length > 0 && gridRef.current.api !== undefined) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    }, [rowData]);

    useEffect(() => {
        setRowData(files);
        if (gridRef && gridRef.current && gridRef.current.api) {

            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }
    }, [files]);

    const rowHeight = 50; 
    const headerHeight = 50; 
    const totalHeight = (rowData?.length <= 5 ? rowData?.length : 5) * rowHeight + headerHeight; // set 5 rows as default

    return (
        <div>
            {(rowData?.length > 0) &&
                <div>
                    <div>
                        <div className="row">
                            <div className="col">
                                <div className="ag-theme-quartz motif-table" style={{ width: "100%", height: totalHeight }}>
                                    <MotifTable zebra={true} compact={true}
                                        rowData={rowData}
                                        columnDefs={getColumnDefs()}
                                        defaultColDef={defaultColDef}
                                        accentedSort={true}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                        onGridReady={event => handleGridReady(event, gridRef, setCurrentPage, setItemsPerPage, setTotalPages, 'REITSuiteClientListColumnState', handleDeselectAll)}
                                        onFirstDataRendered={handleFirstDataRendered(gridRef)}
                                        ref={gridRef}
                                        pagination={true}
                                        suppressPaginationPanel={true}
                                        paginationPageSize={100}
                                        groupDisplayType={'custom'}
                                        groupSelectsChildren={true}
                                        groupDefaultExpanded={0}
                                        onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                                        data-testid="divFileListGrid"
                                        deltaRowDataMode={true}
                                    />
                                    <div className="mt-3">
                                        <span className="right mb-3">
                                            <CustomPagination
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                                totalPages={totalPages}
                                                handleItemsPerPageChange={handleItemsPerPageChange}
                                                gridRef={gridRef}
                                                setCurrentPage={setCurrentPage}
                                                setItemsPerPage={setItemsPerPage}
                                                setTotalPages={setTotalPages} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(files && files.length === 0 && <div>{emptyMessage}</div>) || null}
        </div>
    );
};

FileList.propTypes = {
    files: PropTypes.array,
    onDownload: PropTypes.func,
    onDelete: PropTypes.func,
    canUpload: PropTypes.bool,
    emptyMessage: PropTypes.string
};

export default FileList;
import React from "react";
import PropTypes from "prop-types";
import { IconoirWarningCircle } from '@ey-xd/motif-icon';

import { MotifButton,MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter, 
    MotifLabel,
    MotifIcon} from "@ey-xd/motif-react";

const DeleteFdrTemplateModal = ({
    showDeleteTemplateModal,
    handleYes,
    toggleShowDeleteTemplateModal
}) => {

    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          {children}
        </div>
      );
    return (
        <MotifModal aria-label="Delete Custom FDR Template" show={showDeleteTemplateModal} onClose={toggleShowDeleteTemplateModal}>
            <MotifModalHeader>
                <HeaderWrapper>
                    <MotifIcon className="mt-1" style={{ color: "#F95D54" }} iconFunction={IconoirWarningCircle} size='24'   />
                    <span className="ml-2">Delete Custom FDR Template</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <span>Are you sure you want to delete this template?</span>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={toggleShowDeleteTemplateModal}>
                    No
                </MotifButton>
                <MotifButton variant="primary" className="right" type="button"
                    onClick={handleYes}>
                    Yes
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

DeleteFdrTemplateModal.propTypes = {
    showDeleteTemplateModal: PropTypes.bool,
    handleYes: PropTypes.func,
    toggleShowDeleteTemplateModal: PropTypes.func
};

export default DeleteFdrTemplateModal;
import PropTypes from "prop-types";
import React from "react";

import { Button, Header } from "semantic-ui-react";
import {
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifButton,
    MotifIcon
  } from '@ey-xd/motif-react';
  import { IconoirWarningCircle } from '@ey-xd/motif-icon';

const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: `Close Delete Property modal`,
    ariaLabel: `Close Delete Property modal`,
    dataTestid: 'close',
  };

const DeletePropertyModal = ({ property, showDeletePropertyModal, handleYes, handleNo, deletePropertyDetail }) => {
    return (
         <React.Fragment>
        <MotifModal
        variant="alt"
        show={showDeletePropertyModal}
        onClose={handleNo}
        data-testid="deletePropertyModal"
      >
        <MotifModalHeader
          data-testid="h3DeleteProperty"
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
            <MotifIcon style={{ color: "#F95D54" }} iconFunction={IconoirWarningCircle} size='24'   />
            <span className="ml-2" >Delete Property</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
           {!deletePropertyDetail.period && (
              <span className="checklistActionMsg mb-2" data-testid="spanDeleteConfirmation">  Please confirm you want to delete the property from all periods.</span>
            ) || (
           
                <span className="checklistActionMsg mb-2" data-testid="spanDeleteInformation">  The Property cannot be deleted because it may have a PSQ or a Trial Balance uploaded
                    or it may be the last property within the REIT period. The earliest period this condition exists is in {deletePropertyDetail.period}.
                    </span>
                )}
                
        </MotifModalBody>
        <MotifModalFooter>
           {!deletePropertyDetail.period && (
              <React.Fragment>
                <MotifButton variant="secondary"
                data-testid="btnCancel"
                type="button"
                onClick={handleNo}>Cancel</MotifButton>
                <MotifButton variant="warn"
                data-testid="btnDelete"
                type="button" onClick={handleYes}
                >Delete</MotifButton>
              </React.Fragment>
              ) || (                    
                <MotifButton variant="secondary" type="button"  data-testid="btnOk"
                    onClick={handleNo}>
                    Ok
                </MotifButton>
              )
            }
        </MotifModalFooter>
      </MotifModal>
      </React.Fragment>
    );
};

DeletePropertyModal.propTypes = {
    property: PropTypes.object.isRequired,
    showDeletePropertyModal: PropTypes.bool,
    handleYes: PropTypes.func.isRequired,
    handleNo: PropTypes.func.isRequired
};

export default DeletePropertyModal;
import React from "react";
import PropTypes from "prop-types";
import { MotifButton, MotifIcon, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';
import { IconoirWarningCircle } from '@ey-xd/motif-icon';

const DeleteCustomPSQTemplateModal = ({
    showDeleteCustomPSQTemplateModal,
    handleDeleteCustomPSQTemplate,
    toggleShowDeleteCustomPSQTemplateModal,
    checklistTemplate
    
}) => {
    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          {children}
        </div>
          );

    const closeButtonProps = {
        title: 'Close delete custom PSQ template modal',
        ariaLabel: 'Close delete custom PSQ template modal',
        dataTestid: 'close'
    };

    return (
      <MotifModal
        variant="alt"
        show={showDeleteCustomPSQTemplateModal}
        onClose={toggleShowDeleteCustomPSQTemplateModal}
        data-testid="DeleteCustomPSQTemplate"
      >
        <MotifModalHeader
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
            <MotifIcon style={{ color: '#F95D54' }} iconFunction={IconoirWarningCircle} size='24'   />
            <span className="ml-2">Delete Custom PSQ Template</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <div>
            <span className="customPSQTemplateActionMsg" style={{ display: 'block' }}>
              Are you sure you want to delete this template?
            </span>
            <span className="customPSQTemplateActionMsg" style={{ display: 'block' }}>
              Name -{' '}
              {checklistTemplate ? checklistTemplate.checklistTemplateName : ''}
            </span>
            <p className="mt-3" style={{ fontWeight: 'bold' }}>
              NOTE: Deleting this template will update all properties currently
              assigned to use this template to their default standard
              questionnaire. This update will impact all current and future
              auto-delivery calendars.
            </p>
          </div>
        </MotifModalBody>
        <MotifModalFooter>
          <MotifButton
            variant="secondary"
            className="right"
            data-testid="Cancel"
            type="button"
            onClick={toggleShowDeleteCustomPSQTemplateModal}
          >
            Cancel
          </MotifButton>
          <MotifButton
            variant="warn"
            className="right"
            data-testid="Delete"
            type="button"
            onClick={() =>
              handleDeleteCustomPSQTemplate(
                checklistTemplate ? checklistTemplate.checklistTemplateId : -1
              )
            }
          >
            Delete
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
    );
};

DeleteCustomPSQTemplateModal.propTypes = {
    showDeleteCustomPSQTemplateModal: PropTypes.bool,
    handleDeleteCustomPSQTemplate: PropTypes.func,
    toggleShowDeleteCustomPSQTemplateModal: PropTypes.func
};

export default DeleteCustomPSQTemplateModal;
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as authActions from '../actions/authActions';
import * as notificationActions from '../actions/notificationActions';
import * as trialBalanceActions from '../actions/trialBalanceActions';
import * as microsoftGraphApiActions from '../actions/microsoftGraphApiActions';
import animate from 'css-animation';
import {
    MotifHeader,
    MotifIconButton,
    MotifIcon,
    MotifHeaderLogo,
    MotifBadge, 
    MotifDropdown,
    MotifButton,
    MotifDropdownItem,
} from "@ey-xd/motif-react";
import { IconoirUpload, IconoirArchive, IconoirUserStar, IconoirBellNotification,IconoirHelpCircle } from '@ey-xd/motif-icon';
import { withMsal } from "@azure/msal-react";
import { withRouter } from '../common/withRouter';

import UserAvatarComponent from './shared/navbar/userAvatarComponent';

import * as themeActions from '../actions/themeActions';
/**
 * NavBar container component
 * @extends {React.Component}
 */
export class NavBar extends React.Component {
    /**
     * Creates a new NavBar
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        this.state = { userId: "", activeMotifIconId: -1, isUserDropdownOpen: false, isDarkTheme: false};
    }

    componentDidMount() {
        // Microsoft Graph API call to fetch the profile picture of the logged in user
        this.props.microsoftGraphApiActions.fetchUserProfilePictureV1();
    }

    render() {
        let showReitFilesNavLink = this.props.router.location.pathname && this.props.router.location.pathname.toLowerCase().indexOf("/reit/") > 0;
        let showPeriodFilesNavLink = this.props.router.location.pathname && this.props.router.location.pathname.toLowerCase().indexOf("/period/") > 0;
        let showtbUploadNavLink = this.props.router.location.pathname && this.props.router.location.pathname.toLowerCase().indexOf("client/") > 0 &&  this.props.router.location.pathname.toLowerCase().indexOf("/datastewardrolemanagement") < 0 ;

        let pfmActive = this.props.router.location.pathname && this.props.router.location.pathname.toLowerCase().indexOf("/period/") > 0 && this.props.router.location.pathname.toLowerCase().indexOf("/files") > 0;
        let manageSystemAdminActive = this.props.router.location.pathname && this.props.router.location.pathname.toLowerCase().indexOf("adminauth") > 0;
        let logoffActive = this.props.router.location.pathname && this.props.router.location.pathname.toLowerCase().indexOf("/logoff/") > 0;
        let showHeaderIcons = this.props.router.location.pathname && this.props.router.location.pathname.toLowerCase().indexOf("/psqrecord") < 0 &&
            this.props.router.location.pathname.toLowerCase().indexOf("/arqcrecord") < 0 &&
            this.props.router.location.pathname.toLowerCase().indexOf("/preview") < 0 &&
            this.props.router.location.pathname.toLowerCase().indexOf("/helpGuide") < 0 ;
           

        //If current url is of Period File Management then set the state activeMotifIconId to 2
        if (pfmActive) {
            if (this.state.activeMotifIconId !== 2) {
                this.state.activeMotifIconId = 2;
            }
        }
        //If current url is of Manage System Administrator then set the state activeMotifIconId to 4
        else if (manageSystemAdminActive) {
            if (this.state.activeMotifIconId !== 4) {
                this.state.activeMotifIconId = 4;
            }
        }
        //If current url is of Logoff then set the state activeMotifIconId to 5
        else if (logoffActive) {
            if (this.state.activeMotifIconId !== 5) {
                this.state.activeMotifIconId = 5;
            }
        }
        //else if (this.state.activeMotifIconId == 1 || this.state.activeMotifIconId == 3) {
        //    //Don't update activeMotifIconId to 5
        //}
        else {
            this.state.activeMotifIconId = -1;
        }

        let clientId = -1;
        if (this.props.client && this.props.client.id) {
            clientId = this.props.client.id;
        }

        let isSysAdmin = false;
        let isEngAdmin = false;
        let isEngUser = false;
        if (Array.isArray(this.props.currentUserAuthorizations)) {
            isSysAdmin = authActions.isSystemAdministrator(this.props.currentUserAuthorizations);
            isEngAdmin = authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, clientId);
            isEngUser = authActions.isUser(this.props.currentUserAuthorizations, clientId);
        }

        let user = '';
        const userId = this.props.authentication.currentUser;

        let name = this.props.msalContext.accounts[0].idTokenClaims.name;
        if (name === undefined || name === null || name === "") {
            name = "";
            let firstName = this.props.msalContext.accounts[0].idTokenClaims.given_name;
            if (firstName === undefined || firstName === null) {
                firstName = "";
            }
            let lastName = this.props.msalContext.accounts[0].idTokenClaims.family_name;
            if (lastName === undefined || lastName === null) {
                lastName = "";
            }
            if (firstName !== "") {
                name += firstName;
            }
            if (lastName != "") {
                if (name !== "") {
                    name += " ";
                }
                name += lastName;
            }
        }

        if (name !== "") {
            user += name;
        }
        else {
            user += userId;
        }

        let userRole = "";
        if (isSysAdmin) {
            userRole = "System Administrator";
        } else if (isEngAdmin) {
            userRole = "Engagement Administrator";
        } else if (isEngUser) {
            userRole = "Engagement User";
        }

        let self = this;

        let remaining = "Remaining Time: " + this.props.authentication.remainingTime;
        let hasRemaining = (this.props.authentication.remainingTime !== undefined && this.props.authentication.remainingTime !== null && this.props.authentication.remainingTime !== "");

        /**
         * Handles a navigation click
         * @param {Object} info The information
         */
        function handleClick(info) {
            if (info.key === "SysAdmin") {
                navToSysAdmin();
            }
            if (info.key === "Files") {
                navToFiles();
            }
            if (info.key === "Notifications") {
                self.props.notificationActions.toggleNotificationsDisplay();
            }
            switch (info.key) {
                case "SysAdmin":
                    navToSysAdmin();
                    break;
                case "Files":
                    navToFiles();
                    break;
                case "ReitFiles":
                    navToReitFiles();
                    break;
                case "ContactAll":
                    navToContactAll();
                    break;
                case "MultipleUpload":
                    navToMultipleUpload();
                    break;
                case "Logoff":
                    navToLogoff();
                    break;
            }
        }

        const animation = {
            enter(node, done) {
                let height;
                return animate(node, 'rc-menu-collapse', {
                    start() {
                        height = node.offsetHeight;
                        node.style.height = 0;
                    },
                    active() {
                        node.style.height = `${height}px`;
                    },
                    end() {
                        node.style.height = '';
                        done();
                    },
                });
            },

            appear() {
                return this.enter.apply(this, arguments);
            },

            leave(node, done) {
                return animate(node, 'rc-menu-collapse', {
                    start() {
                        node.style.height = `${node.offsetHeight}px`;
                    },
                    active() {
                        node.style.height = 0;
                    },
                    end() {
                        node.style.height = '';
                        done();
                    },
                });
            },
        };

        const navToSysAdmin = () => {
            this.state.activeMotifIconId = 4;
            this.props.router.navigate(`/adminauth`);
        };

        const navToFiles = () => {
            this.state.activeMotifIconId = 2;
            this.props.router.navigate(`/client/${this.props.client.id}/reit/${this.props.reit.reitid}/period/${this.props.period.reportPeriodID}/files`);
        };

        const navToReitFiles = () => {
            this.props.router.navigate(`/client/${this.props.client.id}/reit/${this.props.reit.reitid}/files`);
        };

        const navToContactAll = () => {
            this.props.router.navigate(`/contactUsers`);
        };

        const navToMultipleUpload = () => {
            this.state.activeMotifIconId = 1;
            // Bulk Upload will open a modal
            this.props.trialBalanceActions.showBulkUploadModal();
        };

        const navToLogoff = () => {
            this.state.activeMotifIconId = 5;
            notificationActions.stopNotificationPolling();
            this.props.router.navigate(`/external/logoff`);

        };

        const setNavigationState = () => {
            this.state.activeMotifIconId = 3;
        };

        function handleNotificationToggleDisplay() {
            setNavigationState();
            self.props.notificationActions.toggleNotificationsDisplay()
        }

        const handleToggleUserDropdown = () => {
            this.setState({ isUserDropdownOpen: !this.state.isUserDropdownOpen });
        };

        const handleToggleUserDropdownWhenClickedOutside = () => {
            if (this.state.isUserDropdownOpen === true) {
                this.setState({ isUserDropdownOpen: false });
            }
        };

        const handleThemesToggle = (event) =>  {
            const darkTheme = 'motif-theme-dark';
            const lightTheme = 'motif-theme-light';

            if(event.target.checked){
                document.body.classList.remove(lightTheme);
                document.body.classList.add(darkTheme);
                this.props.themeActions.changeMotifTheme("motifDark", 'MOTIF_THEME');
                localStorage.setItem('motifThemes', darkTheme);
            }
            else  {
                document.body.classList.remove(darkTheme);
                document.body.classList.add(lightTheme);
                this.props.themeActions.changeMotifTheme("motifLight",'MOTIF_THEME');
                localStorage.setItem('motifThemes', lightTheme);
            } 

            this.setState({ isDarkTheme: event.target.checked});
        };
        const navToDataRetentionPolicy = () => {
            if(userRole!=='External Checklist User')
            {
                window.open('/helpGuide', '_blank');
            }
            
        };
        return (
            <div>
                {/* <MotifHeader style={{backgroundColor: '#1b1a24'}} */}
                <MotifHeader 
                    fixed
                    logo={
                        <MotifHeaderLogo>
                            <a href="/" aria-label="EY Logo">
                                <MotifIcon 
                                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTYxcHgiIGhlaWdodD0iNTc0cHgiIHZpZXdCb3g9IjAgMCA1NjEgNTc0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2My4xICg5MjQ1MikgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+RVlfTG9nb19CZWFtX1doaXRlX1llbGxvd19DX0NNWUs8L3RpdGxlPgogICAgPGRlZnM+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIwLjA2IDAuOTc0IDU2MC4zOTQgMC45NzQgNTYwLjM5NCA1NzQgMC4wNiA1NzQiPjwvcG9seWdvbj4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJFWV9Mb2dvX0JlYW1fZGVmYXVsdExvZ29XaGl0ZV9ZZWxsb3dfQ19DTVlLIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWxpbmUgaWQ9IkZpbGwtMSIgZmlsbD0iI0ZGRkZGRSIgcG9pbnRzPSI4OS45MTYgNDYyLjAzMSAxOTIuNDE3IDQ2Mi4wMzEgMTkyLjQxNyA0MDIuNTExIDg5LjkxNiA0MDIuNTExIDg5LjkxNiAzNTUuNzM0IDIwMy4zMDQgMzU1LjczNCAxNjUuNjg1IDI5MC41MzcgNC45MDMgMjkwLjUzNyA0LjkwMyA1NzQgMjMxLjY2NyA1NzQgMjMxLjY2NyA1MDguODAzIDg5LjkxNiA1MDguODAzIDg5LjkxNiA0NjIuMDMxIj48L3BvbHlsaW5lPgogICAgICAgIDxnIGlkPSJHcm91cC01Ij4KICAgICAgICAgICAgPG1hc2sgaWQ9Im1hc2stMiIgZmlsbD0id2hpdGUiPgogICAgICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgPC9tYXNrPgogICAgICAgICAgICA8ZyBpZD0iQ2xpcC0zIj48L2c+CiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iRmlsbC0yIiBmaWxsPSIjRkZGRkZFIiBtYXNrPSJ1cmwoI21hc2stMikiIHBvaW50cz0iMzgyLjU4OSAyOTAuNTM3IDMzNC40MzkgMzgzLjA0MiAyODYuNDA1IDI5MC41MzcgMTkyLjQxMSAyOTAuNTM3IDI5MS40NTIgNDYyLjAzMSAyOTEuNDUyIDU3NCAzNzYuMjA2IDU3NCAzNzYuMjA2IDQ2Mi4wMzEgNDc1LjM3OCAyOTAuNTM3IDM4Mi41ODkgMjkwLjUzNyI+PC9wb2x5bGluZT4KICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJGaWxsLTQiIGZpbGw9IiNGQkQwMjAiIG1hc2s9InVybCgjbWFzay0yKSIgcG9pbnRzPSI1NjAuMzk0IDAuOTc0IDAgMjA1LjUwNSA1NjAuMzk0IDEwNi41MTEgNTYwLjM5NCAwLjk3NCI+PC9wb2x5bGluZT4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                                />
                                <span className="hide-for-accessibility">Home</span>
                            </a>
                        </MotifHeaderLogo>
                    }
                    appHeaderName="REITSuite"
                    iconsContainer={
                        <div style={{ width: '100%' }}>
                            {showHeaderIcons && (
                                <span style={{ float: 'right' }}>
                                    {showtbUploadNavLink &&
                                        !this.props.clientIsAudit &&
                                        this.props.client &&
                                        this.props.client.id > 0 && (
                                            <span>
                                                <MotifIconButton
                                                    aria-label="TB Upload"
                                                    type="button"
                                                    className={
                                                        this.state.activeMotifIconId == 1
                                                            ? 'motif-icon-active'
                                                            : ''
                                                    }
                                                    onClick={navToMultipleUpload}
                                                >
                                                    <MotifIcon iconFunction={IconoirUpload} title="TB Upload" size='24'/>
                                                </MotifIconButton>
                                            </span>
                                        )}

                                    {showPeriodFilesNavLink && !this.props.clientIsAudit && (
                                        <span>
                                            <MotifIconButton
                                                aria-label="File Management"
                                                type="button"
                                                className={
                                                    this.state.activeMotifIconId == 2
                                                        ? 'motif-icon-active'
                                                        : ''
                                                }
                                                onClick={navToFiles}
                                            >
                                                <MotifIcon iconFunction={IconoirArchive} title="File Management" size='24' />
                                            </MotifIconButton>
                                        </span>
                                    )}
                                    {(userRole!=='External Checklist User')  && (
                                        <span>
                                        <MotifIconButton 
                                            className="header-Help-icon" 
                                            aria-label="Help" 
                                            id="help"  
                                            type="button" 
                                            data-testid="btnHelp"
                                            onClick={navToDataRetentionPolicy}
                                            variant="text">
                                                <MotifIcon iconFunction={IconoirHelpCircle} title="Help" size="24"/>
                                        </MotifIconButton>
                                        </span>
                                     )} 

                                    <span>
                                        <MotifIconButton
                                            aria-label="Notifications"
                                            type="button"
                                            className={
                                                this.props.showNotifications
                                                    ? 'motif-icon-active'
                                                    : ''
                                            }
                                            onClick={handleNotificationToggleDisplay}
                                        >
                                            <div style={{ position: 'relative' }}>
                                                <div style={{ width: '100%', height: '100%' }}>
                                                    <MotifIcon iconFunction={IconoirBellNotification} title="Notifications" size='24'/>
                                                </div>
                                                <div>
                                                    {(Array.isArray(this.props.notifications) &&
                                                        this.props.notifications.length > 0 && (                                                            
                                                        <MotifBadge className="header-notification-count"
                                                            value={this.props.notifications.length}
                                                            data-testid="badgeNotification" />
                                                        )) ||
                                                        null}
                                                </div>
                                            </div>
                                        </MotifIconButton>
                                    </span>

                                    {isSysAdmin && (
                                        <span>
                                            <MotifIconButton
                                                aria-label="Manage System Administrators"
                                                type="button"
                                                data-testid="btnMngSysAdmin"
                                                onClick={navToSysAdmin} >
                                                <MotifIcon iconFunction={IconoirUserStar} title="Manage System Administrators" size='24' />
                                            </MotifIconButton>
                                        </span>
                                    )}
                                    <span style={{ float: 'right' }}>
                                        <UserAvatarComponent
                                            isUserDropdownOpen={this.state.isUserDropdownOpen}
                                            handleToggleUserDropdownWhenClickedOutside={handleToggleUserDropdownWhenClickedOutside}
                                            handleToggleUserDropdown={handleToggleUserDropdown}
                                            userName={name}
                                            userEmail={userId}
                                            userRole={userRole}
                                            userProfilePictureUrl={this.props.userProfilePictureUrl}
                                            navToLogoff={navToLogoff}
                                            handleThemesToggle={handleThemesToggle}
                                            isDarkTheme={this.state.isDarkTheme} />
                                    </span >
                                </span >
                            )
                            }
                        </div>
                    }
                 />
            </div >
        );
    }
}

NavBar.propTypes = {
    user: PropTypes.object,
    client: PropTypes.object,
    reit: PropTypes.object,
    period: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    notifications: PropTypes.array,
    notificationActions: PropTypes.object,
    trialBalanceActions: PropTypes.object,
    showNotifications: PropTypes.bool.isRequired,
    authentication: PropTypes.string,
    microsoftGraphApiActions: PropTypes.object,
    userProfilePictureUrl: PropTypes.string,
    motifThemes: PropTypes.string,
    themeActions: PropTypes.object,
};

NavBar.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let result = {
        client: state.client,
        reit: state.reit,
        period: state.period,
        currentUserAuthorizations: state.currentUserAuthorizations,
        notifications: state.notifications,
        authentication: state.authentication,
        showNotifications: state.showNotifications,
        clientIsAudit: state.client && state.client.isAudit,
        userProfilePictureUrl: state.userProfilePicture,
        router: ownProps.router,
        motifThemes: state.motifThemes
    };

    return result;
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authActions, dispatch),
        notificationActions: bindActionCreators(notificationActions, dispatch),
        trialBalanceActions: bindActionCreators(trialBalanceActions, dispatch),
        microsoftGraphApiActions: bindActionCreators(microsoftGraphApiActions, dispatch),
        themeActions: bindActionCreators(themeActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withMsal(NavBar)));

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SubmissionError, getFormValues } from "redux-form";
import EditClientComponent from "../../components/clients/EditClientComponent";
import * as clientActions from "../../actions/clientActions";
import * as dataPurgeActions from "../../actions/dataPurgeActions";
import * as formHelpers from "../../scripts/formHelpers";
import * as authActions from "../../actions/authActions";
import * as commonActions from "../../actions/commonActions";
import ReactivateClientModal from "../../components/clients/ReactivateClientModal";
import ClientTypeSwitchingConfirmationMessageModal from "../../components/clients/ClientTypeSwitchingConfirmationMessageModal";
import ClientTypeSwitchingErrorMessageModal from "../../components/clients/ClientTypeSwitchingErrorMessageModal";
import * as authorizationDomainValidator from "../../scripts/authorizationDomainValidator";
import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifIcon,
    MotifModalBody,
    MotifModalFooter
} from "@ey-xd/motif-react";
import { ToastContainer, toast, Slide } from 'react-toastify';
import { IconoirWarningCircle } from '@ey-xd/motif-icon';
import { withRouter } from "../../common/withRouter";
import DataStewardAssignmentRequiredModal from '../../components/dataStewardRoleManagement/dataStewardAssignmentRequiredModal';
import * as Constants from '../../constants/other';
import DeactivateClientDisplayErrorMessageModal from "../../components/clients/DeactivateClientDisplayErrorMessageModal";

/**
 * The ClientPage class
 * @extends {React.Component}
 */
export class EditClientPage extends React.Component {
    /**
     * Creates a new EditClientPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            clientFormPurpose: "",
            maximumDataStewardReachedMessage: false,
            showMyEyOnboardingModal: false,
            auth: { "userId": null, "resource": authActions.resource.Client, "role": "2", "resourceId": this.props.clientId, "id": null, receiveDailyNewsletter: false, receiveWeeklyNewsletter: false, receiveRealTimeNotification: false, receiveRealTimeNotificationForNewPsqArqcSent: false, isDataSteward: false },
            confirmAction: null,
            clientUserSearchText: "",
            currentUserId: this.props.currentUserId,
            currentUserIsSysAdmin: this.props.currentUserIsSysAdmin,
            maxLimitMessageText: null,
            hideSuccessMessage: false,
            showReactivateClientModal: false,
            clientData: {},
            isActive: true,
            isAudit: false,
            numberOfEngagementAdmins: 0,
            numberOfDataStewards: 0,
            dataPurgeMaximumDSValue: 0,
            allowedDomains: "",
            myEyUser: {},
            isDataSteward: false,
            showDSError: false,
            dataStewardCheckboxDisplay: false,
            dataPurgeConfigurationData: [],
            deletedUserID: null,
            dataStewardValue: false,
            canSwitchType: false,
            isDisabledValue: !this.props.currentUserIsSysAdmin,
        };
        this.handleClientSubmit = this.handleClientSubmit.bind(this);
        this.updateClient = this.updateClient.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearchText = this.handleClearSearchText.bind(this);
        this.handleNewButtonClick = this.handleNewButtonClick.bind(this);
        this.toggleShowMyEyOnboardingModal = this.toggleShowMyEyOnboardingModal.bind(this); 
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.handleDismissSuccessMessage = this.handleDismissSuccessMessage.bind(this);
        this.handleToggleActiveFlag = this.handleToggleActiveFlag.bind(this);
        this.handleToggleAuditFlag = this.handleToggleAuditFlag.bind(this);
        this.handleReactivateClient = this.handleReactivateClient.bind(this);
        this.handleDeactivateClient = this.handleDeactivateClient.bind(this);
        this.toggleShowReactivateClientModal = this.toggleShowReactivateClientModal.bind(this);
        this.toggleIsActive = this.toggleIsActive.bind(this);
        this.handleEditClientCancel = this.handleEditClientCancel.bind(this);
        this.redirectToPropertiesPage = this.redirectToPropertiesPage.bind(this);
        this.validateDomain = this.validateDomain.bind(this);
        this.validateDomainWithRole = this.validateDomainWithRole.bind(this);
        this.handleOnRoleTypeChange = this.handleOnRoleTypeChange.bind(this);
        this.handleDataStewardsForClientCount = this.handleDataStewardsForClientCount.bind(this);
        this.toggleShowMinimumDataStewardWarningModal = this.toggleShowMinimumDataStewardWarningModal.bind(this);
        this.toggleShowDeactivateClientModal = this.toggleShowDeactivateClientModal.bind(this);
        this.toastSystem = React.createRef();
        this.toggleShowClientSwitchingModal = this.toggleShowClientSwitchingModal.bind(this);
        this.toggleShowClientSwitchingErrorModal = this.toggleShowClientSwitchingErrorModal.bind(this);
        this.handleClientSwitching = this.handleClientSwitching.bind(this);
    }

    /**
     * Invoked immediately after a component mounts
     */
    componentDidMount() {
        if (this.props.clientId) {
            this.props.actions.loadClient(this.props.clientId);
            this.props.dataPurgeActions.fetchDataPurgeConfigurationData();
            this.props.authorizationActions.fetchAuthorizations(null, 2, this.props.clientId, null);
            this.props.clientActions.checkIfClientCanSwitchType(this.props.clientId).then(
                (responseData) => {
                    this.setState({ canSwitchType: responseData })
                });
            this.setFilter();
        } else {
            this.props.actions.unloadClient();
        }
        authActions.fetchAllowedAuthorizationDomains().then((allowedDomains) => { this.setState({ allowedDomains: allowedDomains }); });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.authorizations !== prevProps.authorizations && this.props.authorizations && prevProps.authorizations) {
            if (this.props.clientId) {
                const list = Object.assign([], this.props.authorizations);
                this.setState({ filteredClientAuths: list },
                    this.setState({
                        numberOfEngagementAdmins: this.props.authorizations.filter(x => x.resourceId === this.props.clientId.toString() && x.resource === 2 && x.role === 1).length,
                    })
                );
            }
        }
     
        if (prevState.numberOfDataStewards !== this.state.numberOfDataStewards) {
            if (this.state.numberOfDataStewards >= this.dataPurgeMaximumDSValue) {
                this.setState({ dataStewardCheckboxDisplay: true });
            }
        }

        if (this.props.dataPurgeConfigurationData != prevProps.dataPurgeConfigurationData) {
            this.getDataPurgeMaximumDSValue();
        }
        if (this.props.clientId !== prevProps.clientId) {
            this.props.actions.loadClient(this.props.clientId);
            this.props.dataPurgeActions.fetchDataPurgeConfigurationData();
            this.props.authorizationActions.fetchAuthorizations(null, 2, this.props.clientId, null);

            this.setFilter();
        }

        if (this.props.client !== prevProps.client) {
            if (this.props.client !== null) {
                this.setState({ isActive: this.props.client.isActive, isAudit: this.props.client.isAudit });                
                this.props.dataPurgeActions.fetchDataPurgeConfigurationData();


            }
        }
    }
    toggleShowDeactivateClientModal() {
        this.setState({ showDeactivateErrorMessageModal: false });
    }

    toggleShowClientSwitchingModal() {
        this.setState({ showClientSwitchingMessageModal: false });
    }

    toggleShowClientSwitchingErrorModal() {
        this.setState({ showClientSwitchingErrorMessageModal: false });
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearFormErrors('client');
    }

    handleDismissSuccessMessage() {
        this.setState({ hideSuccessMessage: true });
    }

    handleEditClientCancel() {
        this.props.router.navigate("/");
    }

    getDataPurgeMaximumDSValue() {
            const dataPurgeConfig = this.props.dataPurgeConfigurationData && this.props.dataPurgeConfigurationData.find(item => item.dataPurgeConfigurationID === 4);
            return this.dataPurgeMaximumDSValue = dataPurgeConfig ? dataPurgeConfig.dataPurgeConfigurationValue : null;
    }

    handleDataStewardCheckboxDisplay(numberOfDataStewards) {
        if (numberOfDataStewards >= this.dataPurgeMaximumDSValue) {
            this.setState({ dataStewardCheckboxDisplay: true });
        }
        else {
            this.setState({ dataStewardCheckboxDisplay: false });
        }
    }

    handleDataStewardsForClientCount() {
        const numberOfDataStewards = this.props.authorizations && this.props.authorizations.filter(x => x.resourceId === this.props.clientId.toString() && x.resource === 2 && x.isDataSteward === true).length;
        this.setState({
            numberOfDataStewards: numberOfDataStewards
        });
        this.handleDataStewardCheckboxDisplay(numberOfDataStewards);

    }

    handleClientSubmit(values) {
        this.setState({ hideSuccessMessage: false });
        const client = {
            id: this.props.clientId,
            legalName: values.legalName,
            name: values.name,
            gfisCode: values.gfisCode,
            engagementNumber: values.engagementNumber,
            isActive: this.state.isActive,
            isAudit: this.state.isAudit,
            contactName: values.contactName,
            contactPhone: values.contactPhone,
            contactEmail: values.contactEmail,
            engagementAdministratorEmail: values.engagementAdministratorEmail
        };

        if (client.id === 0 || isNaN(client.id)) {
            this.setState({ clientFormPurpose: "" });
            return this.createClient(client);
        }

        this.setState({ clientFormPurpose: "EDIT" });
        return this.updateClient(client);
    }

    createClient(client) {
        return this.props.clientActions.createClient(client).then(data => {
            this.setState({ reloadClients: true });
            if (data && data.id && !data.errorCode) { // As long as we don't get back data, or the data we get back doesn't have an errorCode, assume success
                this.props.router.navigate(`/client/${data.id}/edit?showSuccess=true`);
                return;
            }

            formHelpers.generateSubmissionError(data);
        }).catch(err => {
            if (err instanceof SubmissionError) {
                throw err;
            }

            formHelpers.generateSubmissionError();
        });
    }

    updateClient(client) {
        return clientActions.updateClient(client).then(data => {
            if (data && data.id && !data.errorCode) { // As long as we don't get back data, or the data we get back doesn't have an errorCode, assume success
                this.props.actions.loadClient(this.props.clientId);
                this.props.router.navigate(`/client/${data.id}/edit?showSuccess=true`);
                return;
            }

            formHelpers.generateSubmissionError(data);
        }).catch(err => {
            if (err instanceof SubmissionError) {
                throw err;
            }

            formHelpers.generateSubmissionError();
        });
    }

    handleSearchChange(e) {
        this.setState({ clientUserSearchText: e.target.value }, this.setFilter);
    }

    handleClearSearchText(e) {
        this.setState({ clientUserSearchText: "" }, this.setFilter);
    } 

    handleNewButtonClick(e) {
        e.preventDefault();
        this.toggleShowAddClientAuthModal();
        this.handleDataStewardsForClientCount();        
    }    
    reloadAdminAuths(reloadAdminAuths) {
        if (reloadAdminAuths) {
            this.props.authorizationActions.fetchAuthorizations(null, 2, this.props.clientId, null);
        }
    }
    toggleShowMyEyOnboardingModal() {
        this.setState({ showMyEyOnboardingModal: !this.state.showMyEyOnboardingModal });
    }

    setFilter() {
        if (this.state.clientUserSearchText === "") {
            this.setState({ filteredClientAuths: Object.assign([], this.props.authorizations) });
        } else {
            this.setState({ filteredClientAuths: this.props.authorizations.filter((auth) => auth.userId.toLowerCase().includes(this.state.clientUserSearchText.toLowerCase())) });
        }
    }


    handleToggleActiveFlag(e, clientId, clientName, clientLegalName, isDisabled) {
        const clientData = {
            "id": clientId,
            "name": clientName,
            "legalName": clientLegalName
        };
        if (e.target.checked) {
            // Only do confirmation if not a NEW CLIENT
            if (this.props.clientId !== 0 && !isDisabled) {
                this.setState({ clientData: clientData }, this.toggleShowReactivateClientModal());
            }
            else {
                this.handleReactivateClient();
            }

            return;
        }

        this.handleDeactivateClient();
    }

    handleToggleAuditFlag(e, isDisabled) {
        if(!isDisabled){
            if(this.props.clientId>0){
            this.props.clientActions.checkIfClientCanSwitchType(this.props.clientId)
                .then((responseData) => {
                    this.setState({ canSwitchType: responseData }, () => {
                        if (this.state.canSwitchType) {
                            this.setState({ showClientSwitchingMessageModal: true });
                        } else {
                            this.setState({ showClientSwitchingErrorMessageModal: true });
                        }                  
                    });
                });
        }
        else{
            this.setState({ isAudit: !this.state.isAudit });
        }
     }
    }

    handleClientSwitching() {
        this.setState({ showClientSwitchingMessageModal: false });
        const requestData = {
            "clientId": this.props.clientId,
            "isAudit": !this.state.isAudit,
        };
        this.props.clientActions.switchClientType(requestData).then(() => {
            this.setState({ isAudit: !this.state.isAudit });
            this.props.actions.loadClient(this.props.clientId);
        });
    }

    handleReactivateClient() {
        this.setState({ isActive: true, showReactivateClientModal: false });
    }

    handleDeactivateClient() {
        if (!this.state.isDisabledValue) {
            if (this.props.clientId > 0) {
                this.props.dataPurgeActions.getdataPurgeApprovedStatus(this.props.clientId).then((responseData) => {
                    if (responseData && responseData.returnCode === 0) {
                        this.setState({ showDeactivateErrorMessageModal: true });
                    } else {
                        this.setState({ isActive: false, showReactivateClientModal: false });
                    }
                });
            } else {
                this.setState({ isActive: false, showReactivateClientModal: false });
            }
        }
    }

    toggleShowReactivateClientModal(periodId) {
        this.setState({ showReactivateClientModal: !this.state.showReactivateClientModal });
    }

    toggleIsActive() {
        this.setState({ isActive: !this.state.isActive });
    }

    redirectToPropertiesPage(reitId, reportPeriodId) {

        this.props.router.navigate(`/client/${this.props.clientId}/reit/${reitId}/period/${reportPeriodId}/properties`);
    }

    validateDomain(value) {
        return authorizationDomainValidator.allowedDomain(value, this.state.allowedDomains);
    }

    validateDomainWithRole(value, allValues) {
        return authorizationDomainValidator.allowedDomain(value, this.state.allowedDomains, allValues);
    }
   
    handleDataSteward(e) {
        if (e && e.target) {
            this.setState({ isDataSteward: e.target.checked });
        }
    }

    handleOnRoleTypeChange(e) {
        if (e && e.target && e.target.value == "4") {
            this.setState({ receiveRealTimeNotification: false });
            this.setState({ receiveRealTimeNotificationForNewPsqArqcSent: true });
        }
        else {
            this.setState({ receiveRealTimeNotification: true });
            this.setState({ receiveRealTimeNotificationForNewPsqArqcSent: false });
        }
    }

    toggleShowMinimumDataStewardWarningModal = () => {
        this.setState({
            showMinimumDataStewardWarningModal: !this.state.showMinimumDataStewardWarningModal,
        });

    };

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const HeaderWrapper = ({ children }) => (
            <div
                style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                }}
            >
                {children}
            </div>
        );
        const authModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                height: '160px'
            }
        };

        const myEyModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '600px',
                height: '320px'
            }
        };
        return (
            <div>
                {(this.props.client ? <h2>Client Settings</h2> : <h2>Create New Client</h2>)}
                <EditClientComponent
                    client={this.props.client}
                    lookupTypes={this.props.lookupTypes}
                    handleClientSubmit={this.handleClientSubmit}
                    showSuccess={this.props.showSuccess && !this.state.hideSuccessMessage}
                    clientFormPurpose={this.state.clientFormPurpose}
                    handleSearchChange={this.handleSearchChange}
                    handleClearSearchText={this.handleClearSearchText}
                    clientUserSearchText={this.state.clientUserSearchText}
                    handleEditButtonClick={this.handleEditButtonClick}
                    handleDeleteButtonClick={this.handleDeleteButtonClick}
                    handleNewButtonClick={this.handleNewButtonClick}
                    auth={this.state.auth}
                    currentUserId={this.state.currentUserId}
                    currentUserIsSysAdmin={this.state.currentUserIsSysAdmin}
                    maxLimitMessageText={this.state.maxLimitMessageText}
                    handleDismissErrorMessage={this.handleDismissErrorMessage}
                    handleDismissSuccessMessage={this.handleDismissSuccessMessage}
                    handleToggleActiveFlag={this.handleToggleActiveFlag}
                    isActive={this.state.isActive}
                    handleToggleAuditFlag={this.handleToggleAuditFlag}
                    isAudit={this.state.isAudit}
                    handleEditClientCancel={this.handleEditClientCancel}
                    validateDomain={this.validateDomain}
                    validateDomainWithRole={this.validateDomainWithRole}
                    validatingUser={this.state.validatingUser}
                    myEyError={this.state.myEyError} 
                    handleOnRoleTypeChange={this.handleOnRoleTypeChange} /> 
                <ReactivateClientModal
                    showReactivateClientModal={this.state.showReactivateClientModal}
                    toggleShowReactivateClientModal={this.toggleShowReactivateClientModal}
                    handleReactivateClient={this.handleReactivateClient}
                    handleCancel={this.handleDeactivateClient}
                    clientData={this.state.clientData} />
                <DeactivateClientDisplayErrorMessageModal
                    showDeactivateErrorMessageModal={this.state.showDeactivateErrorMessageModal}
                    handleCancel={this.toggleShowDeactivateClientModal}
                    confirmationMessage={Constants.deactivateClientConfirmationMessage}
                    headerTitle="Inactivate Client"
                />
                <ClientTypeSwitchingConfirmationMessageModal
                    showClientSwitchingMessageModal={this.state.showClientSwitchingMessageModal}
                    handleCancel={this.toggleShowClientSwitchingModal}
                    confirmationMessage={Constants.clientSwitchingConfirmationMessage}
                    headerTitle="Confirm Client Type Change"
                    handleClientSwitching={this.handleClientSwitching}
                />
                <ClientTypeSwitchingErrorMessageModal
                    showClientSwitchingErrorMessageModal={this.state.showClientSwitchingErrorMessageModal}
                    handleCancel={this.toggleShowClientSwitchingErrorModal}
                    confirmationMessage={Constants.clientSwitchingErrorMessage}
                    headerTitle="Client Type Change"
                />
                <MotifModal variant="alt" show={this.state.showMyEyOnboardingModal} data-testid="DeleteChecklistUserModal">
                    <MotifModalHeader>
                        <HeaderWrapper>
                            <MotifIcon style={{ color: "#F95D54" }} iconFunction={IconoirWarningCircle} size='24'  />
                            <span className="ml-2">Delete Checklist User</span>
                        </HeaderWrapper>
                    </MotifModalHeader>
                    <MotifModalBody>
                        <p>The information was successfully submitted.</p>
                        <p>REITSuite will take care of the onboarding to My EY,
                            just let the external user know that they will receive an
                            email invitation to join My EY.</p>
                        <p>If you experience any issues, please contact us at <a href="mailto:reitsuite@ey.com">reitsuite@ey.com</a>.</p>
                        <br />
                    </MotifModalBody>
                    <MotifModalFooter>
                        <MotifButton variant="primary" type="button" onClick={this.toggleShowMyEyOnboardingModal}>
                            Ok
                        </MotifButton>
                    </MotifModalFooter>
                </MotifModal>

                <DataStewardAssignmentRequiredModal
                    showEngagementAdminForDataStewardAssignmentModal={this.state.showMinimumDataStewardWarningModal}
                    toggleShowEngagementAdminForDataStewardAssignmentModal={this.toggleShowMinimumDataStewardWarningModal}
                    dataStewardAssignmentWarningHeader={Constants.dataStewardDeletionWarningHeader}
                    dataStewardAssignmentWarningBody={Constants.dataStewardDeletionWarningBody}
                />
                {/* <ToastContainer
                    ref={this.toastSystem}
                    position="bottom-right"
                    closeOnClick={false}
                    pauseOnHover={false}
                    draggable={false}
                    closeButton={false}
                    hideProgressBar={true}
                    transition={Slide}
                /> */}
            </div>
        );
    }
}

EditClientPage.propTypes = {
    authorizations: PropTypes.array,
    currentUserAuthorizations: PropTypes.array,
    clientId: PropTypes.number,
    client: PropTypes.object,
    showSuccess: PropTypes.string,
    reportPeriodId: PropTypes.number,
    actions: PropTypes.object,
    dataPurgeActions: PropTypes.object,
    currentUserId: PropTypes.string.isRequired,
    currentUserIsSysAdmin: PropTypes.bool.isRequired,
    commonActions: PropTypes.object.isRequired,
    clientAuthFormValues: PropTypes.object,
    dataPurgeConfigurationData: PropTypes.array,
    deletedUserID: PropTypes.string,
    canSwitchType: PropTypes.bool,
    clientActions: PropTypes.object,
    authorizationActions: PropTypes.object,

};

EditClientPage.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let cid = Number.parseInt(ownProps.router.params.clientId);
    if (isNaN(cid)) {
        cid = 0;
    }

    let periodId = Number.parseInt(ownProps.router.params.periodId);
    if (isNaN(periodId)) {
        periodId = 0;
    }
    const authorizations = state.authorizations;
    const currentUserAuthorizations = state.currentUserAuthorizations;
    const searchParams = new URLSearchParams(ownProps.router.location.search);
    const showSuccess = searchParams.get('showSuccess');
    return {
        clientId: cid,
        reportPeriodId: periodId,
        client: state.client,
        reits: state.reits,
        lookupTypes: state.lookupTypes,
        showSuccess: showSuccess,
        authorizations: authorizations,
        currentUserAuthorizations: currentUserAuthorizations,
        dataPurgeConfigurationData: state.dataPurgeConfigurationData,
        currentUserId: state.authentication.currentUser,
        currentUserIsSysAdmin: Array.isArray(state.currentUserAuthorizations) && (authActions.isSystemAdministrator(state.currentUserAuthorizations)),
        clientAuthFormValues: getFormValues('clientAuth')(state),
        canSwitchType: state.canSwitchType
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(clientActions, dispatch),
        authorizationActions: bindActionCreators(authActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        dataPurgeActions: bindActionCreators(dataPurgeActions, dispatch),
        clientActions: bindActionCreators(clientActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditClientPage));
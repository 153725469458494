import PropTypes from "prop-types";
import React from "react";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifIcon } from "@ey-xd/motif-react";
import { IconoirWarningCircle } from '@ey-xd/motif-icon';

const ClientTypeSwitchingConfirmationMessageModal = ({ showClientSwitchingMessageModal, handleCancel, confirmationMessage, headerTitle,handleClientSwitching
}) => {
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );

    const closeButtonProps = {
        title: headerTitle,
        ariaLabel: headerTitle,
        dataTestid: 'close'
    };

    return (
        <MotifModal variant="alt" show={showClientSwitchingMessageModal} onClose={handleCancel} data-testid="Delete">
            <MotifModalHeader closeButtonProps={closeButtonProps} >
                <HeaderWrapper>
                    <MotifIcon className="mt-2" style={{ color: "#F95D54" }} iconFunction={IconoirWarningCircle} size='24'  />
                    <span className="ml-2 mt-1">{headerTitle}</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <span className="deleteActionMsg">{confirmationMessage}</span>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" className="right"
                    data-testid="Delete"
                    type="button"
                    onClick={handleCancel}>Close</MotifButton>
                <MotifButton variant="primary" className="right" type="button" data-testid="Confirm"
                 onClick={() => handleClientSwitching()}
                >
                    Confirm
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

ClientTypeSwitchingConfirmationMessageModal.propTypes = {
    showClientSwitchingMessageModal: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    confirmationMessage: PropTypes.string.isRequired,
    handleClientSwitching: PropTypes.func.isRequired,
};

export default ClientTypeSwitchingConfirmationMessageModal;
import React from "react";
import { Menu, Form } from 'semantic-ui-react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFormValues, SubmissionError, Field, reduxForm, reset, change } from "redux-form";
import * as actions from "../../actions/chartOfAccountsManagerActions";
import * as commonActions from '../../actions/commonActions';
import * as lookupTypeActions from '../../actions/lookupTypeActions';
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { store } from "../../store/configureStore";
import ChartOfAccountsManagerHeader from "../../components/chartOfAccountsManager/ChartOfAccountsManagerHeader";
import CreateChartOfAccountsModal from "../../components/chartOfAccountsManager/CreateChartOfAccountsModal";
import DeleteChartOfAccountsModal from "../../components/chartOfAccountsManager/DeleteChartOfAccountsModal";
import ChartOfAccountsTable from "../../components/chartOfAccountsManager/ChartOfAccountsTable";
import EditSaveModalMessage from "../../components/chartOfAccountsManager/EditSaveModalMessage";
import EditChartNameModal from '../../components/chartOfAccountsManager/EditChartNameModal';
import CreateClientAccountModal from "../../components/chartOfAccountsManager/CreateClientAccountModal";
import ChangeDefaultChartOfAccountsModal from '../../components/chartOfAccountsManager/ChangeDefaultChartOfAccountsModal';
import ChartUploadModal from '../../components/chartOfAccountsManager/ChartUploadModal';
import { MotifTabNavigation, MotifTabControl } from "@ey-xd/motif-react";
import { Link } from 'react-router-dom';
import { withRouter } from "../../common/withRouter";

/**
 * ChartOfAccountsManagerContainer container component
 * @extends {React.Component}
 */
export class ChartOfAccountsManagerContainer extends React.Component {
    /**
          * Creates a new ChartOfAccountsManagerContainer
          * @constructor
          * @param {Object} props The component properties
          * @param {Object} context The component context
          */
    constructor(props, context) {
        super(props, context);

        this.updateInitialValues = this.updateInitialValues.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleCreateChart = this.handleCreateChart.bind(this);
        this.handleSaveAsTemplate = this.handleSaveAsTemplate.bind(this);
        this.handleChartSelection = this.handleChartSelection.bind(this);
        this.toggleShowCreateTemplateModal = this.toggleShowCreateTemplateModal.bind(this);
        this.toggleShowCreateClientAccountModal = this.toggleShowCreateClientAccountModal.bind(this);
        this.toggleShowUploadChartModal = this.toggleShowUploadChartModal.bind(this);
        this.clearDuplicateErrorMessage = this.clearDuplicateErrorMessage.bind(this);
        this.clearDuplicateChartOfAccountErrorMessage = this.clearDuplicateChartOfAccountErrorMessage.bind(this);
        this.handleDeleteTemplate = this.handleDeleteTemplate.bind(this);
        this.toggleShowDeleteTemplateModal = this.toggleShowDeleteTemplateModal.bind(this);
        this.handleChangeDefaultChart = this.handleChangeDefaultChart.bind(this);
        this.toggleShowChangeDefaultChartModal = this.toggleShowChangeDefaultChartModal.bind(this);
        this.onUploadCoaFile = this.onUploadCoaFile.bind(this);
        this.onDownloadCoaTemplate = this.onDownloadCoaTemplate.bind(this);
        this.handleAddClientAccount = this.handleAddClientAccount.bind(this);
        this.handleOnEditToggle = this.handleOnEditToggle.bind(this);
        this.handleSearchBox = this.handleSearchBox.bind(this);
        this.toggleEditNameModal = this.toggleEditNameModal.bind(this);
        this.handleNameUpdate = this.handleNameUpdate.bind(this);
        this.handleClearSearchText = this.handleClearSearchText.bind(this);
        this.handleAccountUpdate = this.handleAccountUpdate.bind(this);
        this.handleAccountUpdateConfirmation = this.handleAccountUpdateConfirmation.bind(this);
        this.toggleShowEditSaveModalMessage = this.toggleShowEditSaveModalMessage.bind(this);
        //this.preventSubmitOnEnter = this.preventSubmitOnEnter.bind(this);

        this.setTabBar1 = this.setTabBar1.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            showCreateTemplateModal: false,
            showCreateClientAccountModal: false,
            showDeleteTemplateModal: false,
            showChangeDefaultChartModal: false,
            showUploadChartModal: false,
            mode: 'create',
            isEditModeEnabled: false,
            selectedChart: {
                chartMappings: []
            },
            uploadCoaFileStatus: null,
            uniqueKey: 1,
            searchBoxText: "",
            showEditSaveModalMessage: false,
            tabBar1: 0,
            chartsToUpdate: []
        };
    }

    componentDidMount() {
        // Fetch the charts for the dropdown
        this.props.actions.fetchCharts(this.props.clientId).then(() => {
            let defaultChartID = -1;

            // Attempt to find a default chart and select its ID in the dropdown
            if (this.props.chartOfAccounts && this.props.chartOfAccounts.charts && this.props.chartOfAccounts.charts.find(c => c.isDefault)) {
                defaultChartID = this.props.chartOfAccounts.charts.find(c => c.isDefault).chartID;
            }
            this.props.lookupTypeActions.fetchChartOfAccountTypes();
            if (defaultChartID > 0) {
                this.props.actions.fetchChartOfAccountsById(defaultChartID).then(fetchedChartMappings => {
                    this.setState({
                        selectedChart: { chartMappings: fetchedChartMappings },
                        uniqueKey: ++this.state.uniqueKey
                    });
                });
                this.props.dispatch(this.props.initialize({ chartID: defaultChartID }));
            }
        });
    }


    updateInitialValues(chartID, fdrTemplateName) {
        const values = Object.assign({}, {
            chartID,
            fdrTemplateName
        });

        this.props.dispatch(this.props.initialize(
            values,
            { options: { updateUnregisteredFields: true } }
        ));
    }

    //Set tab related state variables
    setTabBar1(currentIndex) {
        this.setState({ tabBar1: currentIndex });
    }

    //Set current tab index
    handleClick(index) {
        this.setTabBar1(index);
    }

    handleAddClientAccount(values) {
        const clientAccountCategoryID = values.clientAccountCategoryID !== undefined ? values.clientAccountCategoryID : null;
        this.props.actions.createChartOfAccount(
            this.props.clientId,
            this.props.chartOfAccountsFormValues.chartID,
            values.clientAccountCode,
            values.clientAccountDescription,
            clientAccountCategoryID,
            values.defaultREITTestingAttributeID,
            values.defaultSubAttribute).then((newChartOfAccountID) => {
                if (newChartOfAccountID && newChartOfAccountID.response && newChartOfAccountID.response.errorCode == 'DUPLICATE_RECORD_ERROR') {
                    // Duplicate error

                }
                else if (!newChartOfAccountID.response) {
                    this.handleChartSelection(this.props.chartOfAccountsFormValues.chartID);
                    this.toggleShowCreateClientAccountModal();
                }
            });
    }

    handleOnEditToggle() {
        this.setState(
            {
                isEditModeEnabled: !this.state.isEditModeEnabled
            }
        );
    }

    handleCancel() {
        this.handleChartSelection(this.props.chartOfAccountsFormValues.chartID);
    }

    onUploadCoaFile(acceptedFiles, rejectedFiles) {
        if (Array.isArray(rejectedFiles) && rejectedFiles.length > 0) {
            this.setState({ uploadCoaFileStatus: 'Rejected' });
        } else if (Array.isArray(acceptedFiles)) {
            this.setState({ uploadCoaFileStatus: 'Uploading' });

            this.props.actions.uploadCoaFileAndGetCoaMappings(
                acceptedFiles,
                this.props.clientId)
                .then(responses => {
                    if (responses.every(r => r)) {
                        this.setState({ uploadCoaFileStatus: 'Success' });
                    } else {
                        this.setState({ uploadCoaFileStatus: 'Error' });
                    }
                });
        }
    }

    onDownloadCoaTemplate(clientId) {
        // this.props.commonActions.beginTask();
        // return actions.downloadCoaTemplate(clientId).then(blob => {
        //     this.props.commonActions.endTask();
        //     return blob;
        // });
    }

    handleSearchBox(e) {
        this.setState({
            searchBoxText: e.target.value
        })
    }

    handleClearSearchText() {
        this.setState({
            searchBoxText: ""
        })
    }

    handleNameUpdate(values) {
        this.props
            .actions
            .updateChartName(values.chartID, values.chartName)
            .then(response => {
                if (response &&
                    response.response &&
                    response.response.errorCode == 'DUPLICATE_RECORD_ERROR') {
                    return;
                }

                this.props.actions.fetchCharts(this.props.clientId).then(() => {
                    this.toggleEditNameModal();
                    this.props.dispatch(this.props.change('chartID', values.chartID));
                });
            });
    }

    handleAccountUpdate(chartsToUpdate) {
        this.setState(
            {
                chartsToUpdate: chartsToUpdate,
                isEditModeEnabled: false,
                showEditSaveModalMessage: true
            }
        );
    }

    handleAccountUpdateConfirmation() {
        this.props
            .actions
            .updateChartOfAccountsById(this.props.chartOfAccountsFormValues.chartID, this.state.chartsToUpdate)
            .then(response => {
                this.setState(
                    {
                        chartsToUpdate: [],
                        isEditModeEnabled: false,
                        showEditSaveModalMessage: false
                    }
                );

                this.handleChartSelection(this.props.chartOfAccountsFormValues.chartID)
            });
    }

    handleCreateChart(values) {
        this.props.actions.createChart(this.props.clientId, values.chartName, values.copyExistingChart, values.isDefault, values.copyChartID).then((newChartID) => {
            if (newChartID && newChartID.response && newChartID.response.errorCode == 'DUPLICATE_RECORD_ERROR') {
                // Duplicate error
            }
            else if(newChartID && newChartID.errorResponse && newChartID.errorResponse.errorCode == 'BAD_REQUEST') {
                // Bad request error
            }
            else if (!newChartID.response && newChartID != undefined) {
                // Chart created successfully
                // Refresh chart list and then select newly created chart in the dropdown
                this.props.actions.fetchCharts(this.props.clientId).then(() => {
                    this.toggleShowCreateTemplateModal();
                    this.props.dispatch(this.props.change('chartID', newChartID));
                    if (newChartID && (!newChartID.errorResponse || newChartID.errorResponse.errorCode !== "UNAUTHORIZED")) {
                        this.handleChartSelection(newChartID);
                      }
                });
            }
        });
    }

    handleSaveAsTemplate(values) {
        let chartId = values.chartId ? values.chartId : this.props.chartOfAccountsFormValues.chartID;
        this.props.actions.SaveAsNewFDRTemplate(this.props.clientId, chartId, values.fdrTemplateName, this.getHtml(this.editor.view.state)).then((newChartId) => {
            if (newChartId && newChartId.response && newChartId.response.errorCode == 'DUPLICATE_RECORD_ERROR') {
                //Duplicate error
            }
            else {
                //If the values.chartId its equal to -1 it because is a new template otherwise is a current template to be modified
                this.props.actions.fetchchartOfAccounts(this.props.clientId).then(() => {
                    this.toggleShowCreateTemplateModal();
                    this.props.dispatch(this.props.change('chartID', newChartId));
                });

            }
        });
    }

    handleChartSelection(chartId) {
        // -1 is "Select an existing template"
        // if (chartId === -1) {
        //     //Here is the code to hanlde the "Select an Existing Chart" option
        //     return false;
        // } else {

        // Fetch the template contents (html)
        this.props.actions.fetchChartOfAccountsById(chartId).then(fetchedChartMappings => {
            this.setState({
                selectedChart: { chartMappings: fetchedChartMappings },
                uniqueKey: ++this.state.uniqueKey
            });
        });

        // }
    }

    clearDuplicateErrorMessage() {
        this.props.actions.clearDuplicateMessage();
    }

    clearDuplicateChartOfAccountErrorMessage() {
        this.props.actions.clearDuplicateChartOfAccountMessage();
    }

    toggleShowCreateTemplateModal(mode) {
        this.props.actions.clearDuplicateMessage();
        this.setState({
            showCreateTemplateModal: !this.state.showCreateTemplateModal,
            mode: mode
        });
    }

    toggleEditNameModal() {
        this.props.actions.clearDuplicateMessage();

        this.setState({
            showEditNameModal: !this.state.showEditNameModal
        });
    }


    toggleShowCreateClientAccountModal() {
        this.clearDuplicateChartOfAccountErrorMessage();
        this.setState({
            showCreateClientAccountModal: !this.state.showCreateClientAccountModal,
        }, () => {
            if (!this.state.showEditCustomQuestionModal) {
                store.dispatch(reset('createClientAccountModal'));
            }
        });
    }

    toggleShowUploadChartModal() {
        this.setState({
            showUploadChartModal: !this.state.showUploadChartModal
        });
    }

    handleDeleteTemplate() {
        this.props.actions.deleteFdrTemplateById(this.props.chartOfAccountsFormValues.chartID).then(() => {
            this.toggleShowDeleteTemplateModal();
            this.props.dispatch(this.props.change('chartID', -1));
        });
    }

    toggleShowDeleteTemplateModal() {
        this.setState({
            showDeleteTemplateModal: !this.state.showDeleteTemplateModal
        });
    }

    handleChangeDefaultChart() {

        //Get selected chart id
        var newDefaultChartId = this.props && this.props.chartOfAccountsFormValues && this.props.chartOfAccountsFormValues.chartID;

        if (newDefaultChartId > 0) {

            //Set selected chart to default chart of client
            this.props
                .actions
                .updateChartAsDefault(newDefaultChartId)
                .then(response => {
                    this.props.actions.fetchCharts(this.props.clientId).then(() => {
                        this.toggleShowChangeDefaultChartModal();
                        this.props.dispatch(this.props.change('chartID', newDefaultChartId));
                    });
                });
        }
        else {
            this.toggleShowChangeDefaultChartModal();
        }
    }

    toggleShowChangeDefaultChartModal() {
        this.setState({
            showChangeDefaultChartModal: !this.state.showChangeDefaultChartModal
        });
    }

    toggleShowEditSaveModalMessage() {
        this.setState({
            chartsToUpdate: [],
            showEditSaveModalMessage: !this.state.showEditSaveModalMessage
        });
    }



    /**
     * Render a React element
     * @returns {Object} A   reference to the component
     */
    render() {


        return (
            <React.Fragment>
                <h2>Chart of Accounts Manager</h2>
                <MotifTabNavigation variant="default" >
                    <Link to={`/client/${this.props.clientId}/chartOfAccountsManager`}
                        tabIndex="-1">
                        <MotifTabControl className="motif-active"
                            autoFocus
                            role="tab"
                            onClick={() => this.handleClick(1)}>
                            Chart of Accounts
                        </MotifTabControl>
                    </Link>
                    <Link to={`/client/${this.props.clientId}/chartOfAccountsManager/assignCharts`} tabIndex="-1">
                        <MotifTabControl
                            role="tab"
                            onClick={() => this.handleClick(2)}>
                            Chart Assignments
                        </MotifTabControl>
                    </Link>
                </MotifTabNavigation>
                <ChartOfAccountsManagerHeader
                    clientId={this.props.clientId}
                    isEditModeEnabled={this.state.isEditModeEnabled}
                    chartOfAccounts={this.props.chartOfAccounts}
                    chartOfAccountsFormValues={this.props.chartOfAccountsFormValues}
                    selectedChart={this.state.selectedChart}
                    showCreateClientAccountModal={this.state.showCreateClientAccountModal}
                    showUploadChartModal={this.state.showUploadChartModal}
                    lookupTypes={this.props.lookupTypes}
                    onSubmit={this.handleCreateChart}
                    onCancel={this.handleCancel}
                    handleChartSelection={this.handleChartSelection}
                    handleAddClientAccount={this.handleAddClientAccount}
                    handleOnEditToggle={this.handleOnEditToggle}
                    handleSearchBox={this.handleSearchBox}
                    handleClearSearchText={this.handleClearSearchText}
                    searchBoxText={this.state.searchBoxText}
                    clearDuplicateChartOfAccountErrorMessage={this.clearDuplicateChartOfAccountErrorMessage}
                    toggleShowCreateTemplateModal={this.toggleShowCreateTemplateModal}
                    toggleEditNameModal={this.toggleEditNameModal}
                    toggleShowCreateClientAccountModal={this.toggleShowCreateClientAccountModal}
                    toggleShowUploadChartModal={this.toggleShowUploadChartModal}
                    toggleShowDeleteTemplateModal={this.toggleShowDeleteTemplateModal}
                    toggleShowChangeDefaultChartModal={this.toggleShowChangeDefaultChartModal} />
                <div className="row">
                    <div className="col-12 pt-2">
                        {this.props.chartOfAccountsFormValues && this.props.chartOfAccountsFormValues.chartID != -1 &&
                            <ChartOfAccountsTable
                                key={this.state.uniqueKey}
                                mappings={this.state.selectedChart.chartMappings}
                                isEditModeEnabled={this.state.isEditModeEnabled}
                                searchBoxText={this.state.searchBoxText}
                                lookupTypes={this.props.lookupTypes}
                                handleOnEditToggle={this.handleOnEditToggle}
                                handleAccountUpdate={this.handleAccountUpdate}
                            />
                        }
                    </div>
                </div>

                {this.props.chartOfAccountsFormValues && this.props.chartOfAccountsFormValues.chartId !== -1 &&
                    <div className="mb-4">
                        {/* <Form onSubmit={this.props.handleSubmit(this.handleSaveTemplate)} onKeyPress={this.preventSubmitOnEnter}>
                            <div className="row">
                                <div className="col">
                                    <h6 className="mt-3">Questions in the Template</h6>
                                </div>
                                <div className="col">
                                    <div className="d-flex flex-row-reverse align-items-center mt-3">
                                        <button className="btn btn-primary ml-2" type="submit" disabled={this.props.invalid || this.props.submitting}>
                                            Save
                                        </button>
                                        <button className="btn btn-secondary" type="button" onClick={this.handleCancel} disabled={this.props.submitting}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form> */}
                    </div>}

                {this.state.showCreateTemplateModal &&
                    <CreateChartOfAccountsModal
                        chartOfAccountsFormValues={this.props.chartOfAccountsFormValues}
                        chartOfAccounts={this.props.chartOfAccounts}
                        mappings={this.state.selectedChart.chartMappings}
                        onSubmit={this.handleCreateChart}
                        toggleShowCreateTemplateModal={this.toggleShowCreateTemplateModal}
                        clearDuplicateErrorMessage={this.clearDuplicateErrorMessage}
                        mode={this.state.mode}
                    />
                }

                {this.state.showEditNameModal &&
                    <EditChartNameModal
                        chartOfAccounts={this.props.chartOfAccounts}
                        chartOfAccountsFormValues={this.props.chartOfAccountsFormValues}
                        chartID={this.props.chartID}
                        onSubmit={this.handleNameUpdate}
                        toggleEditNameModal={this.toggleEditNameModal}
                        clearDuplicateErrorMessage={this.clearDuplicateErrorMessage}

                    />
                }


                <CreateClientAccountModal
                    showCreateClientAccountModal={this.state.showCreateClientAccountModal}
                    lookupTypes={this.props.lookupTypes}
                    invalid={this.props.invalid}
                    submitting={this.props.submitting}
                    isDuplicate={this.props.chartOfAccounts.duplicateClientAccount}
                    handleAddClientAccount={this.handleAddClientAccount}
                    clearDuplicateChartOfAccountErrorMessage={this.clearDuplicateChartOfAccountErrorMessage}
                    toggleShowCreateClientAccountModal={this.toggleShowCreateClientAccountModal}
                />

                <ChartUploadModal
                    showUploadChartModal={this.state.showUploadChartModal}
                    toggleShowUploadChartModal={this.toggleShowUploadChartModal}
                    chartOfAccountsFormValues={this.props.chartOfAccountsFormValues}
                    handleChartSelection={this.handleChartSelection}
                    clientId={this.props.clientId}
                />

                {this.state.showChangeDefaultChartModal && <ChangeDefaultChartOfAccountsModal
                    showChangeDefaultChartModal={this.state.showChangeDefaultChartModal}
                    handleChangeDefaultChart={this.handleChangeDefaultChart}
                    toggleShowChangeDefaultChartModal={this.toggleShowChangeDefaultChartModal}
                />
                }

                <DeleteChartOfAccountsModal
                    showDeleteTemplateModal={this.state.showDeleteTemplateModal}
                    handleYes={this.handleDeleteTemplate}
                    toggleShowDeleteTemplateModal={this.toggleShowDeleteTemplateModal}
                />

                <EditSaveModalMessage
                    showEditSaveModalMessage={this.state.showEditSaveModalMessage}
                    toggleShowEditSaveModalMessage={this.toggleShowEditSaveModalMessage}
                    handleAccountUpdateConfirmation={this.handleAccountUpdateConfirmation}
                />
            </React.Fragment>
        );
    }
}

ChartOfAccountsManagerContainer.propTypes = {
    clientId: PropTypes.number,
    actions: PropTypes.object.isRequired,
    commonActions: PropTypes.object.isRequired,
    chartOfAccountsFormValues: PropTypes.object,
    chartOfAccounts: PropTypes.object,
    lookupTypes: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: Number.parseInt(ownProps.router.params.clientId),
        chartOfAccounts: state.chartOfAccountsManager,
        chartOfAccountsFormValues: getFormValues('chartOfAccountsForm')(state),
        lookupTypes: state.lookupTypes,
        theme: state.themeServices,
        createChartModalFormValues: getFormValues('createChartModalForm')(state),
        createClientAccountFormValues: getFormValues('createClientAccountModal')(state),
        editChartNameFormValues: getFormValues('editChartNameForm')(state),
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        lookupTypeActions: bindActionCreators(lookupTypeActions, dispatch)
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        reduxForm({ form: "chartOfAccountsForm", enableReinitialize: true })(ChartOfAccountsManagerContainer)
    )
);

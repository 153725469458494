/* eslint react/no-danger: 0 */ // --> OFF
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Form, Icon, Input, Label, Popup, Table } from "semantic-ui-react";

import { NumericFormat } from 'react-number-format';
import EditChecklistItem from "./EditChecklistItem";
import ChecklistQuestionTabs from "./ChecklistQuestionTabs";
import ChecklistIncomeDetailsContainer from "../../containers/reits/ChecklistIncomeDetailsContainer";
import ChecklistAssetDetailsContainer from "../../containers/reits/ChecklistAssetDetailsContainer";
import ChecklistDistributionDetailsContainer from "../../containers/reits/ChecklistDistributionDetailsContainer";
import * as actions from "../../actions/distributionsTestActions";
import { formatCurrency, formatPercent } from "../../scripts/formFormatters";
import { FormattedNumber } from "react-intl";
import FormattedCurrency from "../shared/FormattedCurrency";
import { isEmail } from "react-multi-email";

import {
    MotifIconButton,
    MotifIcon,
    MotifFormField,
    MotifInput,
    MotifCheckbox,
    MotifRadioButton,
    MotifLabel,
    MotifSelect,
    MotifOption,
} from "@ey-xd/motif-react";

import { IconoirHelpCircle, IconoirTrash } from '@ey-xd/motif-icon';
import CustomQuestionAttachmentList from '../customChecklistQuestions/CustomQuestionAttachmentList';
const wrapInputFunction = func => value => func({ target: { value } });

let state = {};

const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (!b.includes(a[i])) return false;
    }

    return true;
};

const isUnanswered = (item) => {
    return item.selectedAnswers.lengh === 0;
};

// Check if the question is answered
const isAnswered = (item) => {
    return !(item.selectedAnswers.length === 0
        || (item.selectedAnswers.length > 0 && item.selectedAnswers[0] === ''));
};

const isInstructionType = (item) => item.itemType === 'instruction';

// Check if the child questions are answered
const hasChildrenWithAnswers = (item) => {
    // If no children then return as true
    if (item.children === undefined || item.children === null || item.children.length === 0) {
        return true;
    }

    for (let i = 0; i < item.children.length; i++) {
        // Check if the child question is visible and answered
        if (isChildQuestionVisible(item.children[i], item)) {
            if (!isAnswered(item.children[i]) && !isInstructionType(item.children[i])) {
                return false;
            }
        }
    }

    return true;
};

const hasChildrenWithUnmatchedAnswers = (item) => {
    if (item.children === undefined || item.children === null || item.children.lengh === 0) {
        return false;
    }

    for (let i = 0; i < item.children.lengh; i++) {
        let nextItem = null;
        if (i + 1 < item.children.length) {
            nextItem = item.children[i + 1];
        }

        if (!previousAnswersMatch(item.children[i], nextItem)) {
            return true;
        }
    }

    return false;
};

const isValidNumber = (value) => {
    // Regular expression to check if the value is a valid number
    const regex = /^-?\d+(\.\d+)?$/;
    return regex.test(value);
};

const previousAnswersMatch = (item, nextItem) => {

    if (!Array.isArray(item.previousAnswers)) {
        return false;
    }

    if (item.itemType.toLowerCase() === "arqcstockfmvtable") {
        for (let i = 0; i < item.previousAnswers.length; i++) {
            if (item.selectedAnswers.length < i && item.defaultAnswers.length < i) {
                return false;
            }

            if (item.selectedAnswers !== undefined && item.selectedAnswers.length > i && item.selectedAnswers[i].fmvOfStockOwned !== item.previousAnswers[i].fmvOfStockOwned && item.defaultAnswers[i].fmvOfStockOwned !== item.previousAnswers[i].fmvOfStockOwned) {
                return false;
            }

            if (item.selectedAnswers !== undefined && item.selectedAnswers.length > i && item.selectedAnswers[i].percentOfStockOwned !== item.previousAnswers[i].percentOfStockOwned && item.defaultAnswers[i].percentOfStockOwned !== item.previousAnswers[i].percentOfStockOwned) {
                return false;
            }
        }

        return true;
    }

    if (item.selectedAnswers.length > 0 && !arraysEqual(item.selectedAnswers, item.previousAnswers)) {
        return false;
    }

    if (item.selectedAnswers.length === 0 && !arraysEqual(item.defaultAnswers, item.previousAnswers)) {
        return false;
    }

    if (nextItem && hasRelatedItemWithUnmatchedAnswers(item, nextItem)) {
        return false;
    }

    if (!Array.isArray(item.children)) {
        return true;
    }

    for (let i = 0; i < item.children.length; i++) {
        if (item.children[i].selectedAnswers.length > 0 && !arraysEqual(item.children[i].selectedAnswers, item.children[i].previousAnswers)) {
            return false;
        }

        if (item.children[i].defaultAnswers.length > 0 && !arraysEqual(item.children[i].defaultAnswers, item.children[i].previousAnswers)) {
            return false;
        }
    }

    return true;
};

const hasRelatedItemWithUnmatchedAnswers = (item, nextItem) => {
    if (!item || !nextItem) {
        return false;
    }

    let itemHierarchy = getHierarchy(item.title);
    let nextItemHierarchy = getHierarchy(nextItem.title);

    // If both items have the same number of array elements in the hierarchy, then they are not considered related.
    // For example, a title starting with "II.2 " is not related to an item with a title that starts with "II.3 ",
    // but is related to an item that starts with "II.2.a "
    if (nextItemHierarchy.length <= itemHierarchy.length) {
        return false;
    }

    for (let i = 0; i < itemHierarchy.length; i++) {
        if (itemHierarchy[i] !== nextItemHierarchy[i]) {
            return false;
        }
    }

    return !previousAnswersMatch(nextItem, null);
};

// The hierarchy will return an array with only the groups that match in the first part of the title
// For example title "II.2 Please identify..." will return ["II", "2"]
// and title "II.2.a Check the..." will return ["II", "2", "a"]
const getHierarchy = (title) => {
    let results = [];
    if (!title) {
        return results;
    }

    let matches = title.match(/((?:[A-Za-z0-9]+)+)\.{0,1}((?:[A-Za-z0-9]+)*)\.{0,1}((?:[A-Za-z0-9]+)*)/);

    if (!Array.isArray(matches) || matches.length < 2) {
        return results;
    }

    for (let i = 1; i < matches.length; i++) {
        if (matches[i]) {
            results.push(matches[i]);
        }
    }

    return results;
};

const isChildQuestionVisible = (item, parentItem) => {
    let isVisible = false;
    if (item.enabledByParentAnswers === undefined || item.enabledByParentAnswers.length === 0) {
        isVisible = true;
    } else {
        // determine whether the item is enabled by the parent item
        for (let i = 0; i < item.enabledByParentAnswers.length; i++) {
            if ((parentItem.selectedAnswers === undefined || parentItem.selectedAnswers.length === 0) &&
                parentItem.defaultAnswers.find(x => x === item.enabledByParentAnswers[i]) !== undefined) {
                isVisible = true;
                break;
            } else if (parentItem.selectedAnswers.find(x => x === item.enabledByParentAnswers[i]) !== undefined) {
                isVisible = true;
                break;
            }
        }
    }
    return isVisible;
}

const renderChild = (item,
    parentItem,
    isEnabled,
    handleItemAnswered,
    showAllQuestions,
    isExternal,
    isArqc,
    previousChecklistFound,
    checkReportPeriodVisibilityConstraint,
    handleSaveComment,
    handleDeleteComment,
    handleEditComment,
    prePopulateAnswerOptionID,
    showUnansweredQuestions,
    handleDownloadAttachmentClick) => {

    let isVisible = isChildQuestionVisible(item, parentItem);

    if (isVisible) {
        return (
            <EditChecklistItem key={item.title} isEnabled={isEnabled && item.isEnabled}
                item={item} parentItem={parentItem} handleItemAnswered={handleItemAnswered} showAllQuestions={showAllQuestions}
                isExternal={isExternal} isArqc={isArqc} previousChecklistFound={previousChecklistFound}
                canEdit={state.canEdit} user={state.user} checklistFiles={state.checklistFiles} checklistId={state.checklistId}
                checkReportPeriodVisibilityConstraint={checkReportPeriodVisibilityConstraint}
                handleSaveComment={handleSaveComment} handleDeleteComment={handleDeleteComment} handleEditComment={handleEditComment}
                handleDeleteAttachment={state.handleDeleteAttachment} handleDownloadChecklistFile={state.handleDownloadChecklistFile}
                prePopulateAnswerOptionID={prePopulateAnswerOptionID} showUnansweredQuestions={showUnansweredQuestions} isDisabledTab={state.isDisabledTab} questionComments={state.questionComments} handleDownloadAttachmentClick={handleDownloadAttachmentClick}
            />
        );
    }

    return null;
};

const renderChildren = (item,
    handleItemAnswered,
    isEnabled,
    showAllQuestions,
    isExternal,
    isArqc,
    previousChecklistFound,
    checkReportPeriodVisibilityConstraint,
    handleSaveComment,
    handleDeleteComment,
    handleEditComment,
    prePopulateAnswerOptionID,
    showUnansweredQuestions,
    handleDownloadAttachmentClick) => {
    let nodes = [];
    let tableItems = [];
    let dynamicChildQuestionDisplayOrder = 0; //Initializing dynamic question order to 0

    if (item.children !== undefined && item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].renderAsTable !== undefined && item.children[i].renderAsTable === true) {
                let tableItem = renderChild(item.children[i],
                    item,
                    isEnabled,
                    handleItemAnswered,
                    showAllQuestions,
                    isExternal,
                    isArqc,
                    previousChecklistFound,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID,
                    showUnansweredQuestions,
                    handleDownloadAttachmentClick);

                if (tableItem !== null && (showAllQuestions || isExternal || isUnanswered(item) || (arraysEqual(item.children[i].selectedAnswers, item.children[i].previousAnswers) && item.children[i].selectedAnswers.lengh > 0))) {
                    tableItems.push(tableItem);
                }
            }

            if (tableItems.length > 0 && (item.children[i].renderAsTable === undefined || item.children[i].renderAsTable === false)) {
                nodes.push(
                    <Form.Group key={item.id}>
                        <table>
                            <thead>
                                <tr>
                                    <td>Question</td>
                                    <td>Yes</td>
                                    <td>No</td>
                                    <td>N/A</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableItems.map(item => item)}
                            </tbody>
                        </table>
                        <br />
                    </Form.Group>);
                tableItems = [];
            }

            if (item.children[i].renderAsTable === undefined || item.children[i].renderAsTable === false) {
                nodes.push(renderChild(item.children[i],
                    item,
                    isEnabled,
                    handleItemAnswered,
                    showAllQuestions,
                    isExternal,
                    isArqc,
                    previousChecklistFound,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID,
                    showUnansweredQuestions,
                    handleDownloadAttachmentClick));
            }
        }

        if (item.itemType.toLowerCase() === "psqsection2additionalservices" && nodes.length > 0) {
            let showNodes = false;
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i] !== undefined && nodes[i] !== null) {
                    showNodes = true;
                }
            }

            if (showNodes) {
                return (
                    <tr key={item.id + "children"}>
                        <td colSpan={5}>
                            {nodes.map(i => i)}
                        </td>
                    </tr>);
            } else {
                return null;
            }
        } else if (item.itemType.toLowerCase() === "psqsection2additionalservices" && nodes.length === 0) {
            return null;
        }

        return (
            <div className="ml-3">
                {nodes.map(i => i)}
            </div>
        );
    }

    return null;
};

const getHtml = (title) => {
    return { __html: title };
};

const renderPreviousAnswer = (isExternal, isArqc, previousAnswers, previousChecklistFound, item, checkReportPeriodVisibilityConstraint, handleSaveComment, handleDeleteComment, handleEditComment, prePopulateAnswerOptionID, siblingQuestionIds) =>
    <ChecklistQuestionTabs
        handleSaveComment={handleSaveComment}
        handleDeleteComment={handleDeleteComment}
        handleEditComment={handleEditComment}
        handleDeleteAttachment={state.handleDeleteAttachment}
        checkReportPeriodVisibilityConstraint={checkReportPeriodVisibilityConstraint}
        handleDownloadChecklistFile={state.handleDownloadChecklistFile}
        item={item}
        checklistFiles={state.checklistFiles}
        checklistId={state.checklistId}
        user={state.user}
        externalEmail={state.externalEmail}
        canEdit={state.canEdit}
        previousAnswers={previousAnswers}
        previousChecklistFound={previousChecklistFound}
        isArqc={isArqc}
        isExternal={isExternal}
        siblingQuestionIds={siblingQuestionIds}
        prePopulateAnswerOptionID={prePopulateAnswerOptionID}
        isDisabledTab={state.isDisabledTab}
        questionComments={state.questionComments}
    />;

/* Render Previous Answer for external checklist as well */
const renderJustPreviousAnswer = (isExternal, isArqc, previousAnswers, previousChecklistFound, prePopulateAnswerOptionID) => {
    /* Keep the previous answers tab as blank when prePopulateAnswerOptionID is selected as Questions unaswered (i.e. 2) */
    if (prePopulateAnswerOptionID === 2) {
        return (<div className="checklist-previous-answer"><span className="checklist-previous-answer-heading">Previous Answer:</span></div>);
    }

    /* If previous checklist exists and question is not answered then show blank */
    return (<div className="checklist-previous-answer"><span className="checklist-previous-answer-heading">Previous Answer:</span> {(
        Array.isArray(previousAnswers) && previousAnswers.join(", ")) ||
        (previousAnswers !== undefined && previousAnswers !== null && Array.isArray(previousAnswers) === false && previousAnswers.length > 0 && previousAnswers) ||
        (previousChecklistFound && " ") ||
        (!isArqc && "Prior PSQ for Property Not Available") || (isArqc && "Prior ARQC Not Available")}</div>);
};

const renderArqcStockFmvRow = (answer, item, isEnabled, itemHandler, isExternal, previousChecklistFound, checkReportPeriodVisibilityConstraint, handleSaveComment, handleDeleteComment, handleEditComment, prePopulateAnswerOptionID) => {

    const fmvItemHandler = itemHandler.bind(this, item, null, { "field": "fmv", "individualShareholder": answer.individualShareholder });
    const percentItemHandler = itemHandler.bind(this, item, null, { "field": "percent", "individualShareholder": answer.individualShareholder });
    /* If previous checklist exists and question is not answered then show blank */
    return (
        <tr key={item.id + answer.individualShareholder}>
            <td className="align-top pt-3">
                {answer.individualShareholder}
            </td>
            <td>
                <MotifInput disabled={!isEnabled || !item.isEnabled} value={answer.fmvOfStockOwned !== null ? answer.fmvOfStockOwned : ""} control="input" name={item.id + answer.individualShareholder + "fmvOfStockOwned"} onChange={fmvItemHandler} />
                {previousChecklistFound && item.previousAnswers !== undefined && item.previousAnswers.find(x => x.individualShareholder === answer.individualShareholder) !== undefined ? renderJustPreviousAnswer(isExternal, true, item.previousAnswers.find(x => x.individualShareholder === answer.individualShareholder).fmvOfStockOwned, previousChecklistFound, prePopulateAnswerOptionID) : <i><span className="checklist-previous-answer"><span className="checklist-previous-answer-heading">Previous Answer:</span> </span></i>}
            </td>
            <td>
                <NumericFormat suffix="%" disabled={!isEnabled || !item.isEnabled} value={answer.percentOfStockOwned !== null ? answer.percentOfStockOwned : ""} name={item.id + answer.individualShareholder + "percentOfStockOwned"} onChange={percentItemHandler} maxLength={29} />
                {previousChecklistFound && item.previousAnswers !== undefined && item.previousAnswers.find(x => x.individualShareholder === answer.individualShareholder) !== undefined ? renderJustPreviousAnswer(isExternal, true, item.previousAnswers.find(x => x.individualShareholder === answer.individualShareholder).percentOfStockOwned, previousChecklistFound, prePopulateAnswerOptionID) : <i><span className="checklist-previous-answer"><span className="checklist-previous-answer-heading">Previous Answer:</span> </span></i>}
            </td>
        </tr>
    );
};

const renderArqcAssetTestQuestionChild = (
    item,
    parentItem,
    handleItemAnswered,
    previousChecklistFound,
    isExternal,
    isEnabled,
    checkReportPeriodVisibilityConstraint,
    handleSaveComment,
    handleDeleteComment,
    handleEditComment,
    prePopulateAnswerOptionID
) => {
    const itemHandler = handleItemAnswered.bind(this, item, parentItem);
    const siblingQuestionIds = parentItem.children
        .filter((c) => c.id !== item.id)
        .map((c) => c.id);
    return (
        <td key={item.id}>
            {item.availableAnswers.map((answer) => (
                <MotifRadioButton
                    className="mr-2"
                    disabled={!isEnabled || !item.isEnabled}
                    control="input"
                    type="radio"
                    key={answer}
                    name={item.id + item.title}
                    value={answer}
                    onChange={itemHandler}
                    checked={
                        (item.selectedAnswers.length === 0 &&
                            item.defaultAnswers.find((x) => x === answer) !== undefined) ||
                        item.selectedAnswers.find((x) => x === answer) !== undefined
                    }
                >
                    {answer}
                </MotifRadioButton>
            ))}
            {renderPreviousAnswer(
                isExternal,
                true,
                item.previousAnswers,
                previousChecklistFound,
                item,
                checkReportPeriodVisibilityConstraint,
                handleSaveComment,
                handleDeleteComment,
                handleEditComment,
                prePopulateAnswerOptionID,
                siblingQuestionIds
            )}
        </td>
    );
};

const HelpPopup = (props) => {
    if (!props.item.help || props.item.help == "empty") { return null }

    return (<Popup
        trigger={
                    <MotifIcon iconFunction={IconoirHelpCircle} size='24' 
                     strokeWidth="2"  style={{ cursor: 'pointer', marginRight: '3px', minWidth:'24px' }} />
                }
        content={<div dangerouslySetInnerHTML={getHtml(props.item.help)} />}
        on='click'
        flowing
        position='bottom left'
        className='helpPopup'
    />)
};

const editChecklistItem = ({
    item,
    nextItem,
    parentItem,
    handleItemAnswered,
    isEnabled,
    periodId,
    showAllQuestions,
    isExternal,
    isArqc,
    previousChecklistFound,
    handleRemovePsqSection2Row,
    allowRemovePsqSection2Row,
    tbMode,
    isSectionVisible,
    checklistId,
    canEdit,
    user,
    checklistFiles,
    checkReportPeriodVisibilityConstraint,
    handleSaveComment,
    handleDeleteComment,
    handleEditComment,
    handleDeleteAttachment,
    handleDownloadChecklistFile,
    externalEmail,
    prePopulateAnswerOptionID,
    showUnansweredQuestions,
    isDisabledTab,
    questionComments,
    handleDownloadAttachmentClick
}) => {
    state = {
        canEdit,
        user,
        externalEmail,
        checklistId,
        checklistFiles,
        isArqc,
        handleDeleteAttachment,
        handleDownloadChecklistFile,
        isDisabledTab,
        questionComments
    };

    const itemHandler = handleItemAnswered.bind(this, item, parentItem);
    if (!item.title) {
        return null;
    }

    // Display Changed Answer questions
    if (!showAllQuestions
        && previousAnswersMatch(item, nextItem)
        && !isUnanswered(item)
        && !hasChildrenWithUnmatchedAnswers(item)) {
        return null;
    }

    // Display only Unanswered questions
    if (showUnansweredQuestions && isAnswered(item) && hasChildrenWithAnswers(item)) {
        return null;
    }

    if (item.itemType.toLowerCase() === "menu") {
        const options = item.availableAnswers.map(answer => {
            return { key: answer, text: answer, value: answer };
        });

        let answers = item.selectedAnswers;
        if (!item.isMultiSelect) {
            if (answers !== undefined && answers.length > 0) {
                answers = answers[0];
            } else {
                answers = null;
            }
        }

        return (
            <Form.Group>
                <div dangerouslySetInnerHTML={getHtml(item.title)} />
                {/* <Dropdown
          fluid
          selection
          disabled={!isEnabled || !item.isEnabled}
          options={options}
          onChange={itemHandler}
          defaultValue={answers}
          multiple={item.isMultiSelect}
          name={item.id + item.title}
        /> */}

                <MotifFormField>
                    <MotifSelect
                        data-testid="divEditCheckList"
                        name={item.id + item.title}
                        disabled={!isEnabled || !item.isEnabled}
                        options={options}
                        value={answers}
                        multiple
                        onChange={itemHandler}
                    >
                        {options.map((co) => (
                            <MotifOption
                                className="motif-option-text-space"
                                key={co.value}
                                value={co.value}
                            >
                                {co.text}
                            </MotifOption>
                        ))}
                    </MotifSelect>
                </MotifFormField>

                {renderPreviousAnswer(
                    isExternal,
                    isArqc,
                    item.previousAnswers,
                    previousChecklistFound,
                    item,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID
                )}

                {renderChildren(
                    item,
                    handleItemAnswered,
                    isEnabled,
                    showAllQuestions,
                    isExternal,
                    isArqc,
                    previousChecklistFound,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID,
                    showUnansweredQuestions
                )}
            </Form.Group>
        );
    }

    if (
        item.itemType.toLowerCase() === "button" &&
        item.isMultiSelect === true
    ) {
        if (item.renderAsTable !== undefined && item.renderAsTable === true) {
            return (
                <tr key={item.id}>
                    <td>
                        {item.title}
                        {renderPreviousAnswer(
                            isExternal,
                            isArqc,
                            item.previousAnswers,
                            previousChecklistFound,
                            item,
                            checkReportPeriodVisibilityConstraint,
                            handleSaveComment,
                            handleDeleteComment,
                            handleEditComment,
                            prePopulateAnswerOptionID
                        )}
                    </td>

                    {item.availableAnswers.map((answer, index) => (
                        <td key={answer} className="align-top pt-3">
                            <MotifCheckbox
                                disabled={!isEnabled || !item.isEnabled}
                                name={item.id + item.title}
                                id={item.id + '-' + index}
                                onChange={itemHandler}
                                value={answer}
                                checked={
                                    (item.selectedAnswers.length === 0 &&
                                        item.defaultAnswers.find(
                                            (x) => x === answer
                                        ) !== undefined) ||
                                    item.selectedAnswers.find(
                                        (x) => x === answer
                                    ) !== undefined
                                }>
                                {answer}
                            </MotifCheckbox>
                        </td>
                    ))}
                </tr>
            );
        }

        return (
            <Form.Group>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <HelpPopup item={item} />
                    <div dangerouslySetInnerHTML={getHtml(item.title)} />
                </div>

                {item.availableAnswers.map((answer, index) => (
                    <React.Fragment>
                        <MotifCheckbox
                            className={index === 0 ? "pt-1 pb-1 pl-1 pr-3 checklist-motif-checkboxes" : "pt-0 pb-1 pl-1 pr-3 checklist-motif-checkboxes"}
                            disabled={!isEnabled || !item.isEnabled}
                            key={answer}
                            label={answer}
                            name={item.id + item.title}
                            id={item.id + '-' + index}
                            onChange={itemHandler}
                            value={answer}
                            checked={
                                (item.selectedAnswers.length === 0 &&
                                    item.defaultAnswers.find(
                                        (x) => x === answer
                                    ) !== undefined) ||
                                item.selectedAnswers.find(
                                    (x) => x === answer
                                ) !== undefined
                            }
                        >{answer}</MotifCheckbox>
                    </React.Fragment>
                ))}
                <div>
                    {item.attachments &&
                        item.attachments.length > 0 &&
                        <React.Fragment>
                            <CustomQuestionAttachmentList attachmentList={item.attachments} handleDownloadAttachmentClick={handleDownloadAttachmentClick} className="customQuestionAttachments"></CustomQuestionAttachmentList>
                            <br></br>
                        </React.Fragment>
                    }
                </div>
                {renderPreviousAnswer(
                    isExternal,
                    isArqc,
                    item.previousAnswers,
                    previousChecklistFound,
                    item,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID
                )}

                {renderChildren(
                    item,
                    handleItemAnswered,
                    isEnabled,
                    showAllQuestions,
                    isExternal,
                    isArqc,
                    previousChecklistFound,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID,
                    showUnansweredQuestions,
                    handleDownloadAttachmentClick
                )}
            </Form.Group>
        );
    }

    if (
        item.itemType.toLowerCase() === "button" &&
        item.isMultiSelect === false
    ) {
        if (item.renderAsTable !== undefined && item.renderAsTable === true) {
            return (
                <tr key={item.id}>
                    <td>
                        {item.title}

                        {renderPreviousAnswer(
                            isExternal,
                            isArqc,
                            item.previousAnswers,
                            previousChecklistFound,
                            item,
                            checkReportPeriodVisibilityConstraint,
                            handleSaveComment,
                            handleDeleteComment,
                            prePopulateAnswerOptionID
                        )}
                    </td>
                    {item.availableAnswers.map((answer) => (
                        <td key={answer} className="align-top pt-3">
                            <MotifRadioButton
                                disabled={!isEnabled || !item.isEnabled}
                                name={item.id + item.title}
                                value={answer}
                                onChange={itemHandler}
                                checked={
                                    (item.selectedAnswers.length === 0 &&
                                        item.defaultAnswers.find(
                                            (x) => x === answer
                                        ) !== undefined) ||
                                    item.selectedAnswers.find(
                                        (x) => x === answer
                                    ) !== undefined
                                }
                            >
                                {answer}
                            </MotifRadioButton>
                        </td>
                    ))}

                </tr>
            );
        }

        return (
            <Form.Group>
                <div className="mb-2" style={{ display: "flex", justifyContent: "flex-start" }}>
                    <HelpPopup item={item} />
                    <div dangerouslySetInnerHTML={getHtml(item.title)} />
                </div>
                {item.availableAnswers.map((answer) => (
                    <React.Fragment>
                        <MotifRadioButton
                            className="pr-3"
                            disabled={!isEnabled || !item.isEnabled}
                            name={item.id + item.title}
                            value={answer}
                            onChange={itemHandler}
                            checked={
                                (item.selectedAnswers.length === 0 &&
                                    item.defaultAnswers.find(
                                        (x) => x === answer
                                    ) !== undefined) ||
                                item.selectedAnswers.find(
                                    (x) => x === answer
                                ) !== undefined
                            }
                        >
                            {answer}
                        </MotifRadioButton>
                    </React.Fragment>
                ))}
                <div>
                    {item.attachments &&
                        item.attachments.length > 0 &&
                        <React.Fragment>
                            <CustomQuestionAttachmentList attachmentList={item.attachments} handleDownloadAttachmentClick={handleDownloadAttachmentClick} className="customQuestionAttachments"></CustomQuestionAttachmentList>
                            <br></br>
                        </React.Fragment>
                    }
                </div>
                {renderPreviousAnswer(
                    isExternal,
                    isArqc,
                    item.previousAnswers,
                    previousChecklistFound,
                    item,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID
                )}

                {renderChildren(
                    item,
                    handleItemAnswered,
                    isEnabled,
                    showAllQuestions,
                    isExternal,
                    isArqc,
                    previousChecklistFound,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID,
                    showUnansweredQuestions,
                    handleDownloadAttachmentClick
                )}
            </Form.Group>
        );
    }

    if (item.itemType.toLowerCase() === "plaintext") {
        let defaultValue = "";
        if (
            item.selectedAnswers !== undefined &&
            item.selectedAnswers.length > 0
        ) {
            defaultValue = item.selectedAnswers[0];
        } else if (
            item.defaultAnswers !== undefined &&
            item.defaultAnswers.length > 0
        ) {
            defaultValue = item.defaultAnswers[0];
        }

        return (
            <Form.Group>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <HelpPopup item={item} />
                    <div dangerouslySetInnerHTML={getHtml(item.title)} />
                </div>
                <div>
                    {item.attachments &&
                        item.attachments.length > 0 &&
                        <React.Fragment>
                            <CustomQuestionAttachmentList attachmentList={item.attachments} handleDownloadAttachmentClick={handleDownloadAttachmentClick} className="customQuestionAttachments"></CustomQuestionAttachmentList>
                            <br></br>
                        </React.Fragment>
                    }
                </div>
                <MotifFormField>
                    <MotifInput
                        name={item.id + item.title}
                        disabled={!isEnabled || !item.isEnabled}
                        onChange={itemHandler}
                        value={defaultValue}
                        maxLength={4000}
                        labelPosition="in"
                    />
                </MotifFormField>

                {renderPreviousAnswer(
                    isExternal,
                    isArqc,
                    item.previousAnswers,
                    previousChecklistFound,
                    item,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID
                )}

                {renderChildren(
                    item,
                    handleItemAnswered,
                    isEnabled,
                    showAllQuestions,
                    isExternal,
                    isArqc,
                    previousChecklistFound,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID,
                    showUnansweredQuestions,
                    handleDownloadAttachmentClick
                )}
            </Form.Group>
        );
    }

    if (item.itemType.toLowerCase() === "fileupload") {
        return (
            <Form.Group>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <HelpPopup item={item} />
                    <div className="font-weight-bold" dangerouslySetInnerHTML={getHtml(item.title)} />
                </div>
                <ChecklistQuestionTabs
                    handleSaveComment={handleSaveComment}
                    handleDeleteComment={handleDeleteComment}
                    handleEditComment={handleEditComment}
                    handleDeleteAttachment={state.handleDeleteAttachment}
                    checkReportPeriodVisibilityConstraint={checkReportPeriodVisibilityConstraint}
                    handleDownloadChecklistFile={state.handleDownloadChecklistFile}
                    item={item}
                    checklistFiles={state.checklistFiles}
                    checklistId={state.checklistId}
                    user={state.user}
                    externalEmail={state.externalEmail}
                    canEdit={state.canEdit}
                    previousAnswers={item.previousAnswers}
                    previousChecklistFound={previousChecklistFound}
                    isArqc={isArqc}
                    isExternal={true} // This is to enable the ability to collapse tabs
                    hidePreviousAnswers={true}
                    autoOpenFileTab={true}
                    prePopulateAnswerOptionID={prePopulateAnswerOptionID}
                    isDisabledTab={state.isDisabledTab}
                    questionComments={state.questionComments}
                />
            </Form.Group>
        );
    }

    if (item.itemType.toLowerCase() === "arqcexhibitbmortgagetable") {
        return (
            <Form.Group>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <HelpPopup item={item} />
                    <div dangerouslySetInnerHTML={getHtml(item.title)} />
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Fees</th>
                            <th>Y</th>
                            <th>N</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Commitment Fees</td>
                            <td>
                                <MotifRadioButton
                                    className="mr-2"
                                    disabled={!isEnabled || !item.isEnabled}
                                    type="radio"
                                    control="input"
                                    key="0Yes"
                                    name={item.id + item.title + "0"}
                                    onChange={itemHandler}
                                    value="YES"
                                    checked={
                                        item.selectedAnswers.length !== 0 &&
                                        item.selectedAnswers[0] === "YES"
                                    }
                                >
                                    YES
                                </MotifRadioButton>
                            </td>
                            <td>
                                <MotifRadioButton
                                    className="mr-2"
                                    disabled={!isEnabled || !item.isEnabled}
                                    type="radio"
                                    control="input"
                                    key="0No"
                                    name={item.id + item.title + "0"}
                                    onChange={itemHandler}
                                    value="NO"
                                    checked={
                                        item.selectedAnswers.length !== 0 &&
                                        item.selectedAnswers[0] === "NO"
                                    }
                                >
                                    NO
                                </MotifRadioButton>
                            </td>
                        </tr>
                        <tr>
                            <td>Servicing Rights</td>
                            <td>
                                <MotifRadioButton
                                    className="mr-2"
                                    disabled={!isEnabled || !item.isEnabled}
                                    type="radio"
                                    control="input"
                                    key="1Yes"
                                    name={item.id + item.title + "1"}
                                    onChange={itemHandler}
                                    value="YES"
                                    checked={
                                        item.selectedAnswers.length !== 0 &&
                                        item.selectedAnswers[1] === "YES"
                                    }
                                >
                                    YES
                                </MotifRadioButton>
                            </td>
                            <td>
                                <MotifRadioButton
                                    className="mr-2"
                                    disabled={!isEnabled || !item.isEnabled}
                                    type="radio"
                                    control="input"
                                    key="1No"
                                    name={item.id + item.title + "1"}
                                    onChange={itemHandler}
                                    value="NO"
                                    checked={
                                        item.selectedAnswers.length !== 0 &&
                                        item.selectedAnswers[1] === "NO"
                                    }
                                >
                                    NO
                                </MotifRadioButton>
                            </td>
                        </tr>
                        <tr>
                            <td>Guarantee Fees</td>
                            <td>
                                <MotifRadioButton
                                    className="mr-2"
                                    disabled={!isEnabled || !item.isEnabled}
                                    type="radio"
                                    control="input"
                                    key="2Yes"
                                    name={item.id + item.title + "2"}
                                    onChange={itemHandler}
                                    value="YES"
                                    checked={
                                        item.selectedAnswers.length !== 0 &&
                                        item.selectedAnswers[2] === "YES"
                                    }
                                >
                                    YES
                                </MotifRadioButton>
                            </td>
                            <td>
                                <MotifRadioButton
                                    className="mr-2"
                                    disabled={!isEnabled || !item.isEnabled}
                                    type="radio"
                                    control="input"
                                    key="2No"
                                    name={item.id + item.title + "2"}
                                    onChange={itemHandler}
                                    value="NO"
                                    checked={
                                        item.selectedAnswers.length !== 0 &&
                                        item.selectedAnswers[2] === "NO"
                                    }
                                >
                                    NO
                                </MotifRadioButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {renderPreviousAnswer(
                    isExternal,
                    isArqc,
                    item.previousAnswers,
                    previousChecklistFound,
                    item,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID
                )}
            </Form.Group>
        );
    }


    if (item.itemType.toLowerCase() === "arqcstockfmvtable") {

        let sumFmvOfStockOwned = 0.00;
        let sumPercentOfStockOwned = 0.000;
        let value = 0;

        for (let i = 0; i < item.selectedAnswers.length; i++) {

            if (item.selectedAnswers[i].fmvOfStockOwned !== undefined && item.selectedAnswers[i].fmvOfStockOwned !== null) {

                value = item.selectedAnswers[i].fmvOfStockOwned.replace("$", "").replace(",", "");
                while (value.indexOf(",") > 0) {
                    value = value.replace("$", "").replace(",", "");
                }
                if(isValidNumber(value)){
                    sumFmvOfStockOwned += Number(value);
                }
            }

            if (item.selectedAnswers[i].percentOfStockOwned !== undefined && item.selectedAnswers[i].percentOfStockOwned !== null) {
                value = item.selectedAnswers[i].percentOfStockOwned.replace("%", "");
                if(isValidNumber(value)){
                sumPercentOfStockOwned += Number(value);
                }
            }
        }

        return (
            <div>
                <Form.Group>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <HelpPopup item={item} />
                        <div dangerouslySetInnerHTML={getHtml(item.title)} />
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Indivivdual Shareholder</th>
                                <th>FMV of Stock Owned</th>
                                <th>% of Stock Owned</th>
                            </tr>
                        </thead>
                        <tbody>
                            {item.selectedAnswers.length > 0
                                ? item.selectedAnswers.map((a) =>
                                    renderArqcStockFmvRow(
                                        a,
                                        item,
                                        isEnabled,
                                        handleItemAnswered,
                                        isExternal,
                                        previousChecklistFound,
                                        checkReportPeriodVisibilityConstraint,
                                        handleSaveComment,
                                        handleDeleteComment,
                                        handleEditComment,
                                        prePopulateAnswerOptionID
                                    )
                                )
                                : item.defaultAnswers.map((a) =>
                                    renderArqcStockFmvRow(
                                        a,
                                        item,
                                        isEnabled,
                                        handleItemAnswered,
                                        isExternal,
                                        previousChecklistFound,
                                        checkReportPeriodVisibilityConstraint,
                                        handleSaveComment,
                                        handleDeleteComment,
                                        handleEditComment,
                                        prePopulateAnswerOptionID
                                    )
                                )}
                            <tr>
                                <td>Total</td>
                                <td className="tright">
                                    <FormattedCurrency
                                        value={sumFmvOfStockOwned}
                                        currency="USD"
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                    />
                                </td>
                                <td className="tright">
                                    <FormattedNumber
                                        value={sumPercentOfStockOwned / 100 || 0}
                                        style="percent"
                                        minimumFractionDigits={3}
                                        maximumFractionDigits={3}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="mt-3">
                        <ChecklistQuestionTabs
                            handleSaveComment={handleSaveComment}
                            handleDeleteComment={handleDeleteComment}
                            handleEditComment={handleEditComment}
                            handleDeleteAttachment={state.handleDeleteAttachment}
                            checkReportPeriodVisibilityConstraint={checkReportPeriodVisibilityConstraint}
                            handleDownloadChecklistFile={state.handleDownloadChecklistFile}
                            item={item}
                            checklistFiles={state.checklistFiles}
                            checklistId={state.checklistId}
                            user={state.user}
                            externalEmail={state.externalEmail}
                            canEdit={state.canEdit}
                            previousAnswers={item.previousAnswers}
                            previousChecklistFound={previousChecklistFound}
                            isArqc={isArqc}
                            isExternal={isExternal}
                            hidePreviousAnswers={true}
                            prePopulateAnswerOptionID={prePopulateAnswerOptionID}
                            isDisabledTab={state.isDisabledTab}
                            questionComments={state.questionComments}
                        />
                    </div>
                </Form.Group>
            </div>
        );
    }

    if (item.itemType.toLowerCase() === "psqsection2propertymanager") {
        let defaultPropManager = "";
        if (item.selectedAnswers !== undefined && item.selectedAnswers.length > 0) {
            defaultPropManager = item.selectedAnswers[0];
        } else if (item.defaultAnswers !== undefined && item.defaultAnswers.length > 0) {
            defaultPropManager = item.defaultAnswers[0];
        } else if (item.selectedAnswers !== undefined && item.selectedAnswers.length === 0) {
            item.selectedAnswers.push("");  // Set the Property Manager textbox with empty string, when there is no answer for question
        }

        return (
            <div>
                <Form.Group>
                    <div dangerouslySetInnerHTML={getHtml(item.title)} />
                    <table>
                        <thead>
                            <tr>
                                <th>Property Manager</th>
                                <th>E</th>
                                <th>IK</th>
                                <th>TRS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                <MotifFormField>
                                    <MotifInput
                                        disabled={!isEnabled || !item.isEnabled}  
                                        control="input"
                                        key={"propManager" + item.id}
                                        name={item.id + "propManager"}
                                        onChange={itemHandler}
                                        hideClearButton
                                        value={defaultPropManager}
                                        defaultValue={defaultPropManager}
                                        maxLength="4000"
                                        labelPosition="in"
                                    />
                                </MotifFormField>
                                    {renderPreviousAnswer(
                                        isExternal,
                                        isArqc,
                                        item.previousAnswers,
                                        previousChecklistFound,
                                        item,
                                        checkReportPeriodVisibilityConstraint,
                                        handleSaveComment,
                                        handleDeleteComment,
                                        handleEditComment,
                                        prePopulateAnswerOptionID
                                    )}
                                </td>
                                <td className="align-top pt-3">
                                    <MotifFormField>
                                        <MotifCheckbox
                                            disabled={!isEnabled || !item.isEnabled}
                                            type="checkbox"
                                            key={"E" + item.id}
                                            name={item.id + "E"}
                                            onChange={itemHandler}
                                            value="E"
                                            checked={item.selectedAnswers.find(x => x === "E") !== undefined}>
                                        </MotifCheckbox>
                                    </MotifFormField>
                                </td>
                                <td className="align-top pt-3">
                                    <MotifFormField>
                                        <MotifCheckbox
                                            disabled={!isEnabled || !item.isEnabled}
                                            type="checkbox"
                                            key={"IK" + item.id}
                                            name={item.id + "IK"}
                                            onChange={itemHandler}
                                            value="IK"
                                            id={"IK" + item.id}
                                            checked={item.selectedAnswers.find(x => x === "IK") !== undefined}>
                                        </MotifCheckbox>
                                    </MotifFormField>
                                </td>
                                <td className="align-top pt-3">
                                    <MotifFormField>
                                        <MotifCheckbox
                                            disabled={!isEnabled || !item.isEnabled}
                                            type="checkbox"
                                            key={"TRS" + item.id}
                                            name={item.id + "TRS"}
                                            onChange={itemHandler}
                                            value="TRS"
                                            id={"TRS" + item.id}
                                            checked={item.selectedAnswers.find(x => x === "TRS") !== undefined}>
                                        </MotifCheckbox>
                                    </MotifFormField>
                                </td>
                            </tr>
                            {renderChildren(
                                item,
                                handleItemAnswered,
                                isEnabled,
                                showAllQuestions,
                                isExternal,
                                isArqc,
                                previousChecklistFound,
                                checkReportPeriodVisibilityConstraint,
                                handleSaveComment,
                                handleDeleteComment,
                                handleEditComment,
                                prePopulateAnswerOptionID,
                                showUnansweredQuestions
                            )}
                        </tbody>
                    </table>
                </Form.Group>
            </div>
        );
    }

    if (item.itemType.toLowerCase() === "psqsection2additionalservices") {
        const removePsqSection2Handler = handleRemovePsqSection2Row.bind(
            this,
            item
        );

        let defaultServiceProvider = "";
        if (item.selectedAnswers !== undefined && item.selectedAnswers.length > 0) {
            defaultServiceProvider = item.selectedAnswers[0];
        } else if (
            item.defaultAnswers !== undefined &&
            item.defaultAnswers.length > 0
        ) {
            defaultServiceProvider = item.defaultAnswers[0];
        } else if (
            item.selectedAnswers !== undefined &&
            item.selectedAnswers.length === 0
        ) {
            item.selectedAnswers.push(""); // Set the Service Provider textbox with empty string, when there is no answer for question
        }

        return (
            <tbody>
                <tr>
                    <td>
                        <MotifFormField>
                        <MotifInput
                            disabled={!isEnabled || !item.isEnabled}
                            control="input"
                            key={"serviceProvider" + item.id}
                            name={item.id + "serviceProvider"}
                            onChange={itemHandler}
                            value={defaultServiceProvider}
                            defaultValue={defaultServiceProvider}
                            maxLength="4000"
                            hideClearButton
                            labelPosition="in"
                        />
                        </MotifFormField>
                        {renderPreviousAnswer(
                            isExternal,
                            isArqc,
                            item.previousAnswers,
                            previousChecklistFound,
                            item,
                            checkReportPeriodVisibilityConstraint,
                            handleSaveComment,
                            handleDeleteComment,
                            handleEditComment,
                            prePopulateAnswerOptionID
                        )}
                    </td>
                    <td className="align-top pt-3">
                        <MotifFormField>
                            <MotifCheckbox
                                disabled={!isEnabled || !item.isEnabled}
                                type="checkbox"
                                key={"E" + item.id}
                                name={item.id + "E"}
                                onChange={itemHandler}
                                value="E"
                                id={"E" + item.id}
                                checked={item.selectedAnswers.find(x => x === "E") !== undefined}
                            >
                            </MotifCheckbox>
                        </MotifFormField>
                    </td>
                    <td className="align-top pt-3">
                        <MotifFormField>
                            <MotifCheckbox
                                disabled={!isEnabled || !item.isEnabled}
                                type="checkbox"
                                key={"IK" + item.id}
                                name={item.id + "IK"}
                                onChange={itemHandler}
                                value="IK"
                                id={"IK" + item.id}
                                checked={item.selectedAnswers.find(x => x === "IK") !== undefined}>
                            </MotifCheckbox>
                        </MotifFormField>
                    </td>
                    <td className="align-top pt-3">
                        <MotifFormField>
                            <MotifCheckbox
                                disabled={!isEnabled || !item.isEnabled}
                                type="checkbox"
                                key={"TRS" + item.id}
                                name={item.id + "TRS"}
                                onChange={itemHandler}
                                value="TRS"
                                id={"TRS" + item.id}
                                checked={item.selectedAnswers.find(x => x === "TRS") !== undefined}>
                            </MotifCheckbox>
                        </MotifFormField>
                    </td>
                    <td className="align-top pt-3">
                        {(isEnabled && allowRemovePsqSection2Row && (
                            <MotifIconButton
                                aria-label="Delete this Service Provider"
                                type="button"
                                onClick={removePsqSection2Handler}
                            >
                                <MotifIcon
                                    iconFunction={IconoirTrash} size='24' 
                                    title="Delete this Service Provider"
                                />
                            </MotifIconButton>
                        )) ||
                            null}
                    </td>
                </tr>
                {renderChildren(
                    item,
                    handleItemAnswered,
                    isEnabled,
                    showAllQuestions,
                    isExternal,
                    isArqc,
                    previousChecklistFound,
                    checkReportPeriodVisibilityConstraint,
                    handleSaveComment,
                    handleDeleteComment,
                    handleEditComment,
                    prePopulateAnswerOptionID,
                    showUnansweredQuestions
                )}
            </tbody>
        );
    }

    if (item.itemType.toLowerCase() === "arqcassettestquestion") {
        let hasPreviousMatchChild = false;
        for (let i = 0; i < item.children.length; i++) {
            if (!previousAnswersMatch(item.children[i])) {
                hasPreviousMatchChild = true;
            }
        }

        if (!showAllQuestions && !isExternal && !hasPreviousMatchChild) {
            return null;
        }

        return (
            <div>
                <Form.Group>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <HelpPopup item={item} />
                        <div dangerouslySetInnerHTML={getHtml(item.title)} />
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                {item.children.map((a) => (
                                    <th style={{ width: "25%" }} key={a.id}>
                                        {a.title}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {item.children.map((a) =>
                                    renderArqcAssetTestQuestionChild(
                                        a,
                                        item,
                                        handleItemAnswered,
                                        previousChecklistFound,
                                        isExternal,
                                        isEnabled,
                                        checkReportPeriodVisibilityConstraint,
                                        handleSaveComment,
                                        handleDeleteComment,
                                        handleEditComment,
                                        prePopulateAnswerOptionID
                                    )
                                )}
                            </tr>
                        </tbody>
                    </table>
                </Form.Group>
            </div>
        );
    }

    if (item.itemType === "assetdetailexhibit") {
        return (
            <ChecklistAssetDetailsContainer
                isSectionVisible={isSectionVisible}
                periodId={periodId}
                isExternal={isExternal}
                checklistId={checklistId}
                isArqc={isArqc}
            />
        );
    }

    if (item.itemType === "incomedetailexhibit") {
        return (
            <ChecklistIncomeDetailsContainer
                isSectionVisible={isSectionVisible}
                periodId={periodId}
                isExternal={isExternal}
                checklistId={checklistId}
                isArqc={isArqc}
            />
        );
    }

    if (item.itemType === "distributiondetailexhibit") {
        return (
            <ChecklistDistributionDetailsContainer
                isSectionVisible={isSectionVisible}
                periodId={periodId}
                isExternal={isExternal}
                checklistId={checklistId}
                isArqc={isArqc}
            />
        );
    }

    if (isInstructionType(item)) {
        return (
            <div className="row">
                <div className="col m-3">
                    <div dangerouslySetInnerHTML={getHtml(item.title)} />
                    <div dangerouslySetInnerHTML={getHtml(item.description)} />
                    {renderChildren(item, handleItemAnswered, isEnabled, showAllQuestions, isExternal, isArqc, previousChecklistFound, checkReportPeriodVisibilityConstraint, handleSaveComment, handleDeleteComment, handleEditComment, prePopulateAnswerOptionID, showUnansweredQuestions)}
                </div>
            </div>
        );
    }

    if (item.itemType === "headerInfo") {
        const marginBottom =
            nextItem && nextItem.itemType !== "headerInfo" ? " mb-3" : "";
        return (
            <div className={`row${marginBottom}`}>
                <div className="col">
                    <strong className="mr-1">{`${item.title}:`}</strong>{" "}
                    {item.selectedAnswers[0]}
                </div>
            </div>
        );
    }

    return null;
};

editChecklistItem.propTypes = {
    item: PropTypes.object.isRequired,
    parentItem: PropTypes.object,
    handleItemAnswered: PropTypes.func,
    periodId: PropTypes.number,
    isEnabled: PropTypes.bool,
    incomeDetails: PropTypes.array,
    showAllQuestions: PropTypes.bool,
    isExternal: PropTypes.bool,
    previousChecklistFound: PropTypes.array,
    handleRemovePsqSection2Row: PropTypes.func,
    allowRemovePsqSection2Row: PropTypes.bool,
    tbMode: PropTypes.number,
    properties: PropTypes.array,
    nextItem: PropTypes.object,
    propertyDetails: PropTypes.object,
    propertyItsi: PropTypes.object,
    distributionTestSummary: PropTypes.object,
    distributionTaxableIncome: PropTypes.object,
    isArqc: PropTypes.bool,
    isSectionVisible: PropTypes.bool,
    checklistId: PropTypes.string,
    prePopulateAnswerOptionID: PropTypes.number,
    user: PropTypes.string,
    showUnansweredQuestions: PropTypes.bool,
    handleDownloadAttachmentClick: PropTypes.func,
};

export default editChecklistItem;

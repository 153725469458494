import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from 'ag-grid-react';
import ClientsExternalCellRenderer from "./customRenderer/ClientsExternalCellRenderer";
import { handleFilterChanged, handleFirstDataRendered, handleGridReady, tableHeaderClass, handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import CustomPagination from '../../common/MotifPagination';
import CustomHeaderTemplate from "../shared/customHeaders/CustomHeaderTemplate.js";
import { MotifTable } from "@ey-xd/motif-react";

const ClientListSlim = ({ clients, clientsAreLoaded }) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    const getColumnDefs = () => {
        return [
            {
                field: 'name', headerName: 'Clients', sortable: true, showRowGroup: false, rowGroup: false,
                cellRenderer: ClientsExternalCellRenderer,
                headerComponentParams: {template: CustomHeaderTemplate("name"),},
                comparator: (a, b) => {
                    var valA = a.toString().toLowerCase();
                    var valB = b.toString().toLowerCase();
                    if (valA === valB) return 0;
                    return valA > valB ? 1 : -1;
                },
            }
        ];
    };

    //Handle deselect all event
    const handleDeselectAll = (val) => {
        // set current page and total pages to 0
        setCurrentPage(0);    
        setTotalPages(0);      
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    const handleGridChanges = (gridRefApi) => {
        if (gridRefApi) {
            gridRefApi.refreshCells();
        }
    }


    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    useEffect(() => {
        handleGridChanges(gridRef && gridRef.current ? gridRef.current.api : null);
        if (rowData.length > 0 && gridRef.current.api !== undefined) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    }, [rowData]);

    useEffect(() => {
        setRowData(clients);
        if (gridRef && gridRef.current && gridRef.current.api) {
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }
    }, [clients]);

    const rowHeight = 76; 
    const headerHeight = 45; 
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

    return (
        <React.Fragment>
            <h2>Clients</h2>
            <div>
                {(rowData.length > 0) &&
                    <div>
                        <div className="row">
                            <div className="col">
                                <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                                    <MotifTable zebra={true} compact={true}
                                        rowData={rowData}
                                        columnDefs={getColumnDefs()}
                                        defaultColDef={defaultColDef}
                                        accentedSort={true}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                        onGridReady={event => handleGridReady(event, gridRef, setCurrentPage, setItemsPerPage, setTotalPages, 'REITSuiteExternalClientListColumnState', handleDeselectAll)}
                                        onFirstDataRendered={handleFirstDataRendered(gridRef)}
                                        ref={gridRef}
                                        pagination={true}
                                        suppressPaginationPanel={true}
                                        paginationPageSize={100}
                                        groupDisplayType={'custom'}
                                        groupSelectsChildren={true}
                                        groupDefaultExpanded={0}
                                        onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                                        data-testid="divClientListGrid"
                                        deltaRowDataMode={true}
                                        immutableData={true}
                                        getRowNodeId={(data) => data.id}
                                    />
                                    <div className="mt-3">
                                        <span className="right mb-3">
                                            <CustomPagination
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                                totalPages={totalPages}
                                                handleItemsPerPageChange={handleItemsPerPageChange}
                                                gridRef={gridRef}
                                                setCurrentPage={setCurrentPage}
                                                setItemsPerPage={setItemsPerPage}
                                                setTotalPages={setTotalPages} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(clients && clients.length === 0 && clientsAreLoaded && <div><h5>It looks as though you aren't assigned to any clients right now - please reach out to your EY contact.</h5></div>)
                            || null}
            </div>
        </React.Fragment>
    );
};

ClientListSlim.propTypes = {
    clients: PropTypes.array.isRequired,
    clientsAreLoaded: PropTypes.bool,
};

export default ClientListSlim;
import React from 'react';
import { MotifIcon, MotifIconButton } from '@ey-xd/motif-react';
import { IconoirTrash } from '@ey-xd/motif-icon';

export default (props) => {
  
  if (props.fieldName == 'editIcon' && props.currentUserId.toLowerCase() == props.node.data.userId.toLowerCase()) {
    return null;
  }
  if (props.fieldName == 'editIcon') {
    return (
      <div>
        <div className="justify-content-end align-items-end mt-2">
          <MotifIconButton aria-label="Delete" type="button"
            data-testId="deleteIcon">
            <MotifIcon iconFunction={IconoirTrash} size='24'  title="Delete" onClick={() => props.handleDeleteButtonClick(props.node.data)} />
          </MotifIconButton>
        </div>
      </div>
    );
  }
}

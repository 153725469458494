import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import {
  handleFilterChanged,
  handleFirstDataRendered,
  handleGridReady,
  tableHeaderClass,
  handleItemsPerPageChange,
} from "../../common/AgGridEventHandlers";
import CustomHeaderTemplate from "../shared/customHeaders/CustomHeaderTemplate";
import DistributionDetailCellRenderer from "../../components/shared/customRenderers/DistributionDetailCellRenderer";
import CustomPagination from "../../common/MotifPagination";
import DateComparator from "../checklistDeliveryCalendar/customComparators/DateComparator";
import { MotifTable } from "@ey-xd/motif-react";
import * as formatters from "../../scripts/formFormatters";
const DistributionDetailTable = ({
  distributionDetails,
  showForm,
  handleEditButtonClick,
  handleDeleteButtonClick,
  canEdit,
  isChecklist,
}) => {
  if (!isChecklist) {
    isChecklist = false;
  }
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);

  //Default column settings
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 65,
      filter: true,
      resizable: true,
      sortable: true,
      wrapText: true,
      autoHeight: true,
    };
  }, []);

  const FormattedCurrency = ({ value }) => {
    return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(value);
  };

  const getColumnDefs = () => {
    return [
      {
        field: "class",
        headerName: "Class",
        sortable: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 250,
        headerComponentParams: {
          template: CustomHeaderTemplate("class"),
        },
      },
      {
        field: "distributionDate",
        headerName: "Date",
        sortable: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 200,
        headerComponentParams: {
          template: CustomHeaderTemplate("distributionDate"),
        },
        valueGetter: (params) => {
          const dateValue = params.data && params.data.distributionDate ? params.data.distributionDate : null;
          return formatters.formatDate(dateValue);
        },
        comparator: DateComparator,
      },
      {
        field: "fairMarketValue",
        headerName: "Fair Market Value",
        sortable: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 208,
        headerComponentParams: {
          template: CustomHeaderTemplate("fairMarketValue"),
        },
        valueGetter: (params) => {
          return FormattedCurrency({ value: params.data.fairMarketValue });
        }
      },
      {
        field: "value",
        headerName: "Value",
        sortable: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 208,
        headerComponentParams: {
          template: CustomHeaderTemplate("value"),
        },
        valueGetter: (params) => {
          return FormattedCurrency({ value: params.data.value });
        }
      },

      {
        field: "",
        headerName: "",
        suppressMenu: true,
        suppressHeaderFilterButton: true,
        showRowGroup: false,
        rowGroup: false,
        minWidth: 125,
        maxWidth: 125,
        cellRenderer: DistributionDetailCellRenderer,
        cellRendererParams: {
          distributionDetails,
          showForm,
          handleEditButtonClick,
          handleDeleteButtonClick,
          canEdit,
          isChecklist,
        },
        headerComponentParams: {
          template: CustomHeaderTemplate("TemplateAction"),
        },
      },
    ];
  };

  //Handle deselect all event
  const handleDeselectAll = (val) => {
    // set current page and total pages to 0
    setCurrentPage(0);    
    setTotalPages(0);      
  };

  //Handle Grid Page change event
  const handlePageChange = (val) => {
    setCurrentPage(val);
    gridRef.current.api.paginationGoToPage(val - 1);
  };

  const [columnDefs, setColumnDefs] = useState(getColumnDefs());

  const handleGridChanges = (gridRefApi) => {
    if (gridRefApi) {
      gridRefApi.refreshCells();
    }
  }

  useEffect(() => {
    handleGridChanges(
      gridRef && gridRef.current ? gridRef.current.api : null
    );
    if (rowData.length > 0 && gridRef.current.api !== undefined) {
      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);
      //If total page is zero then set the current page as zero
      if (calcTotalPages == 0) {
        setCurrentPage(calcTotalPages);
      }
    }
  }, [rowData]);

  useEffect(() => {
    setRowData(distributionDetails);
    if (gridRef && gridRef.current && gridRef.current.api) {
      // Get total pages from Grid's default pagination control and apply it to custom pagination control
      let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
      setTotalPages(calcTotalPages);
    }
  }, [distributionDetails]);

  useEffect(() => {
    if (gridRef.current) {
      setColumnDefs(getColumnDefs());
    }
  }, [columnDefs]);

  const rowHeight = 76; 
  const headerHeight = 45; 
  const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default


  return (
    <div>
      {rowData.length > 0 && (
        <div>
          <div className="row mb-3">
            <div className="col  mt-3">
            <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                <MotifTable zebra={true} compact={true}
                  rowData={rowData}
                  columnDefs={getColumnDefs()}
                  defaultColDef={defaultColDef}
                  accentedSort={true}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  onGridReady={(event) =>
                    handleGridReady(
                      event,
                      gridRef,
                      setCurrentPage,
                      setItemsPerPage,
                      setTotalPages,
                      "REITSuiteDistributionDetailColumnState",
                      handleDeselectAll
                    )
                  }
                  onFirstDataRendered={handleFirstDataRendered(gridRef)}
                  ref={gridRef}
                  pagination={true}
                  suppressPaginationPanel={true}
                  paginationPageSize={100}
                  groupDisplayType={"custom"}
                  groupSelectsChildren={true}
                  groupDefaultExpanded={0}
                  onFilterChanged={handleFilterChanged(
                    gridRef,
                    currentPage,
                    setCurrentPage,
                    setTotalPages
                  )}
                  data-testid="divDistributionDetailGrid"
                  deltaRowDataMode={true}
                  immutableData={true}
                  getRowNodeId={(data) => data.distributionID}
                />
                <div className="mt-3">
                  <span className="right mb-3">
                    <CustomPagination
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      totalPages={totalPages}
                      handleItemsPerPageChange={handleItemsPerPageChange}
                      gridRef={gridRef}
                      setCurrentPage={setCurrentPage}
                      setItemsPerPage={setItemsPerPage}
                      setTotalPages={setTotalPages}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {(distributionDetails && distributionDetails.length === 0 && (
        <div>No distribution details found for this report period.</div>
      )) ||
        null}
    </div>
  );
};

DistributionDetailTable.propTypes = {
  distributionDetails: PropTypes.array.isRequired,
  showForm: PropTypes.bool,
  handleEditButtonClick: PropTypes.func,
  handleDeleteButtonClick: PropTypes.func,
  canEdit: PropTypes.bool,
  isChecklist: PropTypes.bool,
};

export default DistributionDetailTable;

import React from 'react';
import { MotifIcon, MotifIconButton } from '@ey-xd/motif-react';
import { IconoirLock, IconoirLockSlash, IconoirEdit, IconoirEditPencil, IconoirTrash } from '@ey-xd/motif-icon';

export default (props) => {
    const isLocked = props.data ? props.data.isLocked : false;
    const checklistTemplateId = props.data ? props.data.checklistTemplateID : -1;
    const checklistTemplateName = props.data ? props.data.checklistTemplateName : '';
    const lockedBy = props.data ? props.data.lockedBy : '';
    const isEditMode = props.data ? props.data.isEditMode : false;
    const beingEditedBy = props.data ? props.data.beingEditedBy : '';
    const beingEditedByValidationMessage = `This checklist template is currently being edited by ${beingEditedBy ? beingEditedBy.toLowerCase() : ''}`;
    const isSystemAdministrator = props.currentUserIsSysAdmin ? props.currentUserIsSysAdmin : false;
    const isEngagementAdmin = props.currentUserIsEngAdmin ? props.currentUserIsEngAdmin : false;
    const isEngagementUser = props.currentUserIsEngagementUser ? props.currentUserIsEngagementUser : false;
    const currentUser = props.currentUser ? props.currentUser : '';
    const lockedByValidationMessage = `Locked by ${lockedBy ? lockedBy.toLowerCase() : ''}`;
    
    let enableActionForLockedAndEditModeTemplate = false;
    //If checklist template is locked and also in edit mode
    if (isLocked && isEditMode) {
        // If current user is system administrator
        if (isSystemAdministrator) {
            enableActionForLockedAndEditModeTemplate = true;
        }
        // If current user is edited that template and locked that template
        else if (currentUser.toUpperCase() == beingEditedBy.toUpperCase() && currentUser.toUpperCase() == lockedBy.toUpperCase()) {
            enableActionForLockedAndEditModeTemplate = true;
        }
    }

    return (
        <div>
        { (isSystemAdministrator || isEngagementAdmin || isEngagementUser) &&
        <div>
            {!isLocked ?
                // For Unlocked Custom Templates
                isEditMode ?
                    // Checklist template is edited(UnLocked)
                    (
                        <div className="justify-content-center align-items-center mt-2">
                            <MotifIconButton
                                aria-label="UnLock"
                                type="button"
                                data-testid="UnLock"
                                // For Edit Mode Checklist Templates, disable Lock button if template is edited by other user
                                disabled={!isSystemAdministrator && currentUser.toUpperCase() !== beingEditedBy.toUpperCase()}
                                onClick={() => props.handleLock(checklistTemplateId, checklistTemplateName, true)}>
                                <MotifIcon iconFunction={IconoirLockSlash} size='24'   title={beingEditedByValidationMessage} />
                            </MotifIconButton>
                            <MotifIconButton
                                aria-label="ChangeHistory"
                                type="button"
                                data-testid="changeHistory"
                                disabled={!isSystemAdministrator && currentUser.toUpperCase() !== beingEditedBy.toUpperCase()}
                                onClick={() => props.handleEdit(checklistTemplateId, checklistTemplateName)} >
                                <MotifIcon iconFunction={IconoirEdit} size='24'  title={beingEditedByValidationMessage} />
                            </MotifIconButton>
                            {props.allowDeleteAction && <MotifIconButton aria-label="Delete"
                                type="button"
                                data-testid="delete"
                                disabled={true}>
                                <MotifIcon iconFunction={IconoirTrash} size='24'  title={beingEditedByValidationMessage} />
                            </MotifIconButton>}
                        </div>
                    )
                    :
                    //  Checklist template is not edited(UnLocked)
                    (
                        <div className="justify-content-center align-items-center mt-2">
                            <MotifIconButton
                                aria-label="UnLock"
                                type="button"
                                data-testid="UnLock"
                                onClick={() => props.handleLock(checklistTemplateId, checklistTemplateName, true)}>
                                <MotifIcon iconFunction={IconoirLockSlash} size='24'  title="Lock" />
                            </MotifIconButton>
                            <MotifIconButton
                                aria-label="Edit"
                                type="button"
                                data-testid="edit"
                                onClick={() => props.handleEdit(checklistTemplateId, checklistTemplateName)}>
                                <MotifIcon iconFunction={IconoirEditPencil} size='24'  title="Edit" />
                            </MotifIconButton>
                            {props.allowDeleteAction && <MotifIconButton aria-label="Delete"
                                type="button"
                                data-testid="delete"
                                onClick={() => props.handleDelete(checklistTemplateId, checklistTemplateName)}>
                                <MotifIcon iconFunction={IconoirTrash} size='24'  title="Delete" />
                            </MotifIconButton>}
                        </div>
                    )
                :
                // For Locked Custom Templates
                checklistTemplateId > 0
                    ?
                    // Checklist template is edited(Locked)
                    isEditMode ?
                        (
                            <div className="justify-content-center align-items-center mt-2">
                                <MotifIconButton
                                    aria-label="Lock"
                                    type="button"
                                    data-testid="Lock"
                                    // For Edit Mode Checklist Templates, disable UnLock button if template is edited by other user
                                    disabled={!enableActionForLockedAndEditModeTemplate}
                                    onClick={() => props.handleLock(checklistTemplateId, checklistTemplateName, false)} >
                                    <MotifIcon iconFunction={IconoirLock} title={lockedByValidationMessage} size='24'  />
                                </MotifIconButton>

                                <MotifIconButton aria-label="ChangeHistory" type="button"
                                    data-testid="changeHistory"
                                    disabled={!enableActionForLockedAndEditModeTemplate}
                                    onClick={() => props.handleEdit(checklistTemplateId, checklistTemplateName)}
                                >
                                    <MotifIcon iconFunction={IconoirEdit} size='24'  title={beingEditedByValidationMessage} />
                                </MotifIconButton>
                                {props.allowDeleteAction && <MotifIconButton aria-label="Delete"
                                    type="button"
                                    data-testid="delete"
                                    disabled={true}
                                >
                                    <MotifIcon iconFunction={IconoirTrash} size='24'  title={beingEditedByValidationMessage} />
                                </MotifIconButton>}
                            </div>
                        )
                        :
                        // Checklist template is not edited(Locked)
                        (
                            <div className="justify-content-center align-items-center mt-2">
                                <MotifIconButton aria-label="Lock" type="button"
                                    data-testid="Lock"
                                    disabled={!isSystemAdministrator && currentUser.toUpperCase() !== lockedBy.toUpperCase()}
                                    onClick={() => props.handleLock(checklistTemplateId, checklistTemplateName, false)} >
                                    <MotifIcon iconFunction={IconoirLock} title={lockedByValidationMessage} size='24'  />
                                </MotifIconButton>

                                <MotifIconButton aria-label="Edit" type="button"
                                    data-testid="edit" disabled={!isSystemAdministrator && currentUser.toUpperCase() !== lockedBy.toUpperCase()} onClick={() => props.handleEdit(checklistTemplateId, checklistTemplateName)}>

                                    <MotifIcon iconFunction={IconoirEditPencil} size='24'  title="Edit" />
                                </MotifIconButton>
                                {props.allowDeleteAction && <MotifIconButton aria-label="Delete" type="button"
                                    data-testid="delete" disabled={true} onClick={() => props.handleDelete(checklistTemplateId, checklistTemplateName)} >
                                    <MotifIcon iconFunction={IconoirTrash} size='24'  title="Delete" />
                                </MotifIconButton>}
                            </div>
                        )
                    :
                    // For Standard Templates
                    null}
        </div>
        }</div>);
};
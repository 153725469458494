import React from 'react';
import { MotifIcon, MotifIconButton,MotifTextLink } from '@ey-xd/motif-react';
import { IconoirTrash, IconoirDownload } from '@ey-xd/motif-icon';
import { Link } from 'react-router-dom';

export default (props) => {
    return (
        <div>
            <div className="justify-content-end align-items-center mt-2">
            {(props.column.colId == "attachedFileName") &&
               <Link tabIndex="-1"  target="_blank" rel="noopener noreferrer" data-testId="fileName" to={'#'} 
               onClick={(e)=>{e.preventDefault();props.onDownload(props?.data.checklistSectionQuestionAttachmentID,props?.data.attachedFileName)}}>
               {props?.data.attachedFileName} 
               </Link>
            }
            </div>
            {(props.column.colId == "action") &&
            <div className="justify-content-end align-items-center mt-2">

                <MotifIconButton aria-label="Download Checklist Attachment" type="button"
                    onClick={()=>props.onDownload(props?.data.checklistSectionQuestionAttachmentID,props?.data.attachedFileName)}
                    data-testId="downloadFile">
                    <MotifIcon iconFunction={IconoirDownload} size='24'  title="Download Checklist Attachment" />
                </MotifIconButton>

                {props.canDelete &&
                    <MotifIconButton aria-label="Delete Checklist Attachment" type="button"
                        onClick={() => props.onDelete(props?.data.checklistSectionQuestionAttachmentID)}
                        data-testId="delete">
                        <MotifIcon iconFunction={IconoirTrash} size='24' 
                            title="Delete Checklist Attachment" />
                    </MotifIconButton>
                    ||
                    <MotifIconButton aria-label="Can't delete Checklist Attachment" type="button"
                        data-testId="delete"
                        disabled>
                        <MotifIcon iconFunction={IconoirTrash} size='24' 
                        />
                    </MotifIconButton>
                }
                 </div>
            }

            </div>

       
    );
}

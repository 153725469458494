import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { Form, Icon, Popup, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem, MotifButton, MotifTable } from "@ey-xd/motif-react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import TemplateActionCellRenderer from '../shared/customRenderers/TemplateActionCellRenderer';
import DateComparator from '../shared/customComparators/DateComparator.js';
import QuestionTitleComparator from "../shared/customComparators/QuestionTitleComparator";
import CustomHeaderTemplate from '../shared/customHeaders/CustomHeaderTemplate.js';
import CommentsCellRenderer from '../shared/customRenderers/CommentsCellRenderer';

const ShowCommentsTable = ({
    formSubmitErrors,
    questionComments,
    gridColumnState,
    handleGridColumnStateChange,
    handleGridChanges,
    handleRowDelete,
    canEdit
}) => {
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const [rowData, setRowData] = useState([]);
    const rowDataRef = useRef({});
    rowDataRef.current = rowData;

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    var filterParams = {
        comparator: (a, b) => {
            var valA = parseInt(a);
            var valB = parseInt(b);
            if (valA === valB) return 0;
            return valA > valB ? 1 : -1;
        },
    };

    const getColumnDefs = () => {
        let columnDefs = [
            {
                field: 'sectionName', headerName: 'Section Name', sortable: true, showRowGroup: false, rowGroup: false,
                minWidth: 250,
                valueGetter: params => {
                    return params.data && params.data.sectionSortOrder;
                },
                cellRenderer: CommentsCellRenderer, cellRendererParams: { fieldName: 'sectionName' },
                filterValueGetter: params => { return params.data && params.data.sectionSortOrder + ' - ' + params.data.snapshotSectionName; },
                filterParams: filterParams,
                headerComponentParams: { template: CustomHeaderTemplate('sectionName') }
            },
            {
                field: 'questionTitle', headerName: 'Question Title', sortable: true, showRowGroup: false, rowGroup: false,
                minWidth: 250,
                valueGetter: params => {
                    return params.data
                        ? params.data.questionText : '';
                },
                cellRenderer: CommentsCellRenderer, cellRendererParams: { fieldName: 'questionTitle' },
                filterValueGetter: params => {
                    return params.data
                        ? params.data.questionText : '';
                },
                filterParams: filterParams,
                comparator: QuestionTitleComparator,
                headerComponentParams: { template: CustomHeaderTemplate('questionTitle') }
            },
            {
                field: 'comments', headerName: 'Comments', sortable: true, showRowGroup: false, rowGroup: false,
                minWidth: 250,
                valueGetter: params => {
                    return params.data && params.data.commentText;
                },
                cellRenderer: CommentsCellRenderer, cellRendererParams: { fieldName: 'comments' },
                filterValueGetter: params => { return params.data && params.data.commentText; },
                headerComponentParams: { template: CustomHeaderTemplate('comments') }
            },
            {
                field: 'commentedBy', headerName: 'Commented By', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 200,
                valueGetter: params => { return params.data && (params.data.isExternalTempUser ? "External User" : params.data.commentedBy); },
                filterValueGetter: params => { return params.data && params.data.commentedBy; },
                headerComponentParams: { template: CustomHeaderTemplate('commentedBy') }
            },
            {
                field: 'dateCommented', headerName: 'Date Commented', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 210,
                valueGetter: params => {
                    const dateValue = params.data && params.data ? params.data.dateCommented : null;
                    return dateValue ? (new Date(dateValue)).toLocaleDateString() : '';
                },
                filterValueGetter: params => { return params.data && (new Date(params.data.dateCommented)).toLocaleDateString(); },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('dateCommented') }
            }
        ];

          // Conditionally add the deleteComment field if canEdit is true
    if (canEdit) {
        columnDefs.push({
            field: 'deleteComment', headerName: '', suppressMenu: true, suppressHeaderFilterButton: true, 
            minWidth: 80,
            cellRenderer: CommentsCellRenderer, cellRendererParams: { fieldName: 'deleteComment', handleRowDelete, canEdit },
            headerComponentParams: { template: CustomHeaderTemplate('deleteComment') }
        });
    }

    return columnDefs;
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Grid Global Listner
    const gridGlobalListner = function (type, event) {
        if (type.indexOf("columnVisible") >= 0) {
            handleGridColumnsChanged(event);
        }

        // Bind handleFilterChanged event to filterChanged and rowDataUpdated events
        if (type.indexOf("filterChanged") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }

        if (type.indexOf("rowDataUpdated") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }  
    }

    //Handle Grid Ready event
    const handleGridReady = (event) => {
        //Remove event for column state change
        event.api.removeEventListener(gridGlobalListner);

        //Add event for column state change
        event.api.addGlobalListener(gridGlobalListner);

        //Apply column state
        var columnState = localStorage.getItem('REITSuiteShowCommentsColumnState');
        if (columnState) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(columnState),
                applyOrder: true,
            });
        }

        // Set the page size and total pages on the first load        
        handleItemsPerPageChange(50); // Setting it 10 to make sure the default number of items on load is always set to 10

        // useCallback(() => {     gridRef.current.api.sizeColumnsToFit();   }, []);        
    }

    // Handle OnFilterChanged event
    const handleFilterChanged = (event) => {
        let filteredRowData = [];
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            if (currentPage > calcTotalPages) {
                if (calcTotalPages == 0) {
                    setTimeout(function () { setCurrentPage(calcTotalPages); }, 0)
                }
                else {
                    setCurrentPage(calcTotalPages);
                }
            }
            const gridCurrentPage = gridRef.current.api.paginationGetCurrentPage() + 1; // Adding 1 since it returns zero based index
            if (currentPage < (gridCurrentPage) && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }

            // Show the expected value as 1 for current page 
            if (currentPage <= 1 &&  gridCurrentPage == 1 && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }    
        }
    }

    //Handle First Data Renderered event
    const handleFirstDataRendered = (event) => {
        gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Handle Grid Columns changed event
    const handleGridColumnsChanged = (event) => {
        var currentColumnState = gridRef.current.columnApi.getColumnState();
        var localStorageColumnState = localStorage.getItem('REITSuiteShowCommentsColumnState');

        // If the column state details are not exists in local storage then save the changed Column state data
        if (currentColumnState && currentColumnState.filter(col => col.hide == true)?.length > 0 && !localStorageColumnState) {
            saveUserPreferenceDetails();
        }
        else if (localStorageColumnState) {
            // If the column state details are exists in local storage then save the changed Column state data only if difference is found
            var columnsStateArray = JSON.parse(localStorageColumnState);
            if (columnsStateArray && columnsStateArray.length > 0
                && currentColumnState && currentColumnState.length > 0
                && columnsStateArray.filter(col => col.hide == true)?.length != currentColumnState.filter(col => col.hide == true)?.length) {
                //If column state mismatch found then only save column state data to avoid saving same state
                saveUserPreferenceDetails();
            }
        }

        //Trigger Grid Column state change (of parent) to sync column state data
        handleGridColumnStateChange(currentColumnState);

        gridRef && gridRef.current && gridRef.current.columnApi && gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Save User Preference details (Column state)
    const saveUserPreferenceDetails = useCallback(() => {
        var columnState = gridRef.current.columnApi.getColumnState();
        if (columnState) {
            localStorage.setItem('REITSuiteShowCommentsColumnState', JSON.stringify(columnState));
        }
    }, []);

    //Handle Grid Items per Page change event
    const handleItemsPerPageChange = (val) => {
        gridRef.current.api.paginationGoToFirstPage();
        gridRef.current.api.paginationSetPageSize(Number(val));
        setCurrentPage(1);
        setItemsPerPage(val);
        // Get total pages from Grid's default pagination control and apply it to custom pagination control
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    useEffect(() => {
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);
    }, [rowData]);

    useEffect(() => {
        setRowData(questionComments);

        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshHeader();
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }
    }, [questionComments]);

    useEffect(() => {
        //Reset Grid to default column state
        //When Grid Column state changes due to Reset Column State action then Reset Grid to default column state
        var localStorageColumnState = localStorage.getItem('REITSuiteShowCommentsColumnState');
        if (!localStorageColumnState && gridRef && gridRef.current && gridRef.current.api) {

            gridRef.current.api.setColumnDefs([]);
            gridRef.current.api.setColumnDefs(getColumnDefs());
        }
    }, [gridColumnState]);

    const rowHeight = 76; 
    const headerHeight = 45; 
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

    return <div>
        <div className="row mb-10">
            {questionComments && questionComments.length > 0 &&
                <div className="col mt-3">
                    <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                        <MotifTable zebra={true} compact={true}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            accentedSort={true}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            onGridReady={handleGridReady}
                            onFirstDataRendered={handleFirstDataRendered}
                            ref={gridRef}
                            pagination={true}
                            suppressPaginationPanel={true}
                            paginationPageSize="100"
                            groupDisplayType={'custom'}
                            groupSelectsChildren={true}
                            groupDefaultExpanded={0}
                            onFilterChanged={handleFilterChanged}
                            data-testid="commentsGrid"
                        />
                         <div className="mt-3 mb-3 right" style={{ marginBottom: '50px' }}>
                            <MotifPagination currentPage={currentPage} onPageChange={handlePageChange} min={1} max={totalPages}>
                                <MotifPaginationSelect data-testid="itemsPerPage">
                                    <MotifPaginationSelectItem selected onClick={() => handleItemsPerPageChange(50)}>
                                        Show 50
                                    </MotifPaginationSelectItem>
                                    <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(100)}>
                                        Show 100
                                    </MotifPaginationSelectItem>
                                    <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(150)}>
                                        Show 150
                                    </MotifPaginationSelectItem>
                                    <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(200)}>
                                        Show 200
                                    </MotifPaginationSelectItem>
                                </MotifPaginationSelect>
                            </MotifPagination>
                        </div>
                    </div>
                   
                </div>
            }
        </div>
    </div>;
};

ShowCommentsTable.propTypes = {
};

export default reduxForm({ form: "showComments" })(ShowCommentsTable);
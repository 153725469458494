import React from 'react';
import { MotifTooltip } from '@ey-xd/motif-react';
import { MotifIcon, MotifIconButton } from '@ey-xd/motif-react';
import { IconoirTrash, IconoirDownload } from '@ey-xd/motif-icon';
export default (props) => {
  let fieldValue = "";
  const attachmentId = props.data ? props.data.checklistFileAttachmentID : -1;
  const attachedFileName = props.data ? props.data.fileName : -1;
  if (props.node && props.node.data) {
    switch (props.fieldName) {
      case 'sectionName': {
        fieldValue = props.node.data['sectionSortOrder'] + ' - ' + props.node.data['snapshotSectionName'];
        break;
      }
      case 'questionTitle': {
        fieldValue = props.node.data['questionText'];
        break;
      }
      case 'fileName': {
        fieldValue = props.node.data['fileName'];
        break;
      }
    }
  }

  if (props.fieldName == 'attachmentIcon') {
    return (
      <div>
        <div className="justify-content-center align-items-center mt-2">
          <MotifIconButton aria-label="Download" type="button"
            data-testid="downloadIcon">
            <MotifIcon iconFunction={IconoirDownload} size='24'  title="Download" onClick={(e) => { e.preventDefault(); props.handleDownloadChecklistFile(attachmentId, attachedFileName); }} />
          </MotifIconButton>
          {(props.canEdit &&

            <MotifIconButton aria-label="Delete" type="button"
              data-testid="deleteIcon">
              <MotifIcon iconFunction={IconoirTrash} size='24'  title="Delete" onClick={() => props.handleDeleteAttachment(attachmentId)} />
            </MotifIconButton>
          )}

        </div>
      </div>
    );
  }

  else {
    if (fieldValue && fieldValue.length > 30 && (props.fieldName == 'sectionName' || props.fieldName == 'questionTitle')) {
      return (
        <div>
          <span>{fieldValue.substring(0, 29)}</span>
          <span className="ml-1" style={{ cursor: 'pointer' }}>
            <MotifTooltip
              id="show-comments-tooltip"
              allowClick
              allowHover
              placement="bottom"
              trigger={<span style={{ whiteSpace: 'nowrap' }}>...</span>}
              variant="alt"
              style={{ maxWidth: '20px' }}
              hideCloseButton={true}
            >
              {fieldValue}
            </MotifTooltip>
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span>{fieldValue}</span>
        </div>
      );
    }
  }
}
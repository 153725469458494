import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { Form, Icon, Popup, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem, MotifButton, MotifToast, MotifTable } from "@ey-xd/motif-react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import TemplateLinkCellRenderer from '../shared/customRenderers/TemplateLinkCellRenderer';
import TemplateActionCellRenderer from '../shared/customRenderers/TemplateActionCellRenderer';
import DateComparator from '../shared/customComparators/DateComparator.js';
import CustomHeaderTemplate from '../shared/customHeaders/CustomHeaderTemplate.js';

const CustomChecklistTemplateTable = ({
    formSyncErrors,
    formSubmitErrors,
    psqTemplates,
    clientId,
    gridColumnState,
    handleGridColumnStateChange,
    handleGridChanges,
    handleEdit,
    handleDelete,
    handleLock,
    handlePreview,
    currentUser,
    currentUserIsSysAdmin,
    currentUserIsEngAdmin,
    currentUserIsEngagementUser
}) => {
    const gridRef = useRef();
    const [hideInfoMessage, setHideInfoMessage] = useState(false);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState([]);
    const rowDataRef = useRef({});
    rowDataRef.current = rowData;

    //  Allow delete action if current user is System admin or Engagement admin 
    const allowDeleteAction = currentUserIsSysAdmin || currentUserIsEngAdmin;

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,
        };
    }, []);

    const getColumnDefs = () => {
        return [
            {
                field: 'templateLink', headerName: '', suppressMenu: true, suppressHeaderFilterButton: true, minWidth: 70,
                cellRenderer: TemplateLinkCellRenderer, cellRendererParams: { clientId: clientId, handlePreview },
                headerComponentParams: { template: CustomHeaderTemplate('templateLink') }
            },
            {
                field: 'checklistTemplateName', headerName: 'Template Name', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 533,maxWidth: 533,
                valueGetter: params => {
                    return params.data && params.data.checklistTemplateName;
                },
                filterValueGetter: params => { return params.data && params.data.checklistTemplateName; },
                headerComponentParams: { template: CustomHeaderTemplate('template') },               
            },
            {
                field: 'questionnaireCount', headerName: 'Questionnaire Count', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 218,
                valueGetter: params => {
                    return params.data && params.data.questionnaireCount;
                },
                filterValueGetter: params => { return params.data && params.data.questionnaireCount; },
                headerComponentParams: { template: CustomHeaderTemplate('questionnaire') },
                cellStyle: { 'height': '100%', 'display': 'flex ', 'justifyContent': 'center', 'alignItems': 'center' }
            },
            {
                field: 'lastUpdatedBy', headerName: 'Last Updated By', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 198,
                valueGetter: params => { return params.data && params.data.lastUpdatedBy; },
                filterValueGetter: params => { return params.data && params.data.lastUpdatedBy; },
                headerComponentParams: { template: CustomHeaderTemplate('lastUpdatedBy') }
            },
            {
                field: 'lastUpdatedDate', headerName: 'Last Updated Date', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: allowDeleteAction === true ? 210: 253,
                valueGetter: params => {
                    const dateValue = params.data && params.data ? params.data.lastUpdatedDate : null;
                    return dateValue ? (new Date(dateValue)).toLocaleDateString() : '';
                },
                filterValueGetter: params => { return params.data && (new Date(params.data.lastUpdatedDate)).toLocaleDateString(); },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('lastUpdatedDate') }
            },
            {
                field: 'templateAction', headerName: '', suppressMenu: true, suppressHeaderFilterButton: true, showRowGroup: false, rowGroup: false,
                minWidth: allowDeleteAction === true ? 200 : 130, 
                cellRenderer: TemplateActionCellRenderer, cellRendererParams: { allowDeleteAction, handleEdit, handleDelete, handleLock, currentUser, currentUserIsSysAdmin, currentUserIsEngAdmin, currentUserIsEngagementUser },
                headerComponentParams: { template: CustomHeaderTemplate('TemplateAction') }
            }      
        ];
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Grid Global Listner
    const gridGlobalListner = function (type, event) {
        if (type.indexOf("columnVisible") >= 0) {
            handleGridColumnsChanged(event);
        }

        // Bind handleFilterChanged event to filterChanged and rowDataUpdated events
        if (type.indexOf("filterChanged") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }

        if (type.indexOf("rowDataUpdated") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }  
    }

    //Handle Grid Ready event
    const handleGridReady = (event) => {
        //Remove event for column state change
        event.api.removeEventListener(gridGlobalListner);

        //Add event for column state change
        event.api.addGlobalListener(gridGlobalListner);

        //Apply column state
        var columnState = localStorage.getItem('REITSuiteTemplateColumnState');
        if (columnState) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(columnState),
                applyOrder: true,
            });
        }
        
        // Set the page size and total pages on the first load        
        handleItemsPerPageChange(100); // Setting it 100 to make sure the default number of items on load is always set to 100
    }

    // Handle OnFilterChanged event
    const handleFilterChanged = (event) => {
        let filteredRowData = [];
        if (gridRef && gridRef.current && gridRef.current.api) {  
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            if (currentPage > calcTotalPages) {                
                if (calcTotalPages == 0) {                    
                    setTimeout(function () { setCurrentPage(calcTotalPages); }, 0)
                }
                else {
                    setCurrentPage(calcTotalPages);
                }                
            }
            const gridCurrentPage = gridRef.current.api.paginationGetCurrentPage()+1; // Adding 1 since it returns zero based index
            if (currentPage < (gridCurrentPage) && calcTotalPages != 0) {                
                setCurrentPage(gridCurrentPage);                
            } 
            
             // Show the expected value as 1 for current page 
             if (currentPage <= 1 &&  gridCurrentPage == 1 && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }    
        }
    }    

    //Handle First Data Renderered event
    const handleFirstDataRendered = (event) => {
        gridRef.current.columnApi.autoSizeAllColumns();
    }   

    //Handle Grid Columns changed event
    const handleGridColumnsChanged = (event) => {
        var currentColumnState = gridRef.current.columnApi.getColumnState();
        var localStorageColumnState = localStorage.getItem('REITSuiteTemplateColumnState');

        // If the column state details are not exists in local storage then save the changed Column state data
        if (currentColumnState && currentColumnState.filter(col => col.hide == true)?.length > 0 && !localStorageColumnState) {
            saveUserPreferenceDetails();
        }
        else if (localStorageColumnState) {
            // If the column state details are exists in local storage then save the changed Column state data only if difference is found
            var columnsStateArray = JSON.parse(localStorageColumnState);
            if (columnsStateArray && columnsStateArray.length > 0
                && currentColumnState && currentColumnState.length > 0
                && columnsStateArray.filter(col => col.hide == true)?.length != currentColumnState.filter(col => col.hide == true)?.length) {
                //If column state mismatch found then only save column state data to avoid saving same state
                saveUserPreferenceDetails();
            }
        }

        //Trigger Grid Column state change (of parent) to sync column state data
        handleGridColumnStateChange(currentColumnState);

        gridRef && gridRef.current && gridRef.current.columnApi && gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Save User Preference details (Column state)
    const saveUserPreferenceDetails = useCallback(() => {
        var columnState = gridRef.current.columnApi.getColumnState();
        if (columnState) {
            localStorage.setItem('REITSuiteTemplateColumnState', JSON.stringify(columnState));
        }
    }, []);

    //Handle Grid Items per Page change event
    const handleItemsPerPageChange = (val) => {
        gridRef.current.api.paginationGoToFirstPage();
        gridRef.current.api.paginationSetPageSize(Number(val));                
        setCurrentPage(1);
        setItemsPerPage(val);
        // Get total pages from Grid's default pagination control and apply it to custom pagination control
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val-1);
    };

    useEffect(() => {
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);        
    }, [rowData]);
        
    useEffect(() => {
        setRowData(psqTemplates);

        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshHeader();        
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);            
        }
    }, [psqTemplates]);    

    useEffect(() => {
        //Reset Grid to default column state
        //When Grid Column state changes due to Reset Column State action then Reset Grid to default column state
        var localStorageColumnState = localStorage.getItem('REITSuiteTemplateColumnState');
        if (!localStorageColumnState && gridRef && gridRef.current && gridRef.current.api) {

            gridRef.current.api.setColumnDefs([]);
            gridRef.current.api.setColumnDefs(getColumnDefs());
        }
    }, [gridColumnState]);

    const rowHeight = 76; 
    const headerHeight = 45; 
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

    return <div>  
    <div className="row mb-10">
        { psqTemplates && psqTemplates.length > 0 &&
            <div className="col mt-3">
                 <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                    <MotifTable zebra={true} compact={true}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        accentedSort={true}
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                        onGridReady={handleGridReady}
                        onFirstDataRendered={handleFirstDataRendered}                        
                        ref={gridRef}
                        pagination={true}
                        suppressPaginationPanel={true}
                        paginationPageSize="100"                        
                        groupDisplayType={'custom'}
                        groupSelectsChildren={true}
                        groupDefaultExpanded={0}
                        onFilterChanged={handleFilterChanged}
                        data-testid="checklistTemplateGrid"
                    />
                    <div className="mt-3">
                        <span className="left mb-5" style={{ minWidth: '790px' }}>
                            {!hideInfoMessage && 
                                <MotifToast  onClose={setHideInfoMessage} data-testid="eyToastMsg">
                                1. Unlock icon on the template indicates that the template is available to lock/edit/delete.<br />
                                2. Lock icon on the template indicates that the template is locked by a user and is unavailable for edit/delete.
                                </MotifToast>
                            }
                        </span>
                        <span className="right mb-5" style={{ height: '40px' }}>
                            <MotifPagination currentPage={currentPage} onPageChange={handlePageChange} min={1} max={totalPages}>
                                <MotifPaginationSelect data-testid="itemsPerPage">
                                    <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(50)}>
                                        Show 50
                                    </MotifPaginationSelectItem>
                                    <MotifPaginationSelectItem selected onClick={() => handleItemsPerPageChange(100)}>
                                        Show 100
                                    </MotifPaginationSelectItem>
                                    <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(150)}>
                                        Show 150
                                    </MotifPaginationSelectItem>
                                    <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(200)}>
                                        Show 200
                                    </MotifPaginationSelectItem>
                                </MotifPaginationSelect>
                            </MotifPagination>
                        </span>
                    </div>
                </div>
                
            </div>
            }
            </div>
    </div>;
};

CustomChecklistTemplateTable.propTypes = {
    handlePreview: PropTypes.func
};

export default reduxForm({ form: "customChecklistTemplate" })(CustomChecklistTemplateTable);
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { Form, Icon, Popup, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem, MotifButton, MotifToast, MotifTable } from "@ey-xd/motif-react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import BulkReportManagementActionCellRenderer from '../shared/customRenderers/BulkReportManagementActionCellRenderer';
import BulkReportManagementStatusCellRenderer from '../shared/customRenderers/BulkReportManagementStatusCellRenderer';
import DateComparator from '../shared/customComparators/DateComparator.js';
import CustomHeaderTemplate from '../shared/customHeaders/CustomHeaderTemplate.js';
import BulkReportPackageNameCellRenderer from './customRenderers/BulkReportPackageNameCellRenderer';
import * as formatters from "../../scripts/formFormatters";
import { getPreviousFridayOrGivenDate } from "../../scripts/dateHelpers";
import ReportPeriodComparator from "../shared/customComparators/ReportPeriodComparator.js";

const BulkReportManagementTable = ({
    formSyncErrors,
    formSubmitErrors,
    bulkReportPackages,
    clientId,
    gridColumnState,
    handleGridColumnStateChange,
    handleGridChanges,
    handleDownload,
    handleDelete,
    currentUser,
    currentUserIsSysAdmin,
    currentUserIsEngAdmin,
    currentUserIsEngagementUser,
    theme
}) => {
    const gridRef = useRef();
    const [hideInfoMessage, setHideInfoMessage] = useState(false);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState([]);
    const rowDataRef = useRef({});
    rowDataRef.current = rowData;

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true,
            resizable: true,
            sortable: true,
            wrapText: true,
            autoHeight: true,      
            autoHeaderHeight: true,      
            wrapHeaderText: true,
        };
    }, []);

    // MM:DD:YYYY HH:MM AM/PM
    const dateFormat1 = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };

    // MM:DD:YYYY
    const dateFormat2 = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    };

    const getColumnDefs = () => {
        return [
            {
                field: 'bulkReportPackageName', headerName: 'Report Package Name', 
                sortable: true, showRowGroup: false, rowGroup: false, minWidth: 250,
                cellRenderer: BulkReportPackageNameCellRenderer,
                headerComponentParams: { template: CustomHeaderTemplate('reportPackageName') },
            },
            {
                field: 'createdBy', headerName: 'Executed By', 
                sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 185,
                valueGetter: params => { return params.data && params.data.createdBy; },
                filterValueGetter: params => { return params.data && params.data.createdBy; },
                headerComponentParams: { template: CustomHeaderTemplate('executedBy') },
            },
            {
                field: 'createdDate', headerName: 'Date Executed', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 190,
                valueGetter: params => {
                    const dateValue = params.data && params.data ? params.data.createdDate : null
                    return dateValue ? (new Date(dateValue + 'Z')).toLocaleString('en-US', dateFormat1) : '';
                },
                filterValueGetter: params => {
                    return params.data
                        ? params.data.createdDate ? (new Date(params.data.createdDate + 'Z')).toLocaleString('en-US', dateFormat1) : 'N/A'
                        : null;
                },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('dateExecuted') },
            },
            {
                field: 'bulkReportStatusDescription', headerName: 'Status', suppressMenu: false, showRowGroup: false, rowGroup: false,
                minWidth: 290, 
                cellRenderer: BulkReportManagementStatusCellRenderer,
                headerComponentParams: { template: CustomHeaderTemplate('Status') },
            },
            {
                field: 'earliestReportPeriodDescription', headerName: 'Earliest Report Period', 
                sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 175,maxWidth: 175,
                valueGetter: params => { return params.data && params.data.earliestReportPeriodDescription; },
                filterValueGetter: params => { return params.data && params.data.earliestReportPeriodDescription; },
                comparator: ReportPeriodComparator
            },
            {
                field: "scheduledDeletionDate", headerName: "Scheduled Deletion Date",
                sortable: true, showRowGroup: false, rowGroup: false, minWidth: 270,
                headerComponentParams: {
                  template: CustomHeaderTemplate("scheduledDeletionDate"),
                },
                valueGetter: (params) => {
                  const dateValue = params.data && params.data.scheduledDeletionDate ? params.data.scheduledDeletionDate : null;
                  return dateValue ? formatters.formatDate(getPreviousFridayOrGivenDate(dateValue)) : null;
                },
                comparator: DateComparator,
            },
            {
                field: 'bulkReportStatusDescription', headerName: '', suppressMenu: true, suppressHeaderFilterButton: true,  showRowGroup: false, rowGroup: false,
                minWidth: 150, 
                cellRenderer: BulkReportManagementActionCellRenderer, cellRendererParams: { handleDownload, handleDelete, currentUser, currentUserIsSysAdmin, currentUserIsEngAdmin, currentUserIsEngagementUser },
                headerComponentParams: { template: CustomHeaderTemplate('bulkReportManagementAction') },
            }
        ];
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Grid Global Listner
    const gridGlobalListner = function (type, event) {
        if (type.indexOf("columnVisible") >= 0) {
            handleGridColumnsChanged(event);
        }

        // Bind handleFilterChanged event to filterChanged and rowDataUpdated events
        if (type.indexOf("filterChanged") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }

        if (type.indexOf("rowDataUpdated") >= 0) {
            setTimeout(function () { handleFilterChanged(event); }, 0);            
        }  
    }

    //Handle Grid Ready event
    const handleGridReady = (event) => {
        //Remove event for column state change
        event.api.removeEventListener(gridGlobalListner);

        //Add event for column state change
        event.api.addGlobalListener(gridGlobalListner);

        //Apply column state
        var columnState = localStorage.getItem('REITSuiteBulkReportPackageColumnState');
        if (columnState) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(columnState),
                applyOrder: true,
            });
        }

        // Set the page size and total pages on the first load        
        handleItemsPerPageChange(100); // Setting it 100 to make sure the default number of items on load is always set to 100
    }

    // Handle OnFilterChanged event
    const handleFilterChanged = (event) => {
        let filteredRowData = [];
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            if (currentPage > calcTotalPages) {
                if (calcTotalPages == 0) {
                    setTimeout(function () { setCurrentPage(calcTotalPages); }, 0)
                }
                else {
                    setCurrentPage(calcTotalPages);
                }
            }
            const gridCurrentPage = gridRef.current.api.paginationGetCurrentPage()+1; // Adding 1 since it returns zero based index
            if (currentPage < (gridCurrentPage) && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }

            // Show the expected value as 1 for current page 
            if (currentPage <= 1 &&  gridCurrentPage == 1 && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }    
        }
    }

    //Handle First Data Renderered event
    const handleFirstDataRendered = (event) => {
        gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Handle Grid Columns changed event
    const handleGridColumnsChanged = (event) => {
        var currentColumnState = gridRef.current.columnApi.getColumnState();
        var localStorageColumnState = localStorage.getItem('REITSuiteBulkReportPackageColumnState');

        // If the column state details are not exists in local storage then save the changed Column state data
        if (currentColumnState && currentColumnState.filter(col => col.hide == true)?.length > 0 && !localStorageColumnState) {
            saveUserPreferenceDetails();
        }
        else if (localStorageColumnState) {
            // If the column state details are exists in local storage then save the changed Column state data only if difference is found
            var columnsStateArray = JSON.parse(localStorageColumnState);
            if (columnsStateArray && columnsStateArray.length > 0
                && currentColumnState && currentColumnState.length > 0
                && columnsStateArray.filter(col => col.hide == true)?.length != currentColumnState.filter(col => col.hide == true)?.length) {
                //If column state mismatch found then only save column state data to avoid saving same state
                saveUserPreferenceDetails();
            }
        }

        //Trigger Grid Column state change (of parent) to sync column state data
        handleGridColumnStateChange(currentColumnState);

        gridRef && gridRef.current && gridRef.current.columnApi && gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Save User Preference details (Column state)
    const saveUserPreferenceDetails = useCallback(() => {
        var columnState = gridRef.current.columnApi.getColumnState();
        if (columnState) {
            localStorage.setItem('REITSuiteBulkReportPackageColumnState', JSON.stringify(columnState));
        }
    }, []);

    //Handle Grid Items per Page change event
    const handleItemsPerPageChange = (val) => {
        gridRef.current.api.paginationGoToFirstPage();
        gridRef.current.api.paginationSetPageSize(Number(val));
        setCurrentPage(1);
        setItemsPerPage(val);
        // Get total pages from Grid's default pagination control and apply it to custom pagination control
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val-1);
    };

    useEffect(() => {
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);
    }, [rowData]);

    useEffect(() => {
        if (gridRef.current) {
            setColumnDefs(getColumnDefs());
        }
    }, [theme]);

    useEffect(() => {
        setRowData(bulkReportPackages);
        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.applyTransaction({
                update: bulkReportPackages.filter(item => gridRef.current.api.getRowNode(item.bulkReportPackageID)),
                remove: gridRef.current.api.forEachNode(node => !bulkReportPackages.find(item => item.bulkReportPackageID === node.data.bulkReportPackageID)),
                add: bulkReportPackages.filter(item => !gridRef.current.api.getRowNode(item.bulkReportPackageID))
            });

            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }
    }, [bulkReportPackages]);

    useEffect(() => {
        //Reset Grid to default column state
        //When Grid Column state changes due to Reset Column State action then Reset Grid to default column state
        var localStorageColumnState = localStorage.getItem('REITSuiteBulkReportPackageColumnState');
        if (!localStorageColumnState && gridRef && gridRef.current && gridRef.current.api) {

            gridRef.current.api.setColumnDefs([]);
            gridRef.current.api.setColumnDefs(getColumnDefs());
        }
    }, [gridColumnState]);

    const rowHeight = 72; 
    const headerHeight = 45; 
    const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default

    return <div>
        {bulkReportPackages && bulkReportPackages.length > 0 &&
        <div>
            <div className="row">
                <div className="col">
                        <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                            <MotifTable zebra={true} compact={true}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                accentedSort={true}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                onGridReady={handleGridReady}
                                onFirstDataRendered={handleFirstDataRendered}
                                ref={gridRef}
                                pagination={true}
                                suppressPaginationPanel={true}
                                paginationPageSize="100"
                                groupDisplayType={'custom'}
                                groupSelectsChildren={true}
                                groupDefaultExpanded={0}
                                onFilterChanged={handleFilterChanged}
                                data-testid="divReportManagementGrid"
                                deltaRowDataMode={true}
                                getRowId={(data) => { return data.data.bulkReportPackageID }}                                
                            />
                            <div className="mt-3">
                                <span className="right mb-3">
                                    <MotifPagination currentPage={currentPage} onPageChange={handlePageChange} min={1} max={totalPages}>
                                        <MotifPaginationSelect data-testid="itemsPerPage">
                                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(50)}>
                                                Show 50
                                            </MotifPaginationSelectItem>
                                            <MotifPaginationSelectItem selected onClick={() => handleItemsPerPageChange(100)}>
                                                Show 100
                                            </MotifPaginationSelectItem>
                                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(150)}>
                                                Show 150
                                            </MotifPaginationSelectItem>
                                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(200)}>
                                                Show 200
                                            </MotifPaginationSelectItem>
                                        </MotifPaginationSelect>
                                    </MotifPagination>
                                </span>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        }
        
    </div>;
};

BulkReportManagementTable.propTypes = {
};

export default reduxForm({ form: "bulkReportManagement" })(BulkReportManagementTable);

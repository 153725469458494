import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { json, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import * as checklistActions from '../../actions/checklistActions';
import * as propertyActions from '../../actions/propertyActions';
import * as commonActions from '../../actions/commonActions';
import * as serviceActions from '../../actions/servicesActions';
import * as periodActions from '../../actions/periodActions';
import * as fileActions from '../../actions/fileActions';
import * as reportActions from '../../actions/reportActions';
import ChecklistNavigation from '../../components/reits/ChecklistNavigation';
import EditChecklistSection from '../../components/reits/EditChecklistSection';
import ChecklistCounters from '../../components/reits/ChecklistCounters';
import { Segment, Button, Modal, Header, Icon, Grid } from 'semantic-ui-react';
import ChecklistReadyModal from '../../components/reits/ChecklistReadyModal';
import PsqCreateChecklistModal from '../../components/reits/PsqCreateChecklistModal';
import ChecklistPageBase from './ChecklistPageBase';
import ChecklistSendModal from '../../components/reits/ChecklistSendModal';
import ForwardPsqForInternalReviewModal from '../../components/reits/ForwardPsqForInternalReviewModal';
import * as formHelpers from '../../scripts/formHelpers';
import * as actionHelpers from '../../scripts/actionHelpers';
import { Button as BootstrapButton, Dropdown as BootstrapDropdown, ButtonGroup, DropdownButton } from 'react-bootstrap';
import { MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem, MotifToggleSwitch, MotifTooltip } from '@ey-xd/motif-react';
import { IconoirNavArrowDown } from '@ey-xd/motif-icon';
import * as reitActions from "../../actions/reitActions";
import * as clientActions from "../../actions/clientActions";
import * as lookupTypeActions from "../../actions/lookupTypeActions";
import * as customChecklistTemplateActions from '../../actions/customChecklistTemplateActions';
import DeleteChecklistDetailGridRowModal from '../../components/reits/DeleteChecklistDetailGridRowModal';
import ShowCommentsTable from "../../components/customChecklistTemplate/ShowCommentsTable";
import ShowAttachmentTable from '../../components/customChecklistTemplate/ShowAttachmentTable';
import ShowDiscrepanciesTable from '../../components/customChecklistTemplate/ShowDiscrepanciesTable';
import * as  checklistCountRequest from '../../constants/checklistNavigationTabs';
import DeleteChecklistServiceProviderModal from '../../components/reits/DeleteChecklistServiceProviderModal';
import { withRouter } from '../../common/withRouter';
import { WebSocketContext } from '../../components/PermExternalApp';

/**
 * ExternalPsqChecklistPage container component
 * @extends {ChecklistPageBase}
 */
export class ExternalPsqChecklistPage extends ChecklistPageBase {
  static contextType = WebSocketContext;
  /**
   * Creates a new ExternalPsqChecklistPage
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);
    this.prevWS = null;
    
    this.setActiveItem = this.setActiveItem.bind(this);
    this.handleSaveExternalPsqChecklist = this.handleSaveExternalPsqChecklist.bind(
      this,
    );
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExternalConfirmationAction = this.handleExternalConfirmationAction.bind(
      this,
    );
    this.handleAddPsqSection2Row = this.handleAddPsqSection2Row.bind(this);
    this.handleRemovePsqSection2Row = this.handleRemovePsqSection2Row.bind(
      this,
    );
    this.showForwardPsqForInternalReviewModal = this.showForwardPsqForInternalReviewModal.bind(
      this,
    );
    this.hideForwardPsqForInternalReviewModal = this.hideForwardPsqForInternalReviewModal.bind(
      this,
    );
    this.submitForwardPsqForInternalReviewModal = this.submitForwardPsqForInternalReviewModal.bind(
      this,
    );
    this.handleInternalReviewEmailFieldChanged = this.handleInternalReviewEmailFieldChanged.bind(
      this,
    );
    this.setInternalReviewEmailFieldReference = this.setInternalReviewEmailFieldReference.bind(
      this,
    );
    this.handleDismissForwardPsqErrorMessage = this.handleDismissForwardPsqErrorMessage.bind(
      this,
    );
    this.handleDownloadAttachmentClick = this.handleDownloadAttachmentClick.bind(this);
    this.state.showDetails = true;
    this.state.isButtonDisabled= false;
    this.state.isSubmittedForEyReview = false;
    this.state.showForwardPsqForInternalReviewModal = false;
    this.state.internalReviewEmails = [];
    this.state.inititiallySetEmails = false;
    this.state.forwardPsqFormLoaded = false;
    this.state.canEdit = true;
    this.state.newQuestionIds = [];
    this.state.checklistDropdownOpen = false;
    this.state.showAllQuestions = true;
    this.state.showUnansweredQuestions = false;
    this.state.selectedAttachmentId = null;
    this.state.selectedCommentId = null;
    this.state.showDeleteChecklistGridRowModal = false;
    this.state.gridColumnState = [];
    this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
    this.handleGridChanges = this.handleGridChanges.bind(this);
    this.state.questionComments = [];
    this.state.attachmentDetails = [];
    this.state.discrepanciesDetail = [];
    this.state.deleteFor = null;
    this.state.selectedServiceProvider = null;
    this.state.showDeleteChecklistServiceProviderModal = false;
    this.state.isPsqCompletionRequirementFulfilled = false;
    this.state.checklistCompletionPercentage = 0;
    this.handleGridRowDeleteById = this.handleGridRowDeleteById.bind(this);
    this.handleDeleteAttachment =
      this.handleDeleteAttachment.bind(this);
    this.toggleShowChecklistGridRowDelete = this.toggleShowChecklistGridRowDelete.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.toggleShowDeleteChecklistServiceProvider = this.toggleShowDeleteChecklistServiceProvider.bind(this);
    this.handleServiceProviderDelete = this.handleServiceProviderDelete.bind(this);
    this.handleSaveCommentExternal = this.handleSaveCommentExternal.bind(this);
    this.handlePsqCompletionRequirement = this.handlePsqCompletionRequirement.bind(this);
    this.setInititiallySetEmailsFrwd = this.setInititiallySetEmailsFrwd.bind(this);
    this.checkReportPeriodVisibilityConstraint = this.checkReportPeriodVisibilityConstraint.bind(this);
    this.state.isInititiallySetEmailsFrwd = false;
    this.state.currentError = null;
  }

  /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.commonActions.clearErrorResponse();
    if (!this.props.isExternalPermUser) {
      this.props.checklistActions.fetchExternalPsqChecklistsById(this.props.checklistId);
      this.props.checklistActions.fetchLastServiceProviderQuestionId(actionHelpers.buildAuthHeader(
        this.props.checklistId,
        "PSQ"
      ));
    }
    else {
      this.props.checklistActions.fetchExternalPermPsqChecklists(this.props.internalChecklistId).then((response) => {
        //  Redirect unauthorized user to Access Denied Error page
        if (response && response.errorResponse && response.errorResponse.errorCode && response.errorResponse.errorCode.toLowerCase() == 'unauthorized') {
          this.props.router.navigate(`/ext/unauthorized`);
        }
      });
      this.props.checklistActions.fetchLastServiceProviderQuestionId();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const ws = this.context;

    if(ws && this.prevWS !== ws){
        this.prevWS = ws;
        ws.on('userAuthorizationUpdate', (message) => {
          // If the user is external permanent user and the user is deleted for same client of the current checklist, reload the page
          if(message && message.ClientID && this.props && this.props.client && this.props.client.id && message.ClientID == this.props.client.id
              && message.ActionName && message.ActionName == 'delete' 
              && this.props.isExternalPermUser){
              window.location.reload();
          }
        });
    }

    if (
      this.props.psqActiveChecklist !== prevProps.psqActiveChecklist ||
      this.props.client !== prevProps.client
    ) {
      if (
        this.props.psqActiveChecklist &&
        this.props.client &&
        this.props.client.id !== undefined
      ) {
        this.getChecklistCount(
          this.props.client.id,
          this.props.psqActiveChecklist.checklistID
        );
      }

      // Set the PSQ completion requirement fulfillment on client load
      this.setState({
        isPsqCompletionRequirementFulfilled: this.props.client
          .isPsqCompletionRequirementEnabled
          ? this.state.checklistCompletionPercentage === 100
          : true
      });
    }
    if (this.props.psqActiveChecklist !== prevProps.psqActiveChecklist) {
      const checklist = this.props.psqActiveChecklist;
      this.setState({ checklist: checklist });

      if (checklist && checklist.reportPeriodID) {
        if (!prevProps.isExternalPermUser) {
          prevProps.lookupTypeActions.fetchExternalPropertyTypes(
            prevProps.checklistId,
            "PSQ"
          );
          prevProps.serviceActions.fetchExternalServicesByPeriod(
            checklist.reportPeriodID,
            this.props.checklistId,
            "PSQ"
          );

          // Get the period (so we know the status), to know if we can add comments
          this.props.periodActions.fetchExternalPeriodById(
            checklist.reportPeriodID,
            this.props.checklistId,
            this.props.psqActiveChecklist.checklistTypeID
          );

          // Load REIT & Client for external user
          reitActions
            .fetchReitByPeriodId(
              checklist.reportPeriodID,
              prevProps.checklistId,
              "PSQ"
            )
            .then(reit => {
              this.setState({ reit: reit });
              if (reit && reit.clients && reit.clients.length > 0) {
                prevProps.clientActions.loadExternalClient(
                  reit.clients[0].id,
                  prevProps.checklistId,
                  "PSQ"
                );
              }
            });
        } else {
          prevProps.lookupTypeActions.fetchPropertyTypes();
          prevProps.serviceActions.fetchServicesByPeriod(
            checklist.reportPeriodID
          );
          periodActions
            .fastFetchPeriodById(checklist.reportPeriodID)
            .then(period => {
              this.setCanEdit(period);
              this.setState({ period: period });
            });

          reitActions
            .fetchReitByPeriodId(checklist.reportPeriodID)
            .then(reit => {
              this.setState({ reit: reit });
              if (reit && reit.clients && reit.clients.length > 0) {
                prevProps.clientActions.loadClient(reit.clients[0].id);
              }
            });
        }
      }

      if (
        !prevProps.period.reportPeriodStatusDescription &&
        this.props.period.reportPeriodStatusDescription
      ) {
        this.setCanEdit(this.props.period);
      }

      // set disabled sections
      if (
        this.props.psqActiveChecklist.content !== undefined &&
        this.props.psqActiveChecklist.content.length > 0 &&
        this.props.services !== undefined &&
        this.props.services !== null
      ) {
        this.disableSections(checklist);

        if (this.state.activeItem == null) {
          for (
            let i = 0;
            i < this.props.psqActiveChecklist.content.length;
            i++
          ) {
            if (
              this.state.disabledSections.find(
                x => x === this.props.psqActiveChecklist.content[i].id
              ) === undefined
            ) {
              this.setState({
                activeItem: this.props.psqActiveChecklist.content[0]
              });
              break;
            }
          }
        }
      }
    }

    // Populate previous answers on active checklist
    if (
      this.props.previousPsqChecklist !== prevProps.previousPsqChecklist &&
      prevProps.psqActiveChecklist
    ) {
      this.props.checklistActions.setActiveChecklist(
        prevProps.psqActiveChecklist,
        this.props.previousPsqChecklist
      );
    }

    // Refresh attachment details table post addition/deletion of attachments
if (this.props.attachmentDetails !== prevProps.attachmentDetails) {
      if (prevProps.psqActiveChecklist && prevProps.psqActiveChecklist !== null && prevProps.psqActiveChecklist.content.length > 0) {
        const updatedAttachmentDetails = this.props.attachmentDetails.map((attachment) => {
          const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...prevProps.psqActiveChecklist }, attachment.questionID);

          return {
            ...attachment,
            questionText: updatedQuestionText === '' ? attachment.questionText : updatedQuestionText
          };
        });

        this.setState({ attachmentDetails: updatedAttachmentDetails });
      }
    }

    // Refresh comments details table post addition/deletion of attachments
    if (prevProps.questionComments !== this.props.questionComments) {
      if (prevProps.psqActiveChecklist && prevProps.psqActiveChecklist !== null && prevProps.psqActiveChecklist.content.length > 0) {
        const updatedCommentsDetails = this.props.questionComments.map((comment) => {
          const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...prevProps.psqActiveChecklist }, comment.checklistSectionQuestionID);

          return {
            ...comment,
            questionText: updatedQuestionText === '' ? comment.questionText : updatedQuestionText
          };
        });

        this.setState({ questionComments: updatedCommentsDetails });
      }
    }

    if(prevProps.errorResponse && (this.props.errorResponse !== prevProps.errorResponse)) {
      this.setState({currentError: prevProps.errorResponse}) 
    }
  }

  setCanEdit(period) {
    const isEnabled = periodActions.isReportingEnabled(period);
    this.setState({ canEdit: isEnabled });
  }

  handleDownloadAttachmentClick(attachmentGuid, attachmentName) {
    return fileActions.downloadChecklistQuestionnaireAttachment(this.props.psqActiveChecklist.checklistID,this.props.client.id, attachmentGuid, encodeURIComponent(attachmentName), this.props.isExternalPermUser ? null :
    actionHelpers.buildAuthHeader(
      this.props.checklistId,
      this.state.checklist.checklistTypeID
    ));

  }

  handleAddPsqSection2Row() {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    const psqSection2 = checklist.content.find(x => x.type === 'PsqSection2');
    let items = psqSection2.items;
    if (items != null) {
      // Get the questions of type "PsqSection2AdditionalServices"
      const additionalServicesItems = items.filter(item => item.itemType === "PsqSection2AdditionalServices");

      // Get the last Service Provider exists on checklist.
      let lastItem = additionalServicesItems[additionalServicesItems.length - 1];
      const questionSortOrder = lastItem.questionSortOrder + 1;

      // Insert Service Provider question in ChecklistSectionQuestion table
      this.props.checklistActions.insertPsqServiceProviderQuestion({
        checklistId: this.props.psqActiveChecklist.checklistID,
        checklistSectionId: psqSection2.id,
        lastServiceProviderQuestionId: lastItem.id,
        questionSortOrder: questionSortOrder
      },
        this.props.isExternalPermUser ? null :
          actionHelpers.buildAuthHeader(
            this.props.checklistId,
            this.state.checklist.checklistTypeID
          ))
        .then((addServiceProviderQuestionResponse) => {
          // Get Service Provider Question JSON with actual IDs populated from addServiceProviderQuestionResponse
          let newItem = this.getServiceQuestionJSON(addServiceProviderQuestionResponse, psqSection2.sectionSortOrder, questionSortOrder);

          // Insert this Service Provider question into checklist
          checklist.content
            .find(x => x.type === 'PsqSection2')
            .items.push(newItem);

          this.setState({ checklist: checklist });

          // Append all these new question ids into state
          this.setState({ newQuestionIds: [...this.state.newQuestionIds, ...addServiceProviderQuestionResponse.map(q => q.questionId)] });

          this.disableSections(checklist);
        });
    }
  }

  handleRemovePsqSection2Row() {
    const item = this.state.selectedServiceProvider;
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    let section = checklist.content.find(x => x.type === 'PsqSection2');
    for (let i = 1; i < section.items.length; i++) {
      if (section.items[i].id === item.id) {
        let questionIdsToRemove = [item.id];
        questionIdsToRemove = [...questionIdsToRemove, ...item.children.map(x => x.id)];

        // Remove Service Provider question and it's childs from ChecklistSectionQuestion table
        this.props.checklistActions.removePsqServiceProviderQuestion({
          checklistId: this.props.psqActiveChecklist.checklistID,
          checklistSectionId: section.id,
          serviceProviderQuestionIdsToRemove: [item.checklistSectionQuestionID, ...item.children.map(x => x.checklistSectionQuestionID)]
        },
          this.props.isExternalPermUser ? null :
            actionHelpers.buildAuthHeader(
              this.props.checklistId,
              this.state.checklist.checklistTypeID
            ))
          .then((removeServiceProviderQuestionResponse) => {
            // Remove these question ids from state
            this.setState({ newQuestionIds: this.state.newQuestionIds.filter(x => !questionIdsToRemove.includes(x)) });

            // Remove the Service Provider question from checklist
            checklist.content
              .find(x => x.type === 'PsqSection2')
              .items.splice(i, 1);

            this.setState({ checklist: checklist });
            this.disableSections(checklist);
          });

        break;
      }
    }
    this.toggleShowDeleteChecklistServiceProvider();
  }

  componentWillUnmount() {
    this.props.commonActions.clearErrorResponse();
  }

  setGridColumnState() {
    let localState = '';
    if (this.state.activeItem === 'Comments') {
      localState = 'REITSuiteShowCommentsColumnState';
    }
    else if (this.state.activeItem === 'Attachments') {
      localState = 'REITSuiteShowAttachmentColumnState';
    }
    else if (this.state.activeItem === 'Discrepancies') {
      localState = 'REITSuiteShowDiscrepanyColumnState';
    }
    var columnState = localStorage.getItem(localState);
    if (columnState) {
      this.setState({ gridColumnState: JSON.parse(columnState) });
    }
    else {
      this.setState({ gridColumnState: [{ colId: '-1' }] });
    }
  }

  /**
  * Reset AG-Grid column state
  * @returns {undefined}
  */
  resetGridColumnState() {
    let localState = '';
    if (this.state.activeItem === 'Comments') {
      localState = 'REITSuiteShowCommentsColumnState';
    }
    else if (this.state.activeItem === 'Attachments') {
      localState = 'REITSuiteShowAttachmentColumnState';
    }
    else if (this.state.activeItem === 'Discrepancies') {
      localState = 'REITSuiteShowDiscrepanyColumnState';
    }
    localStorage.removeItem(localState);
    this.setState({ gridColumnState: [] });
  }

  /**
  * Handle Grid column state chagne
  * @returns {undefined}
  */
  handleGridColumnStateChange(columnState) {
    this.setState({ gridColumnState: columnState });
  }

  handleGridChanges(gridRowData) {
    let rowData = [];

    switch (this.state.activeItem) {
      case 'Comments': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            snapshotSectionName: x.snapshotSectionName,
            questionText: x.questionText,
            commentText: x.commentText,
            commentedBy: x.commentedBy,
            dateCommented: x.dateCommented,
          }
        });
        break;
      }
      case 'Attachments': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            snapshotSectionName: x.snapshotSectionName,
            questionText: x.questionText,
            attachedFileName: x.attachedFileName,
            uploadedBy: x.uploadedBy,
            dateUploaded: x.dateUploaded,
          }
        });
        break;
      }
      case 'Discrepancies': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            sectionName: x.sectionName,
            questionText: x.questionText,
            discrepancyMessage: x.discrepancyMessage,
          }
        });
        break;
      }
    }
    this.setState({ gridCurrentState: rowData });
  }

  setActiveItem(e, { name }) {
    const item = this.props.psqActiveChecklist.content.find(
      x => x.title === name,
    );
    if (item === undefined && name) {
      this.setState({ activeItem: name, isSubNavigation: true });
    }
    else if (this.state.disabledSections.find(x => x === item.id) === undefined) {
      this.setState({ activeItem: item, isSubNavigation: false });
    }
  }

  getChecklistCount(clientId, checklisId) {
    let checklistGuid = null;
    if (!this.props.isExternalPermUser) {
      checklistGuid = this.props.checklistId;
    }

    // this.props.checklistActions.fetchAttachmentDetail(clientId,
    //   checklisId, null, checklistGuid,
    //   "PSQ");

    this.getAttachmentDetails(clientId, checklisId, checklistGuid, "PSQ");
    // this.props.checklistActions.fetchQuestionComments(clientId, checklisId, checklistGuid,
    //   "PSQ");
    this.getCommentsDetails(clientId, checklisId, checklistGuid, "PSQ")
    this.props.checklistActions.fetchChecklistDiscrepancies(clientId, checklisId, checklistGuid,
      "PSQ");

    this.props.checklistActions
      .fetchChecklistNavCounts(
        clientId,
        checklisId,
        checklistCountRequest.ChecklistCountRequest,
        checklistGuid,
        "PSQ"
      )
      .then((data) => {
        this.setState({ counters: data });
      });

  }

  getAttachmentDetails(clientId, checklisId, checklistGuid, checklistType) {
    this.props.checklistActions.fetchAttachmentDetail(clientId, checklisId, null, checklistGuid, checklistType)
      .then(() => {
        if (this.props.psqActiveChecklist && this.props.psqActiveChecklist !== null && this.props.psqActiveChecklist.content.length > 0) {
          const updatedAttachmentDetails = this.props.attachmentDetails.map((attachment) => {
            const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.psqActiveChecklist }, attachment.questionID);

            return {
              ...attachment,
              questionText: updatedQuestionText === '' ? attachment.questionText : updatedQuestionText
            };
          });

          this.setState({ attachmentDetails: updatedAttachmentDetails });
        }
      });
  }

  getCommentsDetails(clientId, checklisId, checklistGuid, checklistType) {
    this.props.checklistActions.fetchQuestionComments(clientId, checklisId, checklistGuid, checklistType)
      .then(() => {
        if (this.props.psqActiveChecklist && this.props.psqActiveChecklist !== null && this.props.psqActiveChecklist.content.length > 0) {
          const updatedCommentsDetails = this.props.questionComments.map((comment) => {
            const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.psqActiveChecklist }, comment.checklistSectionQuestionID);

            return {
              ...comment,
              questionText: updatedQuestionText === '' ? comment.questionText : updatedQuestionText
            };
          });

          this.setState({ questionComments: updatedCommentsDetails });
        }
      });
  }

  checkReportPeriodVisibilityConstraint() {
    return this.props.checklistActions
      .allowUpdateAsPerReportPeriodVisibilityConstraint(
        this.state.checklist.checklistID,
        "PSQ",
        !this.props.isExternalPermUser,
        !this.props.isExternalPermUser ?  
        actionHelpers.buildAuthHeader(
          this.props.checklistId,
          "PSQ"
        ) 
        : null
      );
  }

  

  handleGridRowDeleteById(id) {
    // Before delete, check whether report period of checklist having visibility status as hidden
    this.checkReportPeriodVisibilityConstraint().then((response) => {
      let proceedWithAction = true;
      if(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND'){
        proceedWithAction = false;
      }

      if(proceedWithAction){
        let activeTab = this.state.activeItem;
          if (this.state.activeItem === "Attachments") {
            this.setState({ selectedAttachmentId: id });
          } else if (this.state.activeItem === "Comments") {
            this.setState({ selectedCommentId: id });
          } else {
            activeTab = "Attachments";
            this.setState({ selectedAttachmentId: id });
          }
          this.setState({ deleteFor: activeTab });
          this.toggleShowChecklistGridRowDelete();
      }
    });    
  }

  handleServiceProviderDelete(item) {
    this.setState({ selectedServiceProvider: item });
    this.toggleShowDeleteChecklistServiceProvider();
  }

  toggleShowDeleteChecklistServiceProvider() {
    this.setState({
      showDeleteChecklistServiceProviderModal: !this.state.showDeleteChecklistServiceProviderModal
    });
  }

  toggleShowChecklistGridRowDelete() {
    const showToggle = !this.state.showDeleteChecklistGridRowModal;
    this.setState({
      showDeleteChecklistGridRowModal: showToggle,
    });
  }

  deleteAttachment() {
    let checklistGuid = null;
    if (!this.props.isExternalPermUser) {
      checklistGuid = this.props.checklistId;
    }
    const clientId = this.props.client.id;
    this.props.checklistActions
      .deleteAttachment(
        this.state.selectedAttachmentId,
        clientId,
        checklistGuid,
        "PSQ"
      )
      .then((data) => {
        this.props.checklistActions.fetchAttachmentDetail(
          clientId,
          this.props.psqActiveChecklist.checklistID,
          null,
          checklistGuid,
          "PSQ"
        );

        this.props.checklistActions
          .fetchChecklistNavCounts(
            clientId,
            this.props.psqActiveChecklist.checklistID,
            checklistCountRequest.ChecklistCountRequest,
            checklistGuid,
            "PSQ"
          );

        this.toggleShowChecklistGridRowDelete();
      })
      .catch((error) => {
        this.toggleShowChecklistGridRowDelete();
      });
  }


  deleteComment() {
    let checklistGuid = null;
    if (!this.props.isExternalPermUser) {
      checklistGuid = this.props.checklistId;
    }
    const clientId = this.props.client.id;
    this.props.checklistActions
      .deleteComment(
        this.state.selectedCommentId,
        clientId, checklistGuid, "PSQ"
      )
      .then((data) => {
        this.props.checklistActions.fetchQuestionComments(clientId, this.props.psqActiveChecklist.checklistID, checklistGuid, "PSQ");

        this.props.checklistActions
          .fetchChecklistNavCounts(
            clientId,
            this.props.psqActiveChecklist.checklistID,
            checklistCountRequest.ChecklistCountRequest,
            checklistGuid, "PSQ"
          );


        this.toggleShowChecklistGridRowDelete();
      })
      .catch((error) => {
        this.toggleShowChecklistGridRowDelete();
      });
  }

  handleSaveCommentExternal(questionId, commentText) {
    return this.handleSaveComment(questionId, commentText, true, !this.props.isExternalPermUser);
  }

  getIsGridNoDataFound(name) {
    if ((name === "Comments"
      && this.props.questionComments
      && this.props.questionComments.length === 0)
      || (name === "Attachments"
        && this.props.attachmentDetails
        && this.props.attachmentDetails.length === 0)
      || (name === "Discrepancies"
        && this.props.discrepanciesDetail
        && this.props.discrepanciesDetail.length === 0)) {
      return true;
    }
    else { return false; }
  }

  handleSaveExternalPsqChecklist() {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    this.saveExternalChecklist(checklist, this.props.checklistId, 'PSQ');
  }

  handleSubmit() {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    checklist.sendNotification = true;
    this.saveExternalChecklist(checklist, this.props.checklistId, 'PSQ');

    this.showSendModal();
  }

  handleExternalConfirmationAction(e) {
    this.props.checklistActions.fetchExternalPermPsqChecklists(this.props.internalChecklistId, true, 'Submitted').then((result) => {
          if((result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_ALREADY_SUBMITTED"))
            {
              this.showConfirmationModal();
              this.setState({ isButtonDisabled: true });
            }

  else{  const value = e?.target?.value || "CANCEL"; 

  if (value === "CANCEL") {
    this.showConfirmationModal();
    return;
  }
    if(value === "INPROGRESS" && this.state.checklist.checklistStateID === 'PENDINGINTERNALREVIEW'){
      this.showConfirmationModal();
      return;
    }
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    checklist.checklistStateID = e.target.value;
    this.setState({ checklist: checklist });
    if (e.target.value === 'PENDINGEYREVIEW') {
      checklist.sendNotification = true;
      this.setState({ isSubmittedForEyReview: true });
    }

    this.saveExternalChecklist(checklist, this.props.checklistId, 'PSQ');
    this.showConfirmationModal();
  }
    })
    }

  showForwardPsqForInternalReviewModal() {
    this.setState({
      showForwardPsqForInternalReviewModal: true,  
      internalReviewEmails: [],
    });
  }

  hideForwardPsqForInternalReviewModal() {
    this.handleDismissForwardPsqErrorMessage();
    this.setState({
      showForwardPsqForInternalReviewModal: false,
      inititiallySetEmails: false,
      forwardPsqFormLoaded: false,
    });
  }

  submitForwardPsqForInternalReviewModal(){
    if (this.props.isExternalPermUser) {
      this.props.checklistActions.fetchExternalPermPsqChecklists(this.props.internalChecklistId, true, 'Submitted').then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND' && result.errorResponse.errorCode === "CHECKLIST_ALREADY_SUBMITTED")) {
              this.saveForwardPsqForInternalReviewModal();
            }
            if((result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_ALREADY_SUBMITTED"))
              {
                this.hideForwardPsqForInternalReviewModal();
                this.setState({ isButtonDisabled: true });
              }
          });
        }
      });
    } else {
      this.props.checklistActions.fetchExternalPsqChecklistsById(this.props.checklistId, true).then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
              this.saveForwardPsqForInternalReviewModal();
            }
          });
        }
      });
    }
  }


  saveForwardPsqForInternalReviewModal() {
    let checklistDetails= JSON.parse(JSON.stringify(this.state.checklist));
    checklistDetails.checklistStateID= 'PENDINGINTERNALREVIEW';
    this.saveExternalChecklist(
      checklistDetails,
      this.props.checklistId,
      'PSQ',
    );

    const emails = this.state.internalReviewEmails;
      // Determine the action to perform based on the user type
     const action = this.props.isExternalPermUser ? checklistActions.sendPsqInternalReviewEmailForExtPerm : checklistActions.sendPsqInternalReviewEmail;
     const checklistId= this.props.isExternalPermUser ? this.props.psqActiveChecklist.checklistID: this.props.checklistId;
    return action(checklistId, emails)
      .then(response => {
        if (response.ok) {
          return null;
        }

        return response.json();
      })
      .then(json => {
        if (json == null) {
          this.setState({ checklist: checklistDetails });
          this.hideForwardPsqForInternalReviewModal();
          
          return;
        }

        if (actionHelpers.isErrorResponse(json)) {
          return formHelpers.generateSubmissionError(json);
        }

        return formHelpers.generateSubmissionError();
      })
      .catch(error => {
        if (error instanceof SubmissionError) {
          throw error;
        }

        return formHelpers.generateSubmissionError();
      });
  }

  setInternalReviewEmailFieldReference(ref) {
    this.setState({ forwardPsqFormLoaded: true });
  }

  setInititiallySetEmailsFrwd () {
    this.setState({ isInititiallySetEmailsFrwd: true });
  }

  handleInternalReviewEmailFieldChanged(values) {
    let uniqueValues =
      (Array.isArray(values) &&
        values.filter((value, index, self) => self.indexOf(value) === index)) ||
      values;

    this.setState({ internalReviewEmails: uniqueValues });
  }

  handleDismissForwardPsqErrorMessage() {
    this.props.commonActions.clearFormErrors('forwardPsqForInternalReview');
  }

  downloadChecklist(withAnswer) {
    this.checkReportPeriodVisibilityConstraint().then((response) => {
      let proceedWithAction = true;
            
      if(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND'){
        proceedWithAction = false;
      }

      if(proceedWithAction){
      const reportPeriodDescription = this.props.isExternalPermUser ? this.state.period.reportPeriodDescription : this.props.period.reportPeriodDescription;
      const reportPeriodID = this.props.isExternalPermUser ? this.state.period.reportPeriodID : this.props.period.reportPeriodID;
      const checklistGuid = this.props.checklistId;
      const filename = `${this.state.reit.reitName}_${reportPeriodDescription.replace(' ', '')}_PSQ_Checklist.docx`
      this.props.commonActions.clearErrorResponse();
      this.props.reportActions.downloadChecklist('reitName=' + this.state.reit.reitName + ',periodDesc=' + reportPeriodDescription, reportPeriodID, 'PSQ', this.state.checklist.checklistID, withAnswer, filename, true, checklistGuid);
   }
  });
}

  isChecklistContentEnabled() {
    return this.props.psqActiveChecklist !== null &&
      this.props.psqActiveChecklist.checklistStateID !== undefined &&
      this.props.psqActiveChecklist.checklistStateID.toLowerCase() !== 'completed' &&
      this.props.psqActiveChecklist.checklistStateID.toLowerCase() !== "pendingeyreview" &&
      !this.state.isSubmittedForEyReview;
  }

  handlePsqCompletionRequirement(completionPercentage) {
    // PSQ Completion requirement is fulfilled when either checklist is completed 100% or is it not enabled for client
    this.setState({
      checklistCompletionPercentage: completionPercentage,
      isPsqCompletionRequirementFulfilled: this.props.client.isPsqCompletionRequirementEnabled
        ? completionPercentage === 100
        : true
    });
  }
  
  handleOnSubmitInternallyBtnClick() {
    if (this.props.isExternalPermUser) {
      this.props.checklistActions.fetchExternalPermPsqChecklists(this.props.internalChecklistId, true).then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
              this.showForwardPsqForInternalReviewModal();
            }
          });
        }
      });
    } else {
      this.props.checklistActions.fetchExternalPsqChecklistsById(this.props.checklistId, true).then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
              this.showForwardPsqForInternalReviewModal();
            }
          });
        }
      });
    }
  }
  
  handleOnSubmitEyReviewBtnClick() {
    if (this.props.isExternalPermUser){
      this.props.checklistActions.fetchExternalPermPsqChecklists(this.props.internalChecklistId, true).then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
              this.showConfirmationModal();
            }
          });
        }
      });
    }
    else{
      this.props.checklistActions.fetchExternalPsqChecklistsById(this.props.checklistId, true).then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
              this.showConfirmationModal();
            }
          });
        }
      });
    }
  }
  
  handleSaveBtnClick() {
    if (this.props.isExternalPermUser){
      this.props.checklistActions.fetchExternalPermPsqChecklists(this.props.internalChecklistId, true).then((result) => {       
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          if(this.state.checklist){
            var updatedChecklist = this.state.checklist;
            updatedChecklist.checklistStateID = result.checklistStateID;
            this.setState({ checklist: updatedChecklist });
          }
          
        this.checkReportPeriodVisibilityConstraint().then((response) => {
          if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
            this.handleSaveExternalPsqChecklist();
          }
        });
      }
    });
  } 
  else{
    this.props.checklistActions.fetchExternalPsqChecklistsById(this.props.checklistId, true).then((result) => {      
      if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
        if(this.state.checklist){
          var updatedChecklist = this.state.checklist;
          updatedChecklist.checklistStateID = result.checklistStateID;
          this.setState({ checklist: updatedChecklist });
        }

        this.checkReportPeriodVisibilityConstraint().then((response) => {
          if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
            this.handleSaveExternalPsqChecklist();
          }
        });
      }
    });
  }
}

  getDetails() {
    const isEnabled = this.isChecklistContentEnabled();

    if (
      this.state.showDetails &&
      this.state.checklist !== null &&
      this.props.services
    ) {
      return (
        <div>
          <div className="row">
            <div className="col-md-3 mb-2">
              <ChecklistNavigation
                disabledSections={this.state.disabledSections}
                checklist={this.state.checklist}
                activeItem={this.state.activeItem}
                handleSelectionChanged={this.setActiveItem}
                checklistFiles={this.props.attachmentDetails}
                navigationCount={this.props.checklistCounter}
                isExternal={true}
              />
            </div>
            <div className="col-md-9 mb-2">
              {!this.state.isSubNavigation && (
                <>
                  {this.state.checklist.content.map(section => (
                    <EditChecklistSection
                      periodId={this.state.checklist.reportPeriodID}
                      isEnabled={isEnabled}
                      showAllQuestions={this.state.showAllQuestions}
                      showUnansweredQuestions={this.state.showUnansweredQuestions}
                      key={section.id}
                      section={section}
                      isVisible={section.title === this.state.activeItem.title}
                      handleItemAnswered={this.handleItemAnswered}
                      isExternal={true}
                      isArqc={false}
                      previousChecklistFound={
                        this.props.previousPsqChecklist &&
                        this.props.previousPsqChecklist.content
                      }
                      prePopulateAnswerOptionID={this.state.checklist.prePopulateAnswerOptionID}
                      lastServiceProviderQuestionId={this.props.lastServiceProviderQuestionId}
                      handleAddPsqSection2Row={this.handleAddPsqSection2Row}
                      handleRemovePsqSection2Row={this.handleServiceProviderDelete}
                      checkReportPeriodVisibilityConstraint={this.checkReportPeriodVisibilityConstraint}
                      handleSaveComment={this.handleSaveCommentExternal}
                      handleDeleteComment={this.handleDeleteCommentExternal}
                      handleEditComment={this.handleEditCommentExternal}
                      handleDeleteAttachment={this.handleGridRowDeleteById}
                      handleDownloadChecklistFile={this.handleDownloadChecklistFileExternal}
                      checklistFiles={this.props.attachmentDetails}
                      checklistId={this.props.checklistId}
                      canEdit={this.state.canEdit && this.isChecklistContentEnabled()}
                      externalEmail={this.props.psqActiveChecklist.emailFromIdentifier}
                      questionComments={this.props.questionComments}
                      handleDownloadAttachmentClick={this.handleDownloadAttachmentClick}
                    />
                  ))}
                </>
              )}
              {this.state.isSubNavigation && (
                <>
                  <h5 className="sectionTitle">{this.state.activeItem}</h5>

                  {this.getIsGridNoDataFound(this.state.activeItem) &&
                    <div>No {this.state.activeItem.toLowerCase()} found for this checklist.</div>
                  }

                  {this.state.activeItem === 'Comments' &&
                    <ShowCommentsTable
                      clientId={this.props.client.id}
                      questionComments={this.state.questionComments}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                      handleRowDelete={this.handleGridRowDeleteById}
                      canEdit={this.state.canEdit && this.isChecklistContentEnabled()}
                    />
                  }
                  {this.state.activeItem === 'Attachments' &&
                    <ShowAttachmentTable
                      attachmentDetail={this.state.attachmentDetails}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                      handleDeleteAttachment={this.handleGridRowDeleteById}
                      handleDownloadChecklistFile={ (checklistFileAttachmentId, fileName) => {
                        this.checkReportPeriodVisibilityConstraint().then((response) => {
                          let proceedWithAction = true;
            
                          if(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND'){
                            proceedWithAction = false;
                          }
                          if(proceedWithAction){
                            this.handleDownloadChecklistFileExternal(checklistFileAttachmentId, fileName);
                          }
                        });
                        }
                      }
                      canEdit={this.state.canEdit && this.isChecklistContentEnabled()}
                    />
                  }
                  {this.state.activeItem === 'Discrepancies' &&
                    <ShowDiscrepanciesTable
                      discrepanciesDetail={this.props.discrepanciesDetail}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                    />
                  }
                </>
              )}
            </div>
          </div>
          <DeleteChecklistDetailGridRowModal
            showDeleteChecklistGridRowModal={
              this.state.showDeleteChecklistGridRowModal}
            toggleshowDeleteChecklistGridRowModal={
              this.toggleShowChecklistGridRowDelete}
            deleteRow={this.state.deleteFor === "Attachments" ? this.deleteAttachment : this.deleteComment}
            modalName={this.state.deleteFor}
          />
          <DeleteChecklistServiceProviderModal
            showDeleteChecklistServiceProviderModal={
              this.state.showDeleteChecklistServiceProviderModal
            }
            toggleShowDeleteChecklistServiceProviderModal={
              this.toggleShowDeleteChecklistServiceProvider
            }
            deleteServiceProvider={this.handleRemovePsqSection2Row}
          />
          <ChecklistReadyModal
            handleModalAction={this.handleExternalConfirmationAction}
            showModal={this.state.showConfirmationModal}
          />
          <ForwardPsqForInternalReviewModal
            onSubmit={this.submitForwardPsqForInternalReviewModal}
            showModal={this.state.showForwardPsqForInternalReviewModal}
            handleCancel={this.hideForwardPsqForInternalReviewModal}
            domRef={this.setInternalReviewEmailFieldReference}
            handleInternalReviewEmailFieldChanged={
              this.handleInternalReviewEmailFieldChanged
            }
            handleDismissErrorMessage={this.handleDismissForwardPsqErrorMessage}
            loaded={this.state.forwardPsqFormLoaded}
            emails={this.state.internalReviewEmails}
            isInititiallySetEmailsFrwd={this.isInititiallySetEmailsFrwd}
            setInititiallySetEmailsFrwd={this.setInititiallySetEmailsFrwd}
          />
        </div>
      );
    }

    return null;
  }

  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {
    if (
      this.props.errorResponse &&
      (this.props.errorResponse.errorCode === 'LINK_EXPIRED' ||
       this.props.errorResponse.errorCode === 'UNKNOWN' || this.props.errorResponse.errorCode === 'FORBIDDEN')
      || (this.state.currentError &&  
         (this.state.currentError.errorCode === "LINK_EXPIRED" || 
          this.state.currentError.errorCode === "UNKNOWN" || this.state.currentError.errorCode === 'FORBIDDEN'))
    ) {
      return (
        <div>
          <h2 data-testid="h2PageHeading">Property Service Questionnaire</h2>
          <br />
          <h4>We apologize this link has expired.</h4>
        </div>
      );
    }

    if (!this.props.isExternalPermUser && (
      (this.props.errorResponse && this.props.errorResponse.errorCode === 'CHECKLIST_SUBMITTED') ||
      (this.props.psqActiveChecklist && this.props.psqActiveChecklist.checklistStateID === 'PendingEyReview') ||
      this.state.isSubmittedForEyReview
      || (this.state.currentError && this.state.currentError.errorCode === "CHECKLIST_SUBMITTED"))) {
      return (
        <div>
          <h2 data-testid="h2PageHeading">Property Service Questionnaire</h2>
          <br />
          <h4>This PSQ has been submitted for EY review.</h4>
        </div>
      );
    }    

    if (
      (this.props.errorResponse &&
      this.props.errorResponse.errorCode === 'RESOURCE_NOT_FOUND') ||
      (this.state.currentError && this.state.currentError.errorCode === "RESOURCE_NOT_FOUND")
    ) {
      return (
        <div>
          <h2 data-testid="h2PageHeading">Property Service Questionnaire</h2>
          <br />
          <h4>We apologize this link has expired.</h4>
        </div>
      );
    }

    return (
      <div className="mt-2">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col">
            <h2 data-testid="h2PageHeading">{this.getPsqQuestionnaireTitle()}</h2>
            {this.props.psqActiveChecklist && (this.props.psqActiveChecklist.checklistTemplateID < 0 ? "" :
              <div>
                <div><span className="questionnaireSummary">Property Name:</span> {this.props.psqActiveChecklist.propertyName}</div>
                <div><span className="questionnaireSummary">Property Address:</span> {this.props.psqActiveChecklist.propertyFullAddress}</div>
              </div>)}
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col-md-4 checklistCounters">
            <ChecklistCounters
              previousChecklistFound={
                this.props.previousPsqChecklist &&
                this.props.previousPsqChecklist.content
              }
              isExternal={true}
              checklist={this.state.checklist}
              disabledSections={this.state.disabledSections}
              // Hide Changed Answers when user is external user accessing one time URL and 
              // checklist has not pre-populated previous answers and reference answers
              shouldHideChangedAnswers={!this.props.isExternalPermUser && this.props.psqActiveChecklist && this.props.psqActiveChecklist.prePopulateAnswerOptionID === 2}
              handlePsqCompletionRequirement={this.handlePsqCompletionRequirement}
            />
          </div>
          <div className="col-md-8 ">
            <div className="d-flex ml-auto justify-content-end">
              <span className="ml-3">
                <MotifDropdown
                  open={this.state.checklistDropdownOpen}
                  onClick={()=>{
                    if(this.props.isExternalPermUser){
                    this.props.checklistActions.fetchExternalPermPsqChecklists(this.props.internalChecklistId);}
                    else{this.props.checklistActions.fetchExternalPsqChecklistsById(this.props.checklistId); }                               
                   }
                  }
                  placement="bottom-end"
                  id={'checklist-dropdown'}
                  aria-labelledby={'client-management-trigger'}
                  data-testid="btnDownloadChecklist"
                  handleClickOutside={() => this.setState({ checklistDropdownOpen: false })}
                  trigger={
                    <MotifButton
                      disabled={!(this.props.psqActiveChecklist && this.props.psqChecklists !== undefined && this.props.psqChecklists.length > 0)}
                      type="button"
                      variant="primary-alt"
                      onClick={() => this.setState({ checklistDropdownOpen: !this.state.checklistDropdownOpen })}
                      aria-controls={'checklist-dropdown'}
                      id={'checklist-dropdown-trigger'}
                      aria-expanded={this.state.checklistDropdownOpen}
                      aria-label="Download checklist dropdown"
                    >
                      Download Checklist <MotifIcon iconFunction={IconoirNavArrowDown} size='24'  />
                    </MotifButton>
                  }
                >
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS'); }}>
                    With Answers Only
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS_COMMENTS') }}>
                    With Answers And Comments
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITHOUT_ANSWERS'); }}>
                    Without Answers or Comments
                  </MotifDropdownItem>
                </MotifDropdown>
              </span>

              <MotifTooltip
                placement="bottom"
                allowHover={!this.state.isPsqCompletionRequirementFulfilled}
                allowClick={false}
                trigger={
                  <MotifButton variant="secondary" className="ml-2" type="button" data-testid="btnSubmitForEYReview"
                    disabled={!this.isChecklistContentEnabled() || !this.state.isPsqCompletionRequirementFulfilled || this.state.isButtonDisabled}
                    onClick={()=>{
                     this.handleOnSubmitEyReviewBtnClick();
                    }}
                    >
                    Submit for EY Review
                  </MotifButton>
                }>
                <span>Please make sure to complete the checklist in order to submit for review.</span>
              </MotifTooltip>

              
                <MotifButton variant="secondary" className="ml-2" type="button" data-testid="btnSubmitInternally"
                onClick={()=>{
                 this.handleOnSubmitInternallyBtnClick();
                  }}
                  disabled={!this.isChecklistContentEnabled()  || this.state.isButtonDisabled}>
                  Submit Internally
                </MotifButton>
              
              <MotifButton variant="primary" className="ml-2" type="button" data-testid="btnSave"
                 onClick={()=>{this.handleSaveBtnClick();}
                 }
                 disabled={!this.isChecklistContentEnabled() || this.state.isButtonDisabled}>
                Save
              </MotifButton>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex mr-auto">
            <div className="d-flex flex-row" style={{ alignItems: 'center' }} data-testid="toggleDisplayUnansweredQuestionsOnly">
              <div className="mr-3">
                <span className="right font-weight-bold">Display Unanswered Questions Only:</span>
              </div>
              <div>
                <div className="switch-box">
                  <MotifToggleSwitch
                    aria-label="Display Unanswered Questions Only toggle"
                    name="showUnanswered"
                    id="showUnanswered"
                    onChange={this.handleShowUnansweredQuestions}
                    checked={this.state.showUnansweredQuestions}
                  />
                </div>
              </div>
            </div>

            {/* Do Not show Display Changed Answers button to Unregistered External users*/}
            {(this.props.isExternalPermUser &&
              this.state.showDetails &&
              this.props.psqActiveChecklist &&
              this.props.previousPsqChecklist &&
              Array.isArray(this.props.previousPsqChecklist.content) &&
              this.props.previousPsqChecklist.content.length > 0 && (
                <div className="d-flex flex-row ml-4" style={{ alignItems: 'center' }} data-testid="toggleDisplayChangedAnswers">
                  <div className="mr-3">
                    <span className="right font-weight-bold">Display Changed Answers:</span>
                  </div>
                  <div>
                    <div className="switch-box">
                      <MotifToggleSwitch
                        aria-label="Display Changed Answers toggle"
                        name="showAll"
                        id="showAll"
                        onChange={this.handleShowAllQuestions}
                        checked={!this.state.showAllQuestions}
                      />
                    </div>
                  </div>
                </div>
              )) || <div />}
          </div>
        </div>

        {this.props.psqActiveChecklist &&
          this.props.psqActiveChecklist.prePopulateAnswerOptionID === 1 &&
          <div className="mb-3">
            (Pre-populated with answers from prior period)
          </div>}

        {this.getDetails()}
        <PsqCreateChecklistModal
          properties={this.props.properties}
          handleFieldChanged={this.handleCreateField}
          handleModalAction={this.handleCreateAction}
          showModal={this.state.showCreateModal}
        />
        <ChecklistSendModal
          onSubmit={this.handleSubmit}
          handleFieldChanged={this.handleSendField}
          showModal={this.state.showSendModal}
          showSendModal={this.showSendModal}
          isSendCheckListEmailEnabled={this.state.isSendCheckListEmailEnabled}
        />
      </div>
    );
  }
}

ExternalPsqChecklistPage.propTypes = {
  client: PropTypes.object.isRequired,
  reit: PropTypes.object.isRequired,
  periodId: PropTypes.number.isRequired,
  psqChecklists: PropTypes.array,
  psqActiveChecklist: PropTypes.object,
  checklistActions: PropTypes.object,
  propertyActions: PropTypes.object,
  periodActions: PropTypes.object,
  fileActions: PropTypes.object,
  properties: PropTypes.array,
  checklistId: PropTypes.string.isRequired,
  errorResposne: PropTypes.shape({
    errorCode: PropTypes.string,
    message: PropTypes.string,
  }),
  services: PropTypes.array,
  previousPsqChecklist: PropTypes.object,
  internalChecklistId: PropTypes.string,
  authenticationScope: PropTypes.string,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  return {
    client: state.client,
    periodId: Number.parseInt(ownProps.router.params.periodId),
    psqChecklists: state.externalPsq,
    psqActiveChecklist: state.psqActiveChecklist,
    checklistFiles: state.checklistFiles,
    reit: state.reit,
    properties: state.properties,
    checklistId: ownProps.router.params.checklistId,
    errorResponse: state.errorResponse,
    services: state.services,
    period: state.period,
    previousPsqChecklist: state.previousPsqChecklist,
    internalChecklistId: ownProps.router.params.internalChecklistId,
    isExternalPermUser: ownProps.router.params.checklistId ? false : true,
    authenticationScope: state.authenticationScope,
    lookupTypes: state.lookupTypes,
    questionComments: state.questionComments,
    attachmentDetails: state.attachmentDetail,
    discrepanciesDetail: state.discrepanciesDetail,
    checklistCounter: state.checklistsCounter,
    lastServiceProviderQuestionId: state.lastServiceProviderQuestionId,
    allowUpdateReportPeriodVisibilityConstraintResult: state.allowUpdateReportPeriodVisibilityConstraintResult
  };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    checklistActions: bindActionCreators(checklistActions, dispatch),
    propertyActions: bindActionCreators(propertyActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    serviceActions: bindActionCreators(serviceActions, dispatch),
    periodActions: bindActionCreators(periodActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
    reitActions: bindActionCreators(reitActions, dispatch),
    clientActions: bindActionCreators(clientActions, dispatch),
    lookupTypeActions: bindActionCreators(lookupTypeActions, dispatch),
    customChecklistTemplateActions: bindActionCreators(customChecklistTemplateActions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ExternalPsqChecklistPage)
);

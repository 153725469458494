import React from 'react';
import { MotifIcon, MotifIconButton, MotifLabel } from '@ey-xd/motif-react';
import { IconoirEditPencil, IconoirTrash } from '@ey-xd/motif-icon';
import FormattedCurrency from '../../shared/FormattedCurrency';

export default (props) => {

    const onEditClick = () => {
        props.handleEditButtonClick(props.data);
    };

    const onDeleteClick = () => {
        props.handleDeleteButtonClick(props.data);
    };

    return (
        <div>
            {(props.column.colId == "actions") &&                  
                (<span className='mt-2'>
                    <MotifIconButton aria-label="Edit this TRS" type="button"
                            onClick={onEditClick}>
                        <MotifIcon iconFunction={IconoirEditPencil} size='24'  title="Edit this TRS" />
                        </MotifIconButton>
                    <MotifIconButton aria-label="Delete this TRS" type="button"
                            onClick={onDeleteClick}>
                        <MotifIcon iconFunction={IconoirTrash} size='24'  title="Delete this TRS" />
                        </MotifIconButton>
                    </span>)
                    || (<span>
                        <MotifIconButton aria-label="Can't edit TRS - Testing Closed" type="button" disabled>                        
                            <MotifIcon iconFunction={IconoirEditPencil} size='24'  aria-label="Can't edit TRS - Testing Closed" />
                        </MotifIconButton>
                        <MotifIconButton aria-label="Can't delete TRS - Testing Closed" type="button" disabled>                        
                            <MotifIcon iconFunction={IconoirTrash} size='24'  aria-label="Can't delete TRS - Testing Closed" />
                        </MotifIconButton>
                    </span>)
            }
        </div>
    )
};